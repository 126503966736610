import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContexts";

const PublicWrapper = () => {
  const { authStatus } = useContext(AuthContext);
  return <>{authStatus !== 1 ? <Outlet /> : <Navigate to="/app-users" />}</>;
};

export default PublicWrapper;
