import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import InnerHeader from '../InnerHeader';
import { GetSingleAdmin, EditAdminData } from '../../redux/features/AdminService';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../Common/Loader';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners'; // Importing RingLoader
import Cookies from "js-cookie";

const validate = (values) => {
    const errors = {};
    if (!values?.fullName) {
        errors.fullName = "Please enter a name";
    }
    if (!values?.email) {
        errors.email = "Please enter an email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "*Please type in a valid email";
    }
    if (!values?.status) {
        errors.status = "Please select an option";
    }
    return errors;
};

const uid = Cookies.get('uid');

const SingleAdmin = () => {
    const dispatch = useDispatch();
    const [isComponentVisible, setComponentVisibility] = useState(false);
    const params = useParams();
    const { singleAdmin } = useSelector((state) => state?.admin)
    const id = params.id;
    const [isLoading, setIsLoading] = useState(true);

    const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            if (id) {
                const res = await dispatch(GetSingleAdmin({ id }));
                if (res.type === "admin/GetSingleAdmin/fulfilled") {
                    setIsLoading(false);
                }
            }
        };
        fetchData();
    }, [id]);


    const formik = useFormik({
        initialValues: {
            fullName: singleAdmin?.fullName || "",
            email: singleAdmin?.email || "",
            status: singleAdmin?.status || "",
        },
        enableReinitialize: true,
        validate,
        onSubmit: async (values) => {
            const response = await dispatch(EditAdminData({ id, data: values }));
            if (response.type === "admin/EditAdminData/fulfilled") {
                setComponentVisibility(false);
                await dispatch(GetSingleAdmin({ id }));
                // console.log(response?.payload?.message);
                toast.success(response?.payload?.message, { autoClose: 3000 });
            } else if (response.type === "admin/EditAdminData/rejected") {
                toast.error(response?.payload?.response?.data, { autoClose: 3000 })
            }
            // setLoading(false);
        },
    });

    return (
        <>
            <div>
                <div className="w-100 h-100 ">
                    <Header />
                    <div className="d-flex position-relative">
                        <Sidebar />
                        <main className="MainSection">
                            {/* Inner Header Starts */}
                            <InnerHeader text={"Admin User / " + singleAdmin?.fullName} arrow={true}>
                                <div></div>
                            </InnerHeader>

                            {/* Display App user data */}
                            {/* {loading && <Loader />} */}
                            <div className="conten-div">
                                <div className="single-admin-data singleData w-100">

                                    {/* Edit and Display Design */}
                                    {isComponentVisible ?
                                        <>
                                            <div className='w-100'>
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="d-flex justify-content-between mb-4 w-100">
                                                        <h2 className='BoxTitle'>About</h2>
                                                        <button type='submit' className="SiteBtn2 export-csv-btn btn">
                                                            Save
                                                        </button>
                                                    </div>
                                                    <div className="">
                                                        <div className="did-floating-label-content">
                                                            <input className="did-floating-input" type="text" name="fullName" id='fullName' onChange={formik?.handleChange} value={formik?.values?.fullName} placeholder=" " />
                                                            <label className="did-floating-label" htmlFor='fullName'>Full Name</label>
                                                            {formik?.touched?.fullName && formik?.errors?.fullName ? (
                                                                <div className="error">{formik?.errors?.fullName}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="did-floating-label-content">
                                                            <input className="did-floating-input" type="text" name="email" id='email' onChange={formik?.handleChange} value={formik?.values?.email} placeholder=" " />
                                                            <label className="did-floating-label" htmlFor='email'>Email</label>
                                                            {formik?.touched?.email && formik?.errors?.email ? (
                                                                <div className="error">{formik?.errors?.email}</div>
                                                            ) : null}
                                                        </div>
                                                        {uid === id ? "" :

                                                            <div className="did-floating-label-content mb-0">
                                                                <select className="did-floating-select" name="status" id='status' onChange={formik?.handleChange}  >
                                                                    <option disabled selected value="">Select</option>
                                                                    <option value="1" selected={formik?.values?.status === "1"}>Active</option>
                                                                    <option value="0" selected={formik?.values?.status === "0"}>Inactive</option>
                                                                </select>
                                                                <label className="did-floating-label" htmlFor='status'>Status</label>
                                                                {formik?.touched?.status && formik?.errors?.status ? (
                                                                    <div className="error">{formik?.errors?.status}</div>
                                                                ) : null}
                                                            </div>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="d-flex justify-content-between mb-4 w-100">
                                                <h2 className='BoxTitle'>About</h2>
                                                <button onClick={(e) => { e.preventDefault(); setComponentVisibility(true) }} className="SiteBtn export-csv-btn btn">
                                                    Edit
                                                </button>
                                            </div>
                                            {isLoading ? (
                                                <div className="loader-container m-auto">
                                                    <RingLoader css={override} size={50} color={"#00639B"} loading={isLoading} />
                                                </div>
                                            ) : (

                                                <div className="ms-3">
                                                    <div className="single-username mb-4">
                                                        <div className='single-user-p-main'>Full Name</div>
                                                        <div className='single-user-p-desc'>
                                                            {singleAdmin?.fullName}
                                                        </div>
                                                    </div>
                                                    <div className="single-user-notification mb-4">
                                                        <div className='single-user-p-main'>Email</div>
                                                        <div className='single-user-p-desc'>
                                                            {singleAdmin?.email}
                                                        </div>
                                                    </div>
                                                    {uid === id ? "" :
                                                        <div className="single-user-status">
                                                            <div className='single-user-p-main'>Status</div>
                                                            <div className='single-user-p-desc'>
                                                                <p>{singleAdmin?.status === "1" ? "Active" : "Inactive"}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </>
                                    }
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleAdmin;
