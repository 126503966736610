import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import InnerHeader from "../InnerHeader";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { DeleteCategory, GetSingleCategoryService, UpdateCategoryService } from "../../redux/features/CategoryService";
import { UploadMedia } from "../../redux/features/CommonFileService";
import { toast } from "react-toastify";
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners';

const SingleCategory = () => {

  const [fileName, setFilename] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [status, setStatus] = useState("");
  const [isComponentVisible, setComponentVisibility] = useState(false);
  const { singleCategory } = useSelector((state) => state.category);
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

  const [errors, setErrors] = useState({
    categoryType: "",
    status: "",
    file: "",
  });

  const validate = () => {
    let isValid = true;
    const newErrors = {
      title: "",
      description: "",
      file: "",
    };

    if (!categoryType.trim()) {
      newErrors.categoryType = "Please enter a Category Type";
      isValid = false;
    }
    if (!status.trim()) {
      newErrors.status = "Please enter a status";
      isValid = false;
    }
    if (!fileName) {
      newErrors.file = "Please upload file";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const EditCategory = async (e) => {
    e.preventDefault();
    const data = {
      categoryImage: fileName,
      categoryType: categoryType,
      status: status,
    };
    const response = await dispatch(
      UpdateCategoryService({ id: id, data: data })
    );

    if (response.type === "category/UpdateCategoryService/fulfilled") {
      toast.success("Category Updated Successfully");
      setComponentVisibility(false);
      navigate(`/category/${id}`);
    }
  };

  const handleDeleteCategory = async (e) => {
    e.preventDefault();
    alert("Are you sure want to Delete this Category?")
    const response = await dispatch(DeleteCategory({ id }));
    console.log(response);
    if(response.type === 'category/DeleteCategory/fulfilled'){
      toast.success("Category Deleted Successfully");
      navigate(`/category`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const res = await dispatch(GetSingleCategoryService({ id }));
      if (res.type === "category/GetSingleCategoryService/fulfilled") {
        setFilename(res?.payload.category?.categoryImage);
        setCategoryType(res?.payload.category?.categoryType);
        setStatus(res?.payload.category?.status);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      EditCategory(e);
    } else {
      console.error("Form validation failed");
    }
  };

  const handleFileChange = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("files", file.currentTarget.files[0]);
      const res = await dispatch(UploadMedia(formData));
      if (res.type === "common/UploadMedia/fulfilled") {
        setFilename(res.payload?.files[0]?.mediaName);
        return res.payload?.files[0];
      }
    }
  };

  return (
    <>
      <div>
        <div className="w-100 h-100 ">
          <Header />
          <div className="d-flex position-relative">
            <Sidebar />
            <main className="MainSection">
              {/* Inner Header Starts */}
              <InnerHeader text={`Tenant Category / ${categoryType ? categoryType : 'Category'}`} arrow={true}>
                <div></div>
              </InnerHeader>
              {/* Display App user data */}
              <div className="conten-div">
                <div className="single-admin-data">
                  <div className="d-flex justify-content-between mb-4 w-100">
                    <h2 className="BoxTitle">About</h2>
                    {/* Edit and  save buttom*/}
                    {isComponentVisible ? (
                      <button
                        onClick={onSubmit}
                        className="SiteBtn2 export-csv-btn btn"
                      >
                        Save
                      </button>
                    ) : (
                      <div>
                        <button
                          onClick={(e) => {
                            handleDeleteCategory(e);
                          }}
                          className="DeleteBtn export-csv-btn btn me-2"
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => {
                            setComponentVisibility(true);
                          }}
                          className="SiteBtn export-csv-btn btn"
                        >
                          Edit
                        </button>
                      </div>
                    )}
                  </div>
                  {isLoading ? (
                    <div className="loader-container m-auto">
                      <RingLoader css={override} size={50} color={"#00639B"} loading={isLoading} />
                    </div>
                  ) : (
                    <>
                      {/* Edit and Display Design */}
                      {isComponentVisible ? (
                        <div className="w-100">
                          <form onSubmit={onSubmit} encType="multipart/form-data">
                            <div className="">
                              <div className="did-floating-label-content">
                                <input
                                  className="did-floating-input"
                                  type="text"
                                  defaultValue={categoryType}
                                  onChange={(e) => {
                                    setCategoryType(e.target.value);
                                  }}
                                  placeholder=" "
                                />
                                <label className="did-floating-label">
                                  Category Type
                                </label>
                                {errors.categoryType && (
                                  <div style={{ color: "red" }}>
                                    {errors.categoryType}
                                  </div>
                                )}
                              </div>
                              <div className="did-floating-label-content">
                                <select
                                  className="did-floating-select"
                                  defaultValue={status}
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option disabled value="Select">
                                    Select
                                  </option>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </select>
                                <label className="did-floating-label">Status</label>
                                {errors.status && (
                                  <div style={{ color: "red" }}>
                                    {errors.status}
                                  </div>
                                )}
                              </div>
                              <div>
                                <input
                                  type="file"
                                  onChange={handleFileChange}
                                  id="file-input"
                                  name="file-input"
                                  accept=".png, .pdf, .jpeg, .jpg"
                                />
                                <a href={
                                  process.env.REACT_APP_BACKEND_URL +
                                  "uploads/" +
                                  fileName
                                }
                                ></a>
                                <img
                                  src={
                                    process.env.REACT_APP_BACKEND_URL +
                                    "uploads/" +
                                    fileName
                                  }
                                  className="single-category-img"
                                  alt="!Oops image is not available"
                                />
                                {errors.file && (
                                  <div style={{ color: "red" }}>{errors.file}</div>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <div className="ms-3">
                          <div className="single-username mb-4">
                            <div className="single-user-p-main">Category Type</div>
                            <div className="single-user-p-desc">{categoryType}</div>
                          </div>
                          <div className="single-user-notification mb-4">
                            <div className="single-user-p-main">Status</div>
                            <div className="single-user-p-desc">
                              {status === "1" ? <p>Active</p> : <p>Inactive</p>}
                            </div>
                          </div>
                          <div className="single-user-status">
                            <div className="single-user-p-main">Image</div>
                            <div className="single-user-p-desc mb-1">
                              <img
                                src={
                                  process.env.REACT_APP_BACKEND_URL +
                                  "uploads/" +
                                  fileName
                                }
                                className="single-category-img"
                                alt="!Oops image is not available"
                              />
                            </div>
                            {fileName}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleCategory;