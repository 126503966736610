import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import InnerHeader from "../InnerHeader";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CreateTenant, UpdateTenant, TenantImageUpload, GetTenantWheelPosition, PostTenantWheelPosition } from '../../redux/features/TenantService';
import { toast } from "react-toastify";
import { GetAllCategoryService } from "../../redux/features/CategoryService";
import _ from "lodash";
import * as Yup from "yup";
import { ChromePicker } from "react-color";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import validator from 'validator';

const AddTenant = ({ text, arrow, children, onClick }) => {
  const dispatch = useDispatch();
  const { allCategory } = useSelector((state) => state.category);
  const [currentPage, setCurrentPage] = useState(1);
  const [filePrimaryName, setFilePrimaryName] = useState("");
  const [fileSecondaryName, setFileSecondaryName] = useState("");
  const [fileThirdName, setFileThirdName] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isBtnDisable, setBtnDisable] = useState({
    general: false,
    contract: true,
    about: true,
    fortune: true,
  })
  const handleBackButtonClick = () => {
    localStorage.removeItem('tenant-id');
    navigate(-1);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('lastUnloadTime', Date.now()); // Store the current time on unload
    };
    const handlePageLoad = () => {
      const lastUnloadTime = localStorage.getItem("lastUnloadTime");
      const currentTime = Date.now();
      const timeSinceLastUnload = currentTime - lastUnloadTime;
      if (lastUnloadTime && timeSinceLastUnload < 1000) {
        localStorage.clear();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("load", handlePageLoad);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  useEffect(() => {
    localStorage.removeItem('tenant-id');
  }, [location.pathname]);

  const handleFormSubmission = async (values, dispatch, CreateTenant, UpdateTenant, allCategory, filePrimaryName, fileSecondaryName, fileThirdName, toast) => {
    const data = {
      ...values,
      category: values?.category ? values?.category : allCategory?.data?.records[0]?._id,
      showInWheel: values?.showInWheel ? values?.showInWheel : "0",
      primary_image: filePrimaryName,
      secondary_image: fileSecondaryName,
      third_image: fileThirdName,
    };

    const tenantId = localStorage.getItem("tenant-id");
    let response;
    if (!tenantId) {
      response = await dispatch(CreateTenant({ data }));
      localStorage.setItem("tenant-id", response.payload.tenant._id);
    } else {
      response = await dispatch(UpdateTenant({ id: tenantId, data: data }));
    }

    if (response.type === "tenant/CreateTenant/fulfilled" || response.type === "tenant/UpdateTenant/fulfilled") {
      toast.success("Tenant Details Added Successfully", { autoClose: 3000 });
    } else {
      toast.error(response?.payload?.response?.data?.error, { autoClose: 3000 });
    }

  }

  const validationGeneral = Yup.object().shape({
    tenant: Yup.string().required("*Please enter a tenant name"),
    status: Yup.string().required("*Please select an option"),
  });
  const formikGeneral = useFormik({
    initialValues: {
      tenant: "",
      status: "",
    },
    validationSchema: validationGeneral,
    onSubmit: async (values) => {
      await handleFormSubmission(values, dispatch, CreateTenant, UpdateTenant, allCategory, filePrimaryName, fileSecondaryName, fileThirdName, toast);
      setBtnDisable({
        general: true,
        contract: false,
        about: true,
        fortune: true,
      })
    },
  });

  const validationContact = Yup.object().shape({
    streetAddress: Yup.string().trim().required('*Please enter an address'),
    city: Yup.string().trim().required('*Please enter a city'),
    state: Yup.string().trim().required('*Please enter a state'),
    zip: Yup.string()
      .trim()
      .matches(/^\d{5}(-\d{4})?$/, '*Please enter a valid zip code')
      .required('*Please enter a zip code'),
    website: Yup.string()
      .trim()
      .test('isValidUrl', '*Please enter a valid website link', function (value) {
        if (!value) {
          return true;
        }
        if (!isValidUrl(value)) {
          return false; // URL is not valid
        }
        return true; // URL is valid
      }),
    tenantPhone: Yup.string().trim().matches(/^[0-9]{10}$/, { message: '*Please enter a valid phone number', }),
    tenantEmail: Yup.string()
      .trim()
      .email('*Please type in a valid email')
  });

  const formikContactInfo = useFormik({
    initialValues: {
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
      website: "",
      tenantPhone: "",
      tenantEmail: "",
    },
    validationSchema: validationContact,
    onSubmit: async (values) => {
      const tenantId = localStorage.getItem("tenant-id");
      if (!tenantId) {
        console.error("Tenant ID not found in localStorage");
        return;
      }
      await handleFormSubmission(values, dispatch, CreateTenant, UpdateTenant, allCategory, filePrimaryName, fileSecondaryName, fileThirdName, toast);
      setBtnDisable({
        general: true,
        contract: true,
        about: false,
        fortune: true,
      })
    },
  });
  
  const isValidUrl = (url) =>  {
    try {
      // Regular expression for a URL
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3})|" + // OR IP (v4) address
          "localhost|" + // OR localhost
          "([a-zA-Z\\d-]+\\.([a-zA-Z]{2,}))\\.?)" + // subdomain
          "(:\\d+)?(\\/[-a-zA-Z\\d%@_.~+&:]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
          "(#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
  
      return pattern.test(url);
    } catch (error) {
      return false;
    }
  }

  const validationAbout = Yup.object().shape({
    tenantNumber: Yup.string().trim().required('*Please enter a tenant number'),
    ticketURL: Yup.string()
      .trim()
      .test('isValidUrl', '*Please enter a valid ticket link', function (value) {
        if (!value) {
          return true;
        }
        if (!isValidUrl(value)) {
          return false;
        }
        return true;
      })
      .notRequired('*Please enter ticket link'),

    reservationURL: Yup.string()
      .trim()
      .test('isValidUrl', '*Please enter a valid reservation link', function (value) {
        if (!value) {
          return true;
        }
        if (!isValidUrl(value)) {
          return false; // URL is not valid
        }
        return true; // URL is valid
      })
      .notRequired('*Please enter a reservation link'),

    orderOnlineURL: Yup.string()
      .trim()
      .test('isValidUrl', '*Please enter an valid order online link', function (value) {
        if (!value) {
          return true;
        }
        if (!isValidUrl(value)) {
          return false; // URL is not valid
        }
        return true; // URL is valid
      })
      .notRequired('*Please enter an order online link'),
  });

  const formikAbout = useFormik({
    initialValues: {
      tenantNumber: "",
      category: "",
      ticketURL: "",
      reservationURL: "",
      orderOnlineURL: "",
    },
    validationSchema: validationAbout,
    onSubmit: async (values) => {
      const tenantId = localStorage.getItem("tenant-id");
      if (!tenantId) {
        console.error("Tenant ID not found in localStorage");
        return;
      }
      console.log('About values', values);
      await handleFormSubmission(values, dispatch, CreateTenant, UpdateTenant, allCategory, filePrimaryName, fileSecondaryName, fileThirdName, toast);

      setBtnDisable({
        general: true,
        contract: true,
        about: true,
        fortune: false,
      })
    },
  });

  const validationFortune = Yup.object().shape({
    color: Yup.string().trim().required('*Please select a color'),
    discountText: Yup.string().trim().required('*Please enter the discount text'),
    discountSubText: Yup.string().trim().required('*Please enter the discount subtext'),
  });

  const formikFortune = useFormik({
    initialValues: {
      color: "",
      discountText: "",
      discountSubText: "",
    },
    validationSchema: validationFortune,
    onSubmit: async (values) => {
      const tenantId = localStorage.getItem("tenant-id");
      if (!tenantId) {
        console.error("Tenant ID not found in localStorage");
        return;
      }
      console.log('Fortune values', values, 'category', formikAbout.values?.category);
      await handleFormSubmission({ ...values, category: formikAbout.values?.category }, dispatch, CreateTenant, UpdateTenant, allCategory, filePrimaryName, fileSecondaryName, fileThirdName, toast);
      if (validationFortune) {
        localStorage.removeItem("tenant-id");
        navigate("/tenants");
      }
    },
  });

  useEffect(() => {
    dispatch(
      GetAllCategoryService({ page: currentPage, status: "1", limit: 10 })
    );
  }, []);

  const handlePrimaryFileChange = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("files", file.currentTarget.files[0]);
      const res = await dispatch(TenantImageUpload(formData));
      if (res.type === "tenants/TenantImageUpload/fulfilled") {
        setFilePrimaryName(res.payload?.files[0]?.mediaName);
        const tenantId = localStorage.getItem("tenant-id");
        if (tenantId) {
          const data = {
            primary_image: res.payload?.files[0]?.mediaName,
          };
          const response = await dispatch(UpdateTenant({ id: tenantId, data: data }));
          if (response.type === "tenant/UpdateTenant/fulfilled") {
            toast.success("File uploaded successfully");
          }
        }
      }
    }
  };

  const handleSecondaryFileChange = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("files", file.currentTarget.files[0]);
      const res = await dispatch(TenantImageUpload(formData));
      if (res.type === "tenants/TenantImageUpload/fulfilled") {
        setFileSecondaryName(res.payload?.files[0]?.mediaName);
        const tenantId = localStorage.getItem("tenant-id");
        if (tenantId) {
          const data = {
            secondary_image: res.payload?.files[0]?.mediaName,
          };
          const response = await dispatch(UpdateTenant({ id: tenantId, data: data }));
          if (response.type === "tenant/UpdateTenant/fulfilled") {
            toast.success("File uploaded successfully");
          }
        }
      }
    }
  };

  const handleThirdFileChange = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("files", file.currentTarget.files[0]);
      const res = await dispatch(TenantImageUpload(formData));
      if (res.type === "tenants/TenantImageUpload/fulfilled") {
        setFileThirdName(res.payload?.files[0]?.mediaName);
        const tenantId = localStorage.getItem("tenant-id");
        if (tenantId) {
          const data = {
            third_image: res.payload?.files[0]?.mediaName,
          };
          const response = await dispatch(UpdateTenant({ id: tenantId, data: data }));
          if (response.type === "tenant/UpdateTenant/fulfilled") {
            toast.success("File uploaded successfully");
          }
        }
      }
    }
  };

  return (
    <>
      <div className="w-100 h-100 ">
        <Header />
        <div className="d-flex position-relative">
          <Sidebar />
          <div className="MainSection">
            {/* <InnerHeader text="Tenants / New Tenants" arrow={true} /> */}
            <div className="mb-4 MainTitle">
              <div className="sidebar-sidemenu">
                <div className="SidebarMenuMobile">
                  <button id="SidebarMenuMobileBtn"><i className="fas fa-align-left"></i></button>
                </div>
                <div className="text-dark fs-4 TitleText">
                  {!arrow &&
                    <NavLink style={{ marginRight: '20px' }} onClick={handleBackButtonClick}>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="#42474E" />
                      </svg>
                    </NavLink>
                  }
                  <div className="innerheader-text">
                    Tenants / New Tenant
                  </div>
                </div>
              </div>
              <div className="mt-md-0 mt-4">
                {children && children}
              </div>
            </div>

            <div className="conten-div main-add">
              <div className="tenants-images-add  ">
                <div className="btn-head">
                  <h2 className="BoxTitle">Images</h2>
                </div>
                <div className="row">
                  <div className="row-img">
                    <div className="col-xs-4  disp-primary-img ">
                      {filePrimaryName ? (
                        <img
                          className="tenant-img"
                          src={
                            process.env.REACT_APP_BACKEND_URL +
                            "uploads/" +
                            filePrimaryName
                          }
                          alt="NO IMAGE FOUND"
                        />
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="col-xs-4  disp-secondary-img">
                      {fileSecondaryName ? (
                        <img
                          className="tenant-img"
                          src={
                            process.env.REACT_APP_BACKEND_URL +
                            "uploads/" +
                            fileSecondaryName
                          }
                          alt="NO IMAGE FOUND"
                        />
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="col-xs-4  disp-third-img">
                      {fileThirdName ? (
                        <img
                          className="tenant-img"
                          src={
                            process.env.REACT_APP_BACKEND_URL +
                            "uploads/" +
                            fileThirdName
                          }
                          alt="NO IMAGE FOUND"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="tenant-img-row">
                    <div className="">
                      <div className="primary-img add-img-tenant">
                        <input
                          type="file"
                          name="input-file-1"
                          id="img1"
                          onChange={handlePrimaryFileChange}
                          style={{ display: "none" }}
                        />
                        {error && <div>{error}</div>}
                        <label htmlFor="img1" style={{ marginBottom: "0px" }}>
                          Set Primary Image
                        </label>
                      </div>
                    </div>

                    <div className="">
                      <div className="img1 add-img-tenant">
                        <input
                          type="file"
                          name="input-file-2"
                          id="img2"
                          onChange={handleSecondaryFileChange}
                          style={{ display: "none" }}
                        />
                        {error && <div>{error}</div>}
                        <label htmlFor="img2" style={{ marginBottom: "0px" }}>
                          Set Image 2
                        </label>
                      </div>
                    </div>

                    <div className="">
                      <div className="img2 add-img-tenant">
                        <input
                          type="file"
                          name="input-file-3"
                          id="img3"
                          onChange={handleThirdFileChange}
                          style={{ display: "none" }}
                        />
                        {error && <div>{error}</div>}
                        <label htmlFor="img3" style={{ marginBottom: "0px" }}>
                          Set Image 3
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tenant-general-add">
                {/* <AddTenantGeneral /> */}
                <div className="w-100">
                  <div className="btn-head d-flex justify-content-between mb-4">
                    <h2 className="BoxTitle">General</h2>
                    <button
                      disabled={isBtnDisable?.general}
                      onClick={() => {
                        formikGeneral.handleSubmit();
                      }}
                      className="SiteBtn2 edit-btn-tenant btn"
                    >
                      Save
                    </button>
                  </div>
                  <form onSubmit={formikGeneral.handleSubmit}>
                    <div className="">
                      <div className="did-floating-label-content">
                        <input
                          disabled={isBtnDisable?.general}
                          className={`did-floating-input ${formikGeneral.touched.tenant && formikGeneral.errors.tenant ? "is-invalid" : ""}`}
                          name="tenant"
                          onChange={formikGeneral.handleChange}
                          defaultValue={formikGeneral.values.tenant}
                          type="text"
                          placeholder=" "
                        />
                        <label className="did-floating-label">Tenant</label>
                        {formikGeneral.touched.tenant &&
                          formikGeneral.errors.tenant && (
                            <div className="invalid-feedback">
                              {formikGeneral.errors.tenant}
                            </div>
                          )}
                      </div>
                      <div className="did-floating-label-content mb-0">
                        <select
                          disabled={isBtnDisable?.general}
                          className={`did-floating-select ${formikGeneral.touched.status &&
                            formikGeneral.errors.status
                            ? "is-invalid"
                            : ""
                            }`}
                          name="status"
                          onChange={formikGeneral.handleChange}
                          defaultValue={formikGeneral.values.status}
                        >
                          <option selected>Select</option>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>
                        <label className="did-floating-label">Status</label>
                        {formikGeneral.touched.status &&
                          formikGeneral.errors.status && (
                            <div className="invalid-feedback">
                              {formikGeneral.errors.status}
                            </div>
                          )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="tenant-contact-add">
                {/* <AddTenantContact /> */}
                <div className="w-100">
                  <div className="btn-head d-flex justify-content-between mb-4">
                    <h2 className="BoxTitle">Contact</h2>
                    <button
                      disabled={isBtnDisable?.contract}
                      onClick={() => {
                        formikContactInfo.handleSubmit();
                      }}
                      className="SiteBtn2 edit-btn-tenant btn"
                    >
                      Save
                    </button>
                  </div>
                  <form onSubmit={formikContactInfo.handleSubmit}>
                    <div className="">
                      <div className="did-floating-label-content">
                        <input
                          className={`did-floating-input ${formikContactInfo.touched.streetAddress &&
                            formikContactInfo.errors.streetAddress
                            ? "is-invalid"
                            : ""
                            }`}
                          disabled={isBtnDisable?.contract}
                          name="streetAddress"
                          onChange={formikContactInfo.handleChange}
                          value={formikContactInfo.values.streetAddress}
                          type="text"
                          placeholder=" "
                        />
                        <label className="did-floating-label">
                          Street Address
                        </label>
                        {formikContactInfo.touched.streetAddress &&
                          formikContactInfo.errors.streetAddress && (
                            <div className="invalid-feedback">
                              {formikContactInfo.errors.streetAddress}
                            </div>
                          )}
                      </div>

                      <div className="did-floating-label-content">
                        <input
                          className={`did-floating-input ${formikContactInfo.touched.city &&
                            formikContactInfo.errors.city
                            ? "is-invalid"
                            : ""
                            }`}
                          disabled={isBtnDisable?.contract}
                          name="city"
                          onChange={formikContactInfo.handleChange}
                          value={formikContactInfo.values.city}
                          type="text"
                          placeholder=" "
                        />
                        <label className="did-floating-label">City</label>
                        {formikContactInfo.touched.city &&
                          formikContactInfo.errors.city && (
                            <div className="invalid-feedback">
                              {formikContactInfo.errors.city}
                            </div>
                          )}
                      </div>

                      <div className="did-floating-label-content">
                        <input
                          className={`did-floating-input ${formikContactInfo.touched.state &&
                            formikContactInfo.errors.state
                            ? "is-invalid"
                            : ""
                            }`}
                          disabled={isBtnDisable?.contract}
                          name="state"
                          onChange={formikContactInfo.handleChange}
                          value={formikContactInfo.values.state}
                          type="text"
                          placeholder=" "
                        />
                        <label className="did-floating-label">State</label>
                        {formikContactInfo.touched.state &&
                          formikContactInfo.errors.state && (
                            <div className="invalid-feedback">
                              {formikContactInfo.errors.state}
                            </div>
                          )}
                      </div>

                      <div className="did-floating-label-content">
                        <input
                          className={`did-floating-input ${formikContactInfo.touched.zip &&
                            formikContactInfo.errors.zip
                            ? "is-invalid"
                            : ""
                            }`}
                          disabled={isBtnDisable?.contract}
                          name="zip"
                          onChange={formikContactInfo.handleChange}
                          value={formikContactInfo.values.zip}
                          type="text"
                          placeholder=" "
                        />
                        <label className="did-floating-label">Zip</label>
                        {formikContactInfo.touched.zip &&
                          formikContactInfo.errors.zip && (
                            <div className="invalid-feedback">
                              {formikContactInfo.errors.zip}
                            </div>
                          )}
                      </div>

                      <div className="did-floating-label-content">
                        <input
                          className={`did-floating-input ${formikContactInfo.touched.website &&
                            formikContactInfo.errors.website
                            ? "is-invalid"
                            : ""
                            }`}
                          disabled={isBtnDisable?.contract}
                          name="website"
                          onChange={formikContactInfo.handleChange}
                          value={formikContactInfo.values.website}
                          type="text"
                          placeholder=" "
                        />
                        <label className="did-floating-label">Website</label>
                        {formikContactInfo.touched.website &&
                          formikContactInfo.errors.website && (
                            <div className="invalid-feedback">
                              {formikContactInfo.errors.website}
                            </div>
                          )}
                      </div>

                      <div className="did-floating-label-content">
                        <input
                          className={`did-floating-input ${formikContactInfo.touched.tenantPhone &&
                            formikContactInfo.errors.tenantPhone
                            ? "is-invalid"
                            : ""
                            }`}
                          disabled={isBtnDisable?.contract}
                          name="tenantPhone"
                          onChange={formikContactInfo.handleChange}
                          value={formikContactInfo.values.tenantPhone}
                          type="tel"
                          placeholder=" "
                        />
                        <label className="did-floating-label">
                          Tenant Phone
                        </label>
                        {formikContactInfo.touched.tenantPhone &&
                          formikContactInfo.errors.tenantPhone && (
                            <div className="invalid-feedback">
                              {formikContactInfo.errors.tenantPhone}
                            </div>
                          )}
                      </div>

                      <div className="did-floating-label-content mb-0">
                        <input
                          className={`did-floating-input ${formikContactInfo.touched.tenantEmail &&
                            formikContactInfo.errors.tenantEmail
                            ? "is-invalid"
                            : ""
                            }`}
                          disabled={isBtnDisable?.contract}
                          name="tenantEmail"
                          onChange={formikContactInfo.handleChange}
                          value={formikContactInfo.values.tenantEmail}
                          type="email"
                          placeholder=" "
                        />
                        <label className="did-floating-label">
                          Tenant Email
                        </label>
                        {formikContactInfo.touched.tenantEmail &&
                          formikContactInfo.errors.tenantEmail && (
                            <div className="invalid-feedback">
                              {formikContactInfo.errors.tenantEmail}
                            </div>
                          )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="tenant-about-add">
                {/* <AddTenantAbout /> */}
                <div className="w-100">
                  <div className="btn-head d-flex justify-content-between mb-4">
                    <h2 className="BoxTitle">About</h2>
                    <button
                      disabled={isBtnDisable?.about}
                      onClick={() => {
                        formikAbout.handleSubmit();
                      }}
                      className="SiteBtn2 edit-btn-tenant btn"
                    >
                      Save
                    </button>
                  </div>
                  <form onSubmit={formikAbout.handleSubmit}>
                    <div className="">
                      <div className="did-floating-label-content">
                        <input
                          className={`did-floating-input ${formikAbout.touched.tenantNumber &&
                            formikAbout.errors.tenantNumber
                            ? "is-invalid"
                            : ""
                            }`}
                          onChange={formikAbout.handleChange}
                          defaultValue={formikAbout.values.tenantNumber}
                          disabled={isBtnDisable?.about}
                          name="tenantNumber"
                          type="number"
                          placeholder=""
                        />
                        <label className="did-floating-label">
                          Tenant Number
                        </label>
                        {formikAbout.touched.tenantNumber &&
                          formikAbout.errors.tenantNumber && (
                            <div className="invalid-feedback">
                              {formikAbout.errors.tenantNumber}
                            </div>
                          )}
                      </div>

                      <div className="did-floating-label-content">
                        <select
                          className={` did-floating-select ${formikAbout.touched.category &&
                            formikAbout.errors.category
                            ? "is-invalid"
                            : ""
                            }`}
                          onChange={formikAbout.handleChange}
                          disabled={isBtnDisable?.about}
                          name="category"
                          id="category"
                          defaultValue={((formikAbout?.values?.category) ?? (allCategory?.data?.records[0]?._id))}
                        >
                          <option disabled>Select Category</option>
                          {_.map(allCategory?.data?.records, (data, key) => (
                            <option key={key} value={data?._id} selected={data?._id === formikAbout?.values?.category}>
                              {data?.categoryType}
                            </option>
                          ))}
                        </select>

                        <label className="did-floating-label">Category</label>
                        {formikAbout.touched.category &&
                          formikAbout.errors.category && (
                            <div className="invalid-feedback">
                              {formikAbout.errors.category}
                            </div>
                          )}
                      </div>

                      <div className="did-floating-label-content">
                        <input
                          className={`did-floating-input ${formikAbout.touched.ticketURL &&
                            formikAbout.errors.ticketURL
                            ? "is-invalid"
                            : ""
                            }`}
                          onChange={formikAbout.handleChange}
                          defaultValue={formikAbout.values.ticketURL}
                          disabled={isBtnDisable?.about}
                          name="ticketURL"
                          type="url"
                          placeholder=" "
                        />
                        <label className="did-floating-label">
                          Tickets URL
                        </label>
                        {formikAbout.touched.ticketURL &&
                          formikAbout.errors.ticketURL && (
                            <div className="invalid-feedback">
                              {formikAbout.errors.ticketURL}
                            </div>
                          )}
                      </div>

                      <div className="did-floating-label-content">
                        <input
                          className={`did-floating-input ${formikAbout.touched.reservationURL &&
                            formikAbout.errors.reservationURL
                            ? "is-invalid"
                            : ""
                            }`}
                          onChange={formikAbout.handleChange}
                          name="reservationURL"
                          defaultValue={formikAbout.values.reservationURL}
                          disabled={isBtnDisable?.about}
                          type="url"
                          placeholder=" "
                        />
                        <label className="did-floating-label">
                          Reservation URL
                        </label>
                        {formikAbout.touched.reservationURL &&
                          formikAbout.errors.reservationURL && (
                            <div className="invalid-feedback">
                              {formikAbout.errors.reservationURL}
                            </div>
                          )}
                      </div>

                      <div className="did-floating-label-content mb-0">
                        <input
                          className={`did-floating-input ${formikAbout.touched.orderOnlineURL &&
                            formikAbout.errors.orderOnlineURL
                            ? "is-invalid"
                            : ""
                            }`}
                          name="orderOnlineURL"
                          onChange={formikAbout.handleChange}
                          defaultValue={formikAbout.values.orderOnlineURL}
                          disabled={isBtnDisable?.about}
                          type="url"
                          placeholder=" "
                        />
                        <label className="did-floating-label">
                          Order Online URL
                        </label>
                        {formikAbout.touched.orderOnlineURL &&
                          formikAbout.errors.orderOnlineURL && (
                            <div className="invalid-feedback">
                              {formikAbout.errors.orderOnlineURL}
                            </div>
                          )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="tenant-wheel-of-fortune-add">
                {/* <AddTenantFortune /> */}
                <div className="w-100">
                  <div className="btn-head d-flex justify-content-between mb-4 ">
                    <h2 className="BoxTitle">Wheel of Fortune</h2>
                    <button
                      disabled={isBtnDisable?.fortune}
                      onClick={() => {
                        formikFortune.handleSubmit();
                      }}
                      className="SiteBtn2 edit-btn-tenant btn"
                    >
                      Save
                    </button>
                  </div>
                  <form onSubmit={formikFortune.handleSubmit}>
                    <div className="">
                      <div className="did-floating-label-content">
                      <ChromePicker
                        className="my-3"
                        color={formikFortune.values.color}
                        onChange={(color) => formikFortune.setFieldValue("color", color?.hex)} />
                        <label className="did-floating-label">Color</label>
                        {formikFortune.touched.color &&
                          formikFortune.errors.color && (
                          <div className="" style={{ color: 'red', fontSize: '.875em' }}>
                            {formikFortune.errors.color}
                          </div>
                        )}
                      </div>
                      <div className="did-floating-label-content">
                        <input
                          className={`did-floating-input ${formikFortune.touched.discountText &&
                            formikFortune.errors.discountText
                            ? "is-invalid"
                            : ""}`}
                          name="discountText"
                          onChange={formikFortune.handleChange}
                          defaultValue={formikFortune.values.discountText}
                          type="text"
                          placeholder=" " />
                        <label className="did-floating-label">
                          Discount Text
                        </label>
                        {formikFortune.touched.discountText &&
                          formikFortune.errors.discountText && (
                            <div className="invalid-feedback">
                              {formikFortune.errors.discountText}
                            </div>
                          )}
                      </div>
                      <div className="did-floating-label-content mb-0">
                        <input
                          className={`did-floating-input ${formikFortune.touched.discountSubText &&
                            formikFortune.errors.discountSubText
                            ? "is-invalid"
                            : ""}`}
                          name="discountSubText"
                          onChange={formikFortune.handleChange}
                          defaultValue={formikFortune.values.discountSubText}
                          type="text"
                          placeholder=" " />
                        <label className="did-floating-label">
                          Discount Subtext
                        </label>
                        {formikFortune.touched.discountSubText &&
                          formikFortune.errors.discountSubText && (
                            <div className="invalid-feedback">
                              {formikFortune.errors.discountSubText}
                            </div>
                          )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddTenant;
