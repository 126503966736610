import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContexts";

const PrivateWrapper = () => {
  const { authStatus } = useContext(AuthContext);
  return <>{authStatus === 1 ? <Outlet /> : <Navigate to="/sign-in" />}</>;
};

export default PrivateWrapper;
