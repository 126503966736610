import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import Cookies from "js-cookie";

const url = process.env.REACT_APP_API_URL;

// Get All Users Service
export const GetAllActiveNotification = createAsyncThunk(
    "notification/GetAllActiveNotification",
    async (args, { rejectWithValue }) => {
        try {
            const { status, search, filter } = args;
            const resp = await axios.post(`${url}/admin/get_all_notification`, {
                // "page": page ? page : 1,
                // "limit": limit ? limit : 10,
                "status": status,
                "search": search,
                "filter": filter
            }, {
                headers: getAuthenticationHeader(),
            });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const EditNotificationService = createAsyncThunk(
    "notification/EditNotificationService",
    async (args, { rejectWithValue }) => {
        try {
            const { id, data } = args;
            const resp = await axios.post(`${url}/admin/update_notification/${id}`, data , 
            {
                headers: getAuthenticationHeader(),
            });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const AddNotificationService = createAsyncThunk(
    "notification/AddNotificationService",
    async (args, { rejectWithValue }) => {
        try {
            const { id, data } = args;
            const resp = await axios.post(`${url}/admin/create_notification`, data , 
            {
                headers: getAuthenticationHeader(),
            });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const GetSingleNotificationService = createAsyncThunk(
    "notification/GetSingleNotificationService",
    async (args, { rejectWithValue }) => {
      try {
        const { id } = args;
        const resp = await axios.get(`${url}/admin/get_single_notification/${id}`,
          {
            headers: getAuthenticationHeader(),
          });
        return resp.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  );

  export const DeleteNotificationService = createAsyncThunk(
    "notification/DeleteNotificationService",
    async (args, { rejectWithValue }) => {
      try {
        const { id } = args;
        const resp = await axios.delete(`${url}/admin/delete_notification/${id}`,
          {
            headers: getAuthenticationHeader(),
          });
        return resp.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  );

// User Slice
const NotificationSlice = createSlice({
    name: "notification",
    initialState: {
        error: false,
        loading: false,
        allActiveNotifications: [],
        allInactiveNotifications: [],
        singleNotification: [],
        EditNotification : [],
        deleteNotification: [],
        addNotification: [],
    },

    // reducers
    reducers: {
        setNotificationPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },

    // extra reducers and addcases
    extraReducers: (builder) => {
        builder

            //get all Notification
            .addCase(GetAllActiveNotification.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetAllActiveNotification.fulfilled, (state, action) => {
                state.loading = false;
                state.allActiveNotifications = action.payload;
            })
            .addCase(GetAllActiveNotification.rejected, (state) => {
                state.loading = false;
            })

            //Edit Notification
            .addCase(EditNotificationService.pending, (state) => {
                state.loading = true;
            })
            .addCase(EditNotificationService.fulfilled, (state, action) => {
                state.loading = false;
                state.singleNotification = action.payload.data;
            })
            .addCase(EditNotificationService.rejected, (state) => {
                state.loading = false;
            })

            //get single notification
            .addCase(GetSingleNotificationService.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetSingleNotificationService.fulfilled, (state, action) => {
                state.loading = false;
                state.singleNotification = action.payload.data;
            })
            .addCase(GetSingleNotificationService.rejected, (state) => {
                state.loading = false;
            })

            //delete single notification
            .addCase(DeleteNotificationService.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteNotificationService.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteNotification = action.payload;
            })
            .addCase(DeleteNotificationService.rejected, (state) => {
                state.loading = false;
            })

            //Add Notification
            .addCase(AddNotificationService.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddNotificationService.fulfilled, (state, action) => {
                state.loading = false;
                state.addNotification = action.payload.data;
            })
            .addCase(AddNotificationService.rejected, (state) => {
                state.loading = false;
            })
    },
});

export const { setNotificationPage } = NotificationSlice.actions;

export default NotificationSlice.reducer;