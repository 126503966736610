import React, { useState, useEffect } from "react";
import Header from "../Header";
import InnerHeader from "../InnerHeader";
import Sidebar from "../Sidebar";
import {
  GetSingleTenant,
  TenantImageUpload,
  UpdateTenant,
  GetTenantWheelPosition,
  PostTenantWheelPosition
} from "../../redux/features/TenantService";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { ChromePicker } from "react-color";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { GetAllCategoryService } from "../../redux/features/CategoryService";
import _ from "lodash";
import defaultImg from '../../images/tenatPrimaryImg.png';
import isURL from "validator/lib/isURL";
import { css } from "@emotion/react";
import { RingLoader } from "react-spinners"; // Importing RingLoader

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const SingleTenant = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id; //tenant_id
  // const [isVisibleImages, setisVisibleImages] = useState(false);
  // const [images, setImages] = useState([])
  const navigate = useNavigate();
  const [color, setColor] = useState("");
  // Fetch single user data
  const [isVisibleGeneral, setisVisibleGeneral] = useState(false);
  const [isVisibleContact, setisVisibleContact] = useState(false);
  const [isVisibleAbout, setisVisibleAbout] = useState(false);
  const [isVisibleFortune, setisVisibleFortune] = useState(false);
  const { allCategory } = useSelector((state) => state.category);
  const { singleTenantData } = useSelector((state) => state.tenants);
  const [filePrimaryName, setFilePrimaryName] = useState("");
  const [fileSecondaryName, setFileSecondaryName] = useState("");
  const [fileThirdName, setFileThirdName] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getSelectedColor = () => {
    return color;
  };

  const isValidUrl = (url) =>  {
    try {
      // Regular expression for a URL
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3})|" + // OR IP (v4) address
          "localhost|" + // OR localhost
          "([a-zA-Z\\d-]+\\.([a-zA-Z]{2,}))\\.?)" + // subdomain
          "(:\\d+)?(\\/[-a-zA-Z\\d%@_.~+&:]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
          "(#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
  
      return pattern.test(url);
    } catch (error) {
      return false;
    }
  }

  const validate = async (values) => {
    const errors = {};
    if (isVisibleGeneral) {
      if (!values?.tenant) {
        errors.tenant = "*Please enter a tenant name";
      }

      if (!values?.status) {
        errors.status = "*Please select a status";
      }
    } else if (isVisibleContact) {
      if (!values?.streetAddress) {
        errors.streetAddress = "*Please enter an address";
      }

      if (!values?.city) {
        errors.city = "*Please enter a city";
      }

      if (!values?.state) {
        errors.state = "*Please enter a state";
      }

      if (!values?.zip) {
        errors.zip = "*Please enter a zip code";
      } else if (!values.zip.trim().match(/^\d{5}(-\d{4})?$/)) {
        errors.zip = "*Please enter a valid zip code";
      }

      if (values?.website) {
        const trimmedWebsite = values.website.trim();
        if (!isValidUrl(trimmedWebsite)) {
          errors.website = "*Please enter a valid URL";
        }
      }

      if (values?.tenantPhone && !values.tenantPhone.trim().match(/^[0-9]{10}$/)) {
        errors.tenantPhone = "*Phone enter a valid phone number";
      }

      if (values?.tenantEmail && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.tenantEmail))) {
        errors.tenantEmail = "*Please type in a valid email";
      }
    } else if (isVisibleAbout) {
      if (!values?.tenantNumber) {
        errors.tenantNumber = "*Please enter a tenant number";
      }
      if (!values?.category) {
        errors.category = "*Please enter a category";
      }
      if (values.ticketURL && !isURL(values.ticketURL)) {
        errors.ticketURL = '*Please enter valid ticket link'
      }
      if (values.reservationURL && !isURL(values.reservationURL)) {
        errors.reservationURL = '*Please enter a valid reservation link';
      }
      if (values.orderOnlineURL && !isURL(values.orderOnlineURL)) {
        errors.orderOnlineURL = '*Please enter an valid order online link';
      }
    } else if (isVisibleFortune) {
      if (!values?.color) {
        errors.color = "*Please select a color";
      }
      if (!values?.discountText) {
        errors.discountText = "*Please enter the discount text";
      }
      if (!values?.discountSubText) {
        errors.discountSubText = "*Please enter the discount subtext";
      }
    }
    return errors;
  };

  // console.log(singleTenantDataN)

  useEffect(() => {
    dispatch(
      GetAllCategoryService({ page: 1, status: "1" })
    );

  }, [])

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        setIsLoading(true);
        await dispatch(GetSingleTenant({ id }));
        setIsLoading(false);
      }
      fetchData();
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      primary_image: singleTenantData?.data?.primary_image || "",
      secondary_image: singleTenantData?.data?.secondary_image || "",
      third_image: singleTenantData?.data?.third_image || "",
      tenant: singleTenantData?.data?.tenant || "",
      status: singleTenantData?.data?.status || "",
      streetAddress: singleTenantData?.data?.streetAddress || "",
      city: singleTenantData?.data?.city || "",
      state: singleTenantData?.data?.state || "",
      zip: singleTenantData?.data?.zip || "",
      website: singleTenantData?.data?.website || "",
      tenantPhone: singleTenantData?.data?.tenantPhone || "",
      tenantEmail: singleTenantData?.data?.tenantEmail || "",
      tenantNumber: singleTenantData?.data?.tenantNumber || "",
      category: singleTenantData?.data?.category?._id || "",
      ticketURL: singleTenantData?.data?.ticketURL || "",
      reservationURL: singleTenantData?.data?.reservationURL || "",
      orderOnlineURL: singleTenantData?.data?.orderOnlineURL || "",
      color: singleTenantData?.data?.color || "",
      discountText: singleTenantData?.data?.discountText || "",
      discountSubText: singleTenantData?.data?.discountSubText || "",
    },
    enableReinitialize: true,
    validate,
    onSubmit: async (values) => {
      const response = await dispatch(UpdateTenant({ id: id, data: values }));
      if (response.type === "tenant/UpdateTenant/fulfilled") {
        // console.log(response.type);
        dispatch(GetSingleTenant({ id }));
        toast.success(response?.type?.success, {
          autoClose: 3000,
        });
        setisVisibleGeneral(false);
        setisVisibleContact(false);
        setisVisibleAbout(false);
        setisVisibleFortune(false);
      } else {
        toast.error(response?.payload?.response?.data?.error, {
          autoClose: 3000,
        });
      }
    },
  });

  const handlePrimaryFileChange = async (file) => {
    if (file) {
      const formData = new FormData();
      // console.log(file);
      formData.append("files", file.currentTarget.files[0]);
      const res = await dispatch(TenantImageUpload(formData));
      // console.log(res);
      if (res.type === "tenants/TenantImageUpload/fulfilled") {
        setFilePrimaryName(res.payload?.files[0]?.mediaName);
        // console.log(res.payload?.files[0]);
        const data = {
          primary_image: res.payload?.files[0]?.mediaName,
          secondary_image: singleTenantData?.data?.secondary_image || "",
          third_image: singleTenantData?.data?.third_image || "",
          category: singleTenantData?.data?.category?._id || "",
        };
        const response = await dispatch(UpdateTenant({ id: id, data: data }));
        if (response.type === "tenant/UpdateTenant/fulfilled") {
          // console.log(response.type);
          dispatch(GetSingleTenant({ id }));
          toast.success("File uploaded successfully");
        } else {
          toast.error(response?.payload?.response?.data?.error, {
            autoClose: 3000,
          });
        }
        // return res.payload?.files[0];
      }
    }
  };

  const onChangePosition = async (e) => {
    const res = await dispatch(GetTenantWheelPosition());
    if (res.type === "tenant/GetTenantWheelPosition/fulfilled") {
      console.log(res.payload?.nextPositionValue);
      formik.setFieldValue("position",res.payload?.nextPositionValue)
    }
  }

  const handleSecondaryFileChange = async (file) => {
    // console.log(file);
    // console.log("adasdasdasdsa");
    if (file) {
      const formData = new FormData();
      // console.log(file);
      formData.append("files", file.currentTarget.files[0]);
      const res = await dispatch(TenantImageUpload(formData));
      // console.log(res);
      if (res.type === "tenants/TenantImageUpload/fulfilled") {
        setFileSecondaryName(res.payload?.files[0]?.mediaName);
        // console.log(res.payload?.files[0]);
        // return res.payload?.files[0];
        const data = {
          secondary_image: res.payload?.files[0]?.mediaName,
          category: singleTenantData?.data?.category?._id || "",
        };
        const response = await dispatch(UpdateTenant({ id: id, data: data }));
        if (response.type === "tenant/UpdateTenant/fulfilled") {
          // console.log(response.type);
          dispatch(GetSingleTenant({ id }));
          toast.success("File uploaded successfully");
        } else {
          toast.error(response?.payload?.response?.data?.error, {
            autoClose: 3000,
          });
        }
      }
    }
  };

  const handleThirdFileChange = async (file) => {
    // console.log("adasdasdasdsa");
    // console.log(file);
    if (file) {
      const formData = new FormData();
      // console.log(file);
      formData.append("files", file.currentTarget.files[0]);
      const res = await dispatch(TenantImageUpload(formData));
      // console.log(res);
      if (res.type === "tenants/TenantImageUpload/fulfilled") {
        setFileThirdName(res.payload?.files[0]?.mediaName);
        const data = {
          third_image: res.payload?.files[0]?.mediaName,
          category: singleTenantData?.data?.category?._id || "",
        };
        const response = await dispatch(UpdateTenant({ id: id, data: data }));
        if (response.type === "tenant/UpdateTenant/fulfilled") {
          // console.log(response.type);
          dispatch(GetSingleTenant({ id }));
          toast.success("File uploaded successfully");
        } else {
          toast.error(response?.payload?.response?.data?.error, {
            autoClose: 3000,
          });
        }
      }
    }
  };

  return (
    <>
      <div className="w-100 h-100 ">
        <Header />
        <div className="d-flex position-relative">
          <Sidebar />
          <div className="MainSection">
            {/* Inner Header Starts */}
            <InnerHeader
              text={"Tenants / " + singleTenantData?.data?.tenant}
              arrow={true}
            >
              <div> </div>
            </InnerHeader>

            <div className="conten-div single-tenant-data-tbl ">
              {/* Tenant Images */}
              <div className="tenant-img-ui">
                <h2 className="BoxTitle">Images</h2>
                {isLoading ? (
                  <div className="loader-container m-auto">
                    <RingLoader
                      css={override}
                      size={50}
                      color={"#00639B"}
                      loading={isLoading}
                    />
                  </div>
                ) : (
                  <div className="img-tenant-row">
                    <div className="">
                      <img
                        className="tenant-img"
                        src={
                          (singleTenantData?.data?.primary_image) ? 
                          process.env.REACT_APP_BACKEND_URL +
                          "uploads/" +
                          singleTenantData?.data?.primary_image
                          : defaultImg
                        }
                      />

                      <div className="primary-img add-img-tenant">
                        <input
                          type="file"
                          name="input-file-1"
                          id="img1"
                          onChange={handlePrimaryFileChange}
                          style={{ display: "none" }}
                        />
                        {error && <div>{error}</div>}
                        <label htmlFor="img1" className="m-0">
                          Set Primary Image
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <img
                        className="tenant-img"
                        src={
                          (singleTenantData?.data?.secondary_image) ?
                          process.env.REACT_APP_BACKEND_URL +
                          "uploads/" +
                          singleTenantData?.data?.secondary_image
                          : defaultImg
                        }
                      />

                      <div className="img1 add-img-tenant">
                        <input
                          type="file"
                          name="input-file-2"
                          id="img2"
                          onChange={handleSecondaryFileChange}
                          style={{ display: "none" }}
                        />
                        {error && <div>{error}</div>}
                        <label htmlFor="img2" className="m-0">
                          Set Image 2
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <img
                        className="tenant-img"
                        src={
                          (singleTenantData?.data?.third_image) ?
                          process.env.REACT_APP_BACKEND_URL +
                          "uploads/" +
                          singleTenantData?.data?.third_image
                          : defaultImg
                        }
                      />

                      <div className="img2 add-img-tenant">
                        <input
                          type="file"
                          name="input-file-3"
                          id="img3"
                          onChange={handleThirdFileChange}
                          style={{ display: "none" }}
                        />
                        {error && <div>{error}</div>}
                        <label htmlFor="img3" className="m-0">
                          Set Image 3
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="tenant-general-ui">
                <>
                  <div className="w-100">
                    <div className="d-flex justify-content-between mb-4">
                      <h2 className="BoxTitle">General</h2>
                      {isVisibleGeneral ? (
                        <button
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                          className="SiteBtn2 export-csv-btn btn"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          disabled={
                            isVisibleContact ||
                            isVisibleAbout ||
                            isVisibleFortune
                          }
                          onClick={() => {
                            if (
                              !isVisibleContact &&
                              !isVisibleAbout &&
                              !isVisibleFortune
                            ) {
                              setisVisibleGeneral(true);
                            }
                          }}
                          className="SiteBtn edit-btn-tenant btn"
                        >
                          Edit
                        </button>
                      )}
                    </div>
                    {/* Edit and Display Design */}
                    {isVisibleGeneral ? (
                      <div className="">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="">
                            <div className="did-floating-label-content">
                              <input
                                name="tenant"
                                className="did-floating-input"
                                onChange={formik.handleChange}
                                value={formik?.values?.tenant}
                                type="text"
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Tenant
                              </label>
                              {formik?.touched?.tenant &&
                                formik?.errors?.tenant ? (
                                <div className="error">
                                  {formik?.errors?.tenant}
                                </div>
                              ) : null}
                            </div>
                            <div className="did-floating-label-content">
                              <select
                                name="status"
                                className="did-floating-select"
                                onChange={formik.handleChange}
                                value={formik?.values?.status}
                              >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </select>
                              <label className="did-floating-label">
                                Status
                              </label>
                              {formik?.touched?.status &&
                                formik?.errors?.status ? (
                                <div className="error">
                                  {formik?.errors?.status}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div className="ms-3">
                        <div className="single-tenant mb-4">
                          <div className="single-user-p-main">Tenant</div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.tenant}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Status</div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.status === "1" ? (
                              <p>Active</p>
                            ) : (
                              <p>Inactive</p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </div>

              {/* Tenant Contact Data */}
              <div className="tenant-contact-ui ">
                <>
                  <div className="w-100">
                    <div className="d-flex justify-content-between mb-4">
                      <h2 className="BoxTitle">Contact</h2>
                      {isVisibleContact ? (
                        <button
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                          className="SiteBtn2 export-csv-btn btn"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          disabled={
                            isVisibleGeneral ||
                            isVisibleAbout ||
                            isVisibleFortune
                          }
                          onClick={() => {
                            if (
                              !isVisibleGeneral &&
                              !isVisibleAbout &&
                              !isVisibleFortune
                            ) {
                              setisVisibleContact(true);
                            }
                          }}
                          className="SiteBtn edit-btn-tenant btn "
                        >
                          Edit
                        </button>
                      )}
                    </div>
                    {/* Edit and Display Design */}
                    {isVisibleContact ? (
                      <div className="">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="">
                            <div className="did-floating-label-content">
                              <input
                                name="streetAddress"
                                className="did-floating-input"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.streetAddress
                                  //  || singleTenantData?.data?.streetAddress
                                }
                                type="text"
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Street Address
                              </label>
                              {formik?.touched?.streetAddress &&
                                formik?.errors?.streetAddress ? (
                                <div className="error">
                                  {formik?.errors?.streetAddress}
                                </div>
                              ) : null}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                name="city"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.city
                                  // ||singleTenantData?.data?.city
                                }
                                type="text"
                                placeholder=" "
                              />
                              <label className="did-floating-label">City</label>
                              {formik?.touched?.city && formik?.errors?.city ? (
                                <div className="error">
                                  {formik?.errors?.city}
                                </div>
                              ) : null}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                name="state"
                                className="did-floating-input"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.state
                                  // ||singleTenantData?.data?.state
                                }
                                type="text"
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                State
                              </label>
                              {formik?.touched?.state &&
                                formik?.errors?.state ? (
                                <div className="error">
                                  {formik?.errors?.state}
                                </div>
                              ) : null}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                name="zip"
                                className="did-floating-input"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.zip
                                  // || singleTenantData?.data?.zip
                                }
                                type="text"
                                placeholder=" "
                              />
                              <label className="did-floating-label">Zip</label>
                              {formik?.touched?.zip && formik?.errors?.zip ? (
                                <div className="error">
                                  {formik?.errors?.zip}
                                </div>
                              ) : null}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                name="website"
                                className="did-floating-input"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.website
                                  // ||singleTenantData?.data?.website
                                }
                                type="text"
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Website
                              </label>
                              {formik?.touched?.website &&
                                formik?.errors?.website ? (
                                <div className="error">
                                  {formik?.errors?.website}
                                </div>
                              ) : null}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                name="tenantPhone"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.tenantPhone
                                  // ||singleTenantData?.data?.tenantPhone
                                }
                                type="tele"
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Tenant Phone
                              </label>
                              {formik?.touched?.tenantPhone &&
                                formik?.errors?.tenantPhone ? (
                                <div className="error">
                                  {formik?.errors?.tenantPhone}
                                </div>
                              ) : null}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                name="tenantEmail"
                                className="did-floating-input"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.tenantEmail
                                  // ||singleTenantData?.data?.tenantEmail
                                }
                                type="email"
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Tenant Email
                              </label>
                              {formik?.touched?.tenantEmail &&
                                formik?.errors?.tenantEmail ? (
                                <div className="error">
                                  {formik?.errors?.tenantEmail}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div className="ms-3">
                        <div className="single-tenant mb-4">
                          <div className="single-user-p-main">
                            Street Address
                          </div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.streetAddress}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">City</div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.city}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">State</div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.state}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Zip</div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.zip}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Website</div>
                          <div className="single-user-p-desc">
                            <NavLink
                              target="_blank"
                              to={singleTenantData?.data?.website}
                            >
                              {singleTenantData?.data?.website}
                            </NavLink>
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Tenant Phone</div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.tenantPhone}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Tenant Email</div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.tenantEmail}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </div>

              {/* Tenat About Data */}
              <div className="tenant-about-ui">
                <>
                  <div className="w-100">
                    <div className="d-flex justify-content-between mb-4">
                      <h2 className="BoxTitle">About</h2>
                      {isVisibleAbout ? (
                        <button
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                          className="SiteBtn2 export-csv-btn btn"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          disabled={
                            isVisibleContact ||
                            isVisibleGeneral ||
                            isVisibleFortune
                          }
                          onClick={() => {
                            if (
                              !isVisibleGeneral &&
                              !isVisibleContact &&
                              !isVisibleFortune
                            ) {
                              setisVisibleAbout(true);
                            }
                          }}
                          className="SiteBtn edit-btn-tenant btn"
                        >
                          Edit
                        </button>
                      )}
                    </div>
                    {/* Edit and Display Design */}
                    {isVisibleAbout ? (
                      <div className="">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="">
                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                name="tenantNumber"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.tenantNumber
                                  // || singleTenantData?.data?.tenantNumber
                                }
                                type="text"
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Tenant Number
                              </label>
                              {formik?.touched?.tenantNumber &&
                                formik?.errors?.tenantNumber ? (
                                <div className="error">
                                  {formik?.errors?.tenantNumber}
                                </div>
                              ) : null}
                            </div>

                            <div className="did-floating-label-content">
                              <select
                                name="category"
                                className="did-floating-select"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.category?._id
                                  // ||singleTenantData?.data?.category?._id
                                }
                              >
                                {_.map(
                                  allCategory?.data?.records,
                                  (data, key) => (<>
                                    <option key={key} value={data?._id} selected={data?._id === singleTenantData?.data?.category?._id} >
                                      {data?.categoryType}
                                    </option>
                                  </>
                                  )
                                )}
                              </select>
                              <label className="did-floating-label">
                                Category
                              </label>

                              {formik?.touched?.category &&
                                formik?.errors?.category ? (
                                <div className="error">
                                  {formik?.errors?.category}
                                </div>
                              ) : null}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                name="ticketURL"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.ticketURL
                                  // || singleTenantData?.data?.ticketURL
                                }
                                type="url"
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Tickets URL
                              </label>
                              {formik?.touched?.ticketURL &&
                                formik?.errors?.ticketURL ? (
                                <div className="error">
                                  {formik?.errors?.ticketURL}
                                </div>
                              ) : null}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                name="reservationURL"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.reservationURL
                                  //  || singleTenantData?.data?.reservationURL
                                }
                                type="url"
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Reservation URL
                              </label>
                              {formik?.touched?.reservationURL &&
                                formik?.errors?.reservationURL ? (
                                <div className="error">
                                  {formik?.errors?.reservationURL}
                                </div>
                              ) : null}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                name="orderOnlineURL"
                                onChange={formik.handleChange}
                                value={
                                  formik?.values?.orderOnlineURL
                                  //  ||singleTenantData?.data?.orderOnlineURL
                                }
                                type="url"
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Order Online URL
                              </label>
                              {formik?.touched?.orderOnlineURL &&
                                formik?.errors?.orderOnlineURL ? (
                                <div className="error">
                                  {formik?.errors?.orderOnlineURL}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div className="ms-3">
                        <div className="single-tenant mb-4">
                          <div className="single-user-p-main">
                            Tenant Number
                          </div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.tenantNumber}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Category</div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.category?.categoryType}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Tickets URL</div>
                          <div className="single-user-p-desc">
                            {/* {singleTenantData?.data?.ticketURL} */}
                            <NavLink
                              target="_blank"
                              to={singleTenantData?.data?.ticketURL}
                            >
                              {singleTenantData?.data?.ticketURL}
                            </NavLink>
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">
                            Reservation URL
                          </div>
                          <div className="single-user-p-desc">
                            <NavLink
                              target="_blank"
                              to={singleTenantData?.data?.reservationURL}
                            >
                              {singleTenantData?.data?.reservationURL}
                            </NavLink>
                            {/* {singleTenantData?.data?.reservationURL} */}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">
                            Order Online URL
                          </div>
                          <div className="single-user-p-desc">
                            <NavLink
                              target="_blank"
                              to={singleTenantData?.data?.orderOnlineURL}
                            >
                              {singleTenantData?.data?.orderOnlineURL}
                            </NavLink>
                            {/* {singleTenantData?.data?.orderOnlineURL} */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </div>

              {/* Tenant Wheel of Fortune Data */}
              <div className="tenant-fortune-ui mb-5 ">
                <>
                  <div className="w-100">
                    <div className="d-flex justify-content-between mb-4">
                      <h2 className="BoxTitle">Wheel of Fortune</h2>
                      {isVisibleFortune ? (
                        <button
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                          className="SiteBtn2 export-csv-btn btn"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          disabled={
                            isVisibleContact ||
                            isVisibleAbout ||
                            isVisibleGeneral
                          }
                          onClick={() => {
                            if (
                              !isVisibleGeneral &&
                              !isVisibleContact &&
                              !isVisibleAbout
                            ) {
                              setisVisibleFortune(true);
                            }
                          }}
                          className="SiteBtn edit-btn-tenant btn"
                        >
                          Edit
                        </button>
                      )}
                    </div>

                    {/* Edit and Display Design */}
                    {isVisibleFortune ? (
                      <div className="">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="">
                            <div>
                              <ChromePicker
                                className="my-3"
                                color={formik?.values?.color}
                                // value={ formik?.values?.color || singleTenantData?.data?.color }
                                onChange={(newColor) => { formik.setFieldValue('color', newColor?.hex); setColor(newColor?.hex); } } />
                              {formik?.touched?.color && formik?.errors?.color ? (
                                <div className="error pb-2">
                                  {formik?.errors?.color}
                                </div>
                              ) : null}
                            </div>
                              <div className="did-floating-label-content">
                                <input
                                  name="discountText"
                                  className="did-floating-input"
                                  onChange={formik.handleChange}
                                  value={formik?.values?.discountText
                                    // || singleTenantData?.data?.discountText
                                  }
                                  type="text"
                                  placeholder=" " />
                                <label className="did-floating-label">
                                  Discount Text
                                </label>
                                {formik?.touched?.discountText &&
                                  formik?.errors?.discountText ? (
                                  <div className="error">
                                    {formik?.errors?.discountText}
                                  </div>
                                ) : null}
                              </div>
                              <div className="did-floating-label-content">
                                <input
                                  name="discountSubText"
                                  className="did-floating-input"
                                  onChange={formik.handleChange}
                                  value={formik?.values?.discountSubText
                                    // ||  singleTenantData?.data?.discountSubText
                                  }
                                  type="text"
                                  placeholder=" " />
                                <label className="did-floating-label">
                                  Discount Subtext
                                </label>
                                {formik?.touched?.discountSubText &&
                                  formik?.errors?.discountSubText ? (
                                  <div className="error">
                                    {formik?.errors?.discountSubText}
                                  </div>
                                ) : null}
                              </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div className="ms-3">
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Color</div>
                          <div className="single-user-p-desc">
                            <div style={{ marginTop: "10px" }}>
                              {singleTenantData?.data?.color}
                            </div>
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">
                            Discount Text
                          </div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.discountText}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">
                            Discount Subtext
                          </div>
                          <div className="single-user-p-desc">
                            {singleTenantData?.data?.discountSubText}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleTenant;
