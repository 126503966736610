import Cookies from "js-cookie";
import React from "react";
import { Link } from "react-router-dom";

const Header = () => {

  const username = Cookies.get('username');
  
  const logout = () => {
      localStorage.clear();
      const cookies = document.cookie.split(";");
      Cookies.remove('auth-token');
      Cookies.remove('signin-email-bd');
      Cookies.remove('breadowntown-email-login');
      Cookies.remove('username');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      window.location.href = '/';
  };
  return (
    <div
      className="LoggedHeader d-flex justify-content-between align-items-center "
      style={{ background: "linear-gradient(0deg, rgba(0, 99, 155, 0.14) 0%, rgba(0, 99, 155, 0.14) 100%)" }}>
      <div className="d-flex align-items-center gap-1">
        <div>
        </div>
        <div className="HeaderSiteName">
         <Link style={{color : 'black'}} to={'/'}>Brea Downtown</Link>
        </div>
      </div>
      <div
        className="menuDropdown"
      >
        {/* User Btn */}
        <div className="btn-group profile-btn-drpdwn">
          <button className="btn btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {username}
          </button>
          <ul className="dropdown-menu text-center">
            <li>
              <a className="cursor-pointer" onClick={() => logout()}>
                <svg style={{ marginRight : "8px"}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                Log out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
