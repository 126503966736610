import React from "react";
import { NavLink, useNavigate } from 'react-router-dom';

const InnerHeader = ({ text, arrow, children, onClick }) => {
  const navigate = useNavigate();

  return (
    <div className="mb-4 MainTitle">
      <div className="sidebar-sidemenu">
        <div className="SidebarMenuMobile">
          <button id="SidebarMenuMobileBtn"><i className="fas fa-align-left"></i></button>
        </div>
        <div className="text-dark fs-4 TitleText">
          {
            arrow &&
            <NavLink style={{ marginRight: '20px' }} onClick={() => navigate(-1)}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
                  fill="#42474E" />
              </svg>
            </NavLink>
          }
          <div className="innerheader-text">
            {text}
          </div>
        </div>
      </div>
      <div className="mt-md-0 mt-4">
        {children && children}
      </div>
    </div>
  );
};

export default InnerHeader;
