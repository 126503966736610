import React from 'react'

const PageNotFound = () => {
  return (
    <div className='pagenotfound'>
      <div className="404notfound" style={{ fontFamily: 'Roboto', position: "relative", top: '10%' }}>
        <p className='text-404'>404</p>
        <p className="text-pagenotfound my-4">Page not found</p>
        <p className="text-dess my-4">--</p>
      </div>
    </div>
  )
}

export default PageNotFound;
