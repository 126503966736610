import React, { useState, useEffect, useRef, useMemo } from "react";
import { GetAllTenants } from "../../redux/features/TenantService";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Header";
import Sidebar from "../Sidebar";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import AddTenant from "./AddTenant";
import InnerHeader from "../InnerHeader";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Common/Loader";
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners'; // Import RingLoader
import { toast } from "react-toastify";
import { GetAllCategoryService } from "../../redux/features/CategoryService";
import { setActiveTab } from "../../redux/features/MiscService";


const columns = [
  {
    id: 1,
    name: "Name",
    selector: (row) => row.tenant,
    sortable: true,
    reorder: true,
    width: "150px",
  },
  {
    id: 2,
    name: "Category",
    selector: (row) => row.category?.categoryType,
    sortable: true,
    reorder: true,
    width: "130px",
  },
  {
    id: 3,
    name: "Website",
    selector: (row) => row.website,
    sortable: true,
    reorder: true,
    width: "200px",
  },
  {
    id: 4,
    name: "Ticket Link",
    selector: (row) => {
      if (row.ticketURL === undefined || row.ticketURL === "") {
        row = "No";
      } else {
        row = "Yes";
      }
      return row;
    },
    sortable: true,
    reorder: true,
    width: "150px",
  },
  {
    id: 5,
    name: "Order Online Link",
    selector: (row) => {
      if (row.orderOnlineURL === undefined || row.orderOnlineURL === "") {
        row = "No";
      } else {
        row = "Yes";
      }
      return row;
    },
    sortable: true,
    reorder: true,
    width: "190px"
  },
  {
    id: 6,
    name: "Reservation Link",
    selector: (row) => {
      if (row.reservationURL === undefined || row.reservationURL === "") {
        row = "No";
      } else {
        row = "Yes";
      }
      return row;
    },
    sortable: true,
  },
  {
    id: 7,
    name: "Last edited",
    selector: (row) =>
      moment
        .tz(row.updatedAt, "america/los_angeles")
        .format("MM/DD/yyyy @ HH:mm") + "h PT",
    sortable: true,
    reorder: true,
    width: "200px",
  },
];

const TenantsList = () => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState();
  // const [activeTab, setActiveTab] = useState(0);
  const miscState = useSelector((state) => state.misc);
  const activeTab = useMemo(() => miscState?.tenants?.activeTab || 0, [miscState?.tenants?.activeTab]);
  const [loading, setLoading] = useState(false);
  const [isComponentVisible, setComponentVisibility] = useState(false);
  const { allActiveTenants } = useSelector((state) => state.tenants);
  const [csvData, setCsvData] = useState([]);
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const [currentPageInactive, setCurrentPageInactive] = useState(1);
  const [isVisible, setIsVisible] = useState({
    active: false,
    inActive: false
  });
  const tabs = ["Active", "Inactive"];
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterNoMatch, setFilterNoMatch] = useState();
  const [isSearchText, setSearchText] = useState({
    active: "",
    inActive: ""
  });
  const [formData, setFormData] = useState({
    active: {
      tenant: "",
      category: "",
      website: "",
      ticketURL: "",
      orderOnlineURL: "",
      reservationURL: "",
    },
    inActive: {
      tenant: "",
      category: "",
      website: "",
      ticketURL: "",
      orderOnlineURL: "",
      reservationURL: "",
    }
  });
  const { allCategory } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  document.title = "Tenants - Brea downtown";

  const handleInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value;
    if (!activeTab) {
      setFormData({ ...formData, active: { ...formData?.active, [name]: value } })
    } else {
      setFormData({ ...formData, inActive: { ...formData?.inActive, [name]: value } })
    }
  };

  useEffect(() => {
    dispatch(
      GetAllCategoryService({ status: "1", limit: 10 })
    );
  }, []);

  const clearBtn = async (e) => {
    if (activeTab === 0) {
      await dispatch(
        GetAllTenants({ page: currentPageActive, status: "1", limit: 10 })
      );
    } else if (activeTab === 1) {
      await dispatch(
        GetAllTenants({ page: currentPageActive, status: "0", limit: 10 })
      );
    }
    setFormData({
      active: {
        tenant: "",
        category: "",
        website: "",
        ticketURL: "",
        orderOnlineURL: "",
        reservationURL: "",
      },
      inActive: {
        tenant: "",
        category: "",
        website: "",
        ticketURL: "",
        orderOnlineURL: "",
        reservationURL: "",
      }
    });
  };

  //Active Inactive Tab handler
  const handleTabClick = async (index) => {
    setLoading(true);
    // setIsVisible(!isVisible);
    dispatch(setActiveTab({ tabOf: 'tenants', activeTab: index }));
    if (index === 0) {
      const res = await dispatch(GetAllTenants({ search: isSearchText?.active, page: currentPageActive, status: "1", filter: formData?.active }));
      if (res.type === "tenant/GetAllTenants/fulfilled") {
        setLoading(false);
      }
    } else {
      const res = await dispatch(GetAllTenants({ search: isSearchText?.inActive, page: currentPageInactive, status: "0", filter: formData?.inActive }));
      if (res.type === "tenant/GetAllTenants/fulfilled") {
        setLoading(false);
      }
    }
    // setActiveTab(index);
  };

  const handleRowClick = (row) => {
    navigate(`/tenants/${row?._id}`);
  };

  //search
  const handleSearch = async (searchText, number) => {
    if (activeTab === 0 && number === 0) {
      setSearchText({ active: searchText, inActive: isSearchText?.inActive })
      await dispatch(
        GetAllTenants({
          page: currentPageActive,
          limit: rowsPerPage,
          status: "1",
          search: searchText,
        })
      );
    } else {
      setSearchText({ active: isSearchText?.active, inActive: searchText })
      await dispatch(
        GetAllTenants({
          page: currentPageInactive,
          status: "0",
          search: searchText,
        })
      );
    }
    setFormData({
      active: {
        tenant: "",
        category: "",
        website: "",
        ticketURL: "",
        orderOnlineURL: "",
        reservationURL: "",
      },
      inActive: {
        tenant: "",
        category: "",
        website: "",
        ticketURL: "",
        orderOnlineURL: "",
        reservationURL: "",
      }
    });
  };


  // console.log(allCategory);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        if (activeTab === 0) {
          const res = await dispatch(GetAllTenants({ page: currentPageActive, status: "1" }));
          if (res.type === "tenant/GetAllTenants/fulfilled") {
            setLoading(false);
          }
        }
        else {
          const res = await dispatch(GetAllTenants({ page: currentPageActive, status: "0" }));
          if (res.type === "tenant/GetAllTenants/fulfilled") {
            setLoading(false);
          }
        }
        setLoading(false); // Set loading to true when fetching data
      } catch {
        toast.error("failed to load app users");
        setLoading(false);
      }
    }
    fetchData();
  }, []);


  //calling EditUser function
  const onSubmit = () => { };

  const toggleVisibility = async () => {
    if (activeTab === 0) {
      await dispatch(GetAllTenants({ status: "1" }));
    } else {
      await dispatch(GetAllTenants({ status: "0" }))
    }
    clearBtn();
    const visible = {
      active: !activeTab ? !isVisible?.active : activeTab === 1 ? isVisible?.active : false,
      inActive: activeTab ? !isVisible?.inActive : activeTab === 0 ? isVisible?.inActive : false
    }
    setIsVisible(visible);
    setFilterText();
    setFilterNoMatch();
  };

  const headers = [
    { label: "Name", key: "tenant" },
    { label: "Category", key: "category" },
    { label: "Website", key: "website" },
    { label: "Ticket Link", key: "ticketURL" },
    { label: "Order Online Link", key: "orderOnlineURL" },
    { label: "Reservation Link", key: "reservationURL" },
    { label: "Last Edited", key: "updatedAt" },
  ];

  const filterApply = async (e) => {
    e.preventDefault();

    if (activeTab === 0) {
      await dispatch(
        GetAllTenants({
          page: currentPageActive,
          status: "1",
          limit: 10,
          filter: formData?.active,
        })
      );
    } else if (activeTab === 1) {
      await dispatch(
        GetAllTenants({
          page: currentPageInactive,
          status: "0",
          limit: 10,
          filter: formData?.inActive,
        })
      );
    }
    setFilterNoMatch(true);
  };

  const getCsvData = async () => {
    if (activeTab === 0) {
      const appuserTableData = await _.map(
        allActiveTenants?.data?.records,
        (tenantDetails, userIndex) => {
          return {
            tenant: tenantDetails?.tenant,
            category: tenantDetails?.category?.categoryType,
            website: tenantDetails?.website === undefined || tenantDetails?.website === "" ? '' : tenantDetails?.website,
            position: tenantDetails?.position === undefined || tenantDetails?.position === "" ? 'N/A' : tenantDetails?.position,
            ticketURL: tenantDetails?.ticketURL === undefined || tenantDetails?.ticketURL === "" ? '' : tenantDetails?.ticketURL,
            orderOnlineURL: tenantDetails?.orderOnlineURL === undefined || tenantDetails?.orderOnlineURL === "" ? '' : tenantDetails?.orderOnlineURL,
            reservationURL: tenantDetails?.reservationURL,
            updatedAt: moment
              .tz(tenantDetails?.updatedAt, "america/los_angeles")
              .format("MM/DD/yyyy @ HH:mm") + "h PT",
          };
        }
      );
      setCsvData(appuserTableData);
    } else {
      const appuserTableData = await _.map(
        allActiveTenants?.data?.records,
        (tenantDetails, userIndex) => {
          return {
            tenant: tenantDetails?.tenant,
            category: tenantDetails?.category?.categoryType,
            website: tenantDetails?.website === undefined || tenantDetails?.website === "" ? '' : tenantDetails?.website,
            position: tenantDetails?.position === undefined || tenantDetails?.position === "" ? 'N/A' : tenantDetails?.position,
            ticketURL: tenantDetails?.ticketURL === undefined || tenantDetails?.ticketURL === "" ? '' : tenantDetails?.ticketURL,
            orderOnlineURL: tenantDetails?.orderOnlineURL === undefined || tenantDetails?.orderOnlineURL === "" ? '' : tenantDetails?.orderOnlineURL,
            reservationURL: tenantDetails?.reservationURL,
            updatedAt: moment
              .tz(tenantDetails?.updatedAt, "america/los_angeles")
              .format("MM/DD/yyyy @ HH:mm") + "h PT",
          };
        }
      );
      setCsvData(appuserTableData);
    }
  };

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

  return (
    <>
      {/* {loading && <Loader />} */}
      <div className="w-100 h-100 ">
        <Header />
        <div className="d-flex position-relative">
          <Sidebar />
          <div className="MainSection">
            <InnerHeader text="Tenants" arrow={false}>
              <div className="sidebar-btn">
                <div className="me-3">

                  <CSVLink
                    className="csvLink"
                    data={csvData}
                    headers={headers}
                    filename="Tenants-List"
                    onClick={(e) => getCsvData()}
                  >
                    <button className="SiteBtn export-csv-btn btn">
                      Export{" "}</button>
                  </CSVLink>
                </div>
                {isComponentVisible ? (
                  <button
                    onClick={onSubmit}
                    className="SiteBtn export-csv-btn btn"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      navigate("/tenants/add");
                    }}
                    className="SiteBtn2 export-csv-btn btn"
                  >
                    Add
                  </button>
                )}
              </div>
            </InnerHeader>

            {isComponentVisible ? (
              <AddTenant />
            ) : (
              <div className="conten-div">
                {/* search and filter */}
                <div className="row justify-content-between">
                  <div className="col-md-4 col-lg-3 col-md-4 col-8 mb-3 mb-lg-0">
                    <div className="searchbarmain ">
                      <input
                        type="text"
                        className="inputsearchbarmain"
                        name=""
                        id="search"
                        placeholder="Search"
                        value={!activeTab ? isSearchText?.active : isSearchText?.inActive}
                        onChange={(e) => handleSearch(e.target.value, activeTab)}
                      />
                      <button className="searchbtn" >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1479_513)">
                            <path
                              d="M18.0831 16.3333H17.1614L16.8348 16.0183C18.2348 14.385 18.9581 12.1567 18.5614 9.78833C18.0131 6.545 15.3064 3.955 12.0398 3.55833C7.10475 2.95166 2.95142 7.105 3.55809 12.04C3.95475 15.3067 6.54475 18.0133 9.78809 18.5617C12.1564 18.9583 14.3848 18.235 16.0181 16.835L16.3331 17.1617V18.0833L21.2914 23.0417C21.7698 23.52 22.5514 23.52 23.0298 23.0417C23.5081 22.5633 23.5081 21.7817 23.0298 21.3033L18.0831 16.3333ZM11.0831 16.3333C8.17809 16.3333 5.83309 13.9883 5.83309 11.0833C5.83309 8.17833 8.17809 5.83333 11.0831 5.83333C13.9881 5.83333 16.3331 8.17833 16.3331 11.0833C16.3331 13.9883 13.9881 16.3333 11.0831 16.3333Z"
                              fill="#42474E"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1479_513">
                              <rect width="28" height="28" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>
                  {(activeTab === 0 && isVisible?.active) || (activeTab === 1 && isVisible?.inActive) ?
                    <>
                      <div className="col-lg-7 col-md-5 col-12 order-3 order-md-2">
                        <div className="col-md-12">
                          <div className="row TenantFilterBox">
                            <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                              <div className="input-group">
                                <input
                                  type="text"
                                  value={!activeTab ? formData?.active?.tenant : formData?.inActive?.tenant}
                                  onChange={(e) => handleInputChange(e)}
                                  name="tenant"
                                  id="tenant"
                                  className="form-control"
                                  placeholder="Search by Name"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                              <select
                                name="category"
                                value={!activeTab ? formData?.active?.category : formData?.inActive?.category}
                                onChange={(e) => handleInputChange(e)}
                                className="form-select from-control select2-custom text-left-txt"
                                aria-label="Default select example"
                              >
                                <option selected>Search by Category </option>
                                <option disabled>Select Category</option>
                                {_.map(allCategory?.data?.records, (data, key) => (

                                  <option key={key} value={data?._id} >
                                    {data?.categoryType}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                              <div className="input-group">
                                <input
                                  type="text"
                                  name="website"
                                  value={!activeTab ? formData?.active?.website : formData?.inActive?.website}
                                  id="website"
                                  onChange={(e) => handleInputChange(e)}
                                  className="form-control"
                                  placeholder="Search by Website"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                              <select
                                name="ticketURL"
                                value={!activeTab ? formData?.active?.ticketURL : formData?.inActive?.ticketURL}
                                onChange={(e) => handleInputChange(e)}
                                className="form-select from-control select2-custom text-left-txt"
                                aria-label="Default select example"
                              >
                                <option selected>Search by TicketURL </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                              <select
                                name="orderOnlineURL"
                                value={!activeTab ? formData?.active?.orderOnlineURL : formData?.inActive?.orderOnlineURL}
                                onChange={(e) => handleInputChange(e)}
                                className="form-select from-control select2-custom text-left-txt"
                                aria-label="Default select example"
                              >
                                <option selected>
                                  Search by Order Online{" "}
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                              <select
                                name="reservationURL"
                                value={!activeTab ? formData?.active?.reservationURL : formData?.inActive?.reservationURL}
                                onChange={(e) => handleInputChange(e)}
                                className="form-select from-control select2-custom text-left-txt"
                                aria-label="Default select example"
                              >
                                <option selected>
                                  Search by Reservation URL{" "}
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-end">
                          <button
                            className="SiteBtn main-filter-btn btn"
                            onClick={clearBtn}
                          >
                            Clear
                          </button>
                          <button
                            className="SiteBtn main-filter-btn btn ms-2"
                            onClick={(e) => {
                              filterApply(e);
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </>
                    : <></>}
                  <div className="col-lg-2 col-md-3 col-4 order-2 order-md-3 text-end">
                    <button
                      className="SiteBtn main-filter-btn btn"
                      onClick={toggleVisibility}>
                      Filter
                    </button>

                  </div>
                </div>

                <div className="col-md-6 d-flex mb-5 mt-4">
                  {tabs.map((tab, index) => {
                    return (
                      <div
                        key={`${index}-tenant-tab`}
                        className="TabButton"
                        onClick={() => handleTabClick(index)}
                        style={{
                          borderBottom:
                            activeTab === index ? "2px solid #00639B" : "none",
                          color: activeTab === index ? "#1A1C1E" : "#42474E",
                        }}
                      >
                        {tab}
                      </div>
                    );
                  })}
                </div>

                {/* <Tenant data Table /> */}
                {activeTab === 0 && (
                  <div>
                    <div className="TableData">
                      {loading ? (
                        <div className="sweet-loading m-auto">
                          <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                        </div>
                      ) : (
                        <DataTable
                          title=""
                          columns={columns}
                          defaultSortFieldId={1}
                          pagination
                          data={allActiveTenants?.data?.records}
                          onRowClicked={handleRowClick}
                          highlightOnHover
                          persistTableHead
                          noDataComponent={
                            <div className="header-text-container">
                              {isSearchText?.inActive || isSearchText?.active ?
                                <p className="header-text no-data-text">There are no records to display </p>
                                : (filterNoMatch ?
                                  <p className="header-text no-data-text"> No results matching your filter. Try removing the Filter </p>
                                  : <p className="header-text no-data-text">There are no records to display</p>)
                              }
                            </div>
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {activeTab === 1 && (
                  <div>
                    <div className="TableData">
                      {loading ? (
                        <div className="sweet-loading m-auto">
                          <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                        </div>
                      ) : (
                        <DataTable
                          title=""
                          columns={columns}
                          defaultSortFieldId={1}
                          pagination
                          data={allActiveTenants?.data?.records}
                          onRowClicked={handleRowClick}
                          highlightOnHover
                          persistTableHead
                          noDataComponent={
                            <div className="header-text-container">
                              {isSearchText?.inActive || isSearchText?.active ?
                                <p className="header-text">There are no records to display </p>
                                : (filterNoMatch ?
                                  <p className="header-text"> No results matching your filter. Try removing the Filter </p>
                                  : <p className="header-text">There are no records to display</p>)
                              }
                            </div>
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantsList;
