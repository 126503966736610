import React, { useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import InnerHeader from "../InnerHeader";
import { useDispatch } from 'react-redux';
import { UploadMedia } from '../../redux/features/CommonFileService';
import { AddCategoryService } from '../../redux/features/CategoryService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddCategory = () => {
    const dispatch = useDispatch();
    const [fileName, setFilename] = useState('')
    const [categoryType, setCategoryType] = useState('')
    const [status, setStatus] = useState('');
    const navigate = useNavigate();

    const handleFileChange = async (file) => {
        if (file) {
            const formData = new FormData();
            formData.append("files", file.currentTarget.files[0]);
            const res = await dispatch(UploadMedia(formData));
            if (res.type === "common/UploadMedia/fulfilled") {
                setFilename(res.payload?.files[0]?.mediaName)
                setErrors((Clearerror) => ({
                    ...Clearerror,
                    fileName: "",
                }));
                return res.payload?.files[0];
            }
        }
    };

    const AddNewCAtegory = async (e) => {
        const data = {
            categoryImage: fileName,
            categoryType: categoryType,
            status: status,
        }
        const response = await dispatch(AddCategoryService({ data: data }));
        if (response.type === "category/AddCategoryService/fulfilled") {
            toast.success("Category Added  Successfully");
            navigate('/category');
        }
    }

    const [errors, setErrors] = useState({
        fileName: '',
        categoryType: '',
        status: '',
    });

    const validate = () => {
        let isValid = true;
        const newErrors = {
            fileName: '',
            categoryType: '',
            status: '',
        };
        if (!status.trim()) {
            newErrors.status = 'Please select status';
            isValid = false;
        }
        if (!categoryType.trim()) {
            newErrors.categoryType = 'Please enter a Category Type';
            isValid = false;
        }
        if (!fileName) {
            newErrors.fileName = 'Please upload file';
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

        switch (name) {
            case 'categoryType':
                setCategoryType(value);
                break;
            case 'status':
                setStatus(value);
                break;
            case 'fileName':
                setFilename(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            AddNewCAtegory();
        }
    };

    return (
        <>
            <div>
                <div className="w-100 h-100 ">
                    <Header />
                    <div className="d-flex position-relative">
                        <Sidebar />
                        <main className="MainSection">
                            {/* Inner Header Starts */}
                            <InnerHeader text="Tenant Category / New Category" arrow={true}>
                                <div></div>
                            </InnerHeader>
                            {/* Display App user data */}
                            <div className="conten-div">
                                <div className="single-admin-data">
                                    <div className="w-100">
                                        <div className="d-flex justify-content-between w-100 mb-4 ">
                                            <h2 className='BoxTitle'>About</h2>
                                            {/* Add button*/}
                                            <button onClick={handleSubmit} className="SiteBtn2 export-csv-btn btn">
                                                Add
                                            </button>
                                        </div>
                                        <form onSubmit={handleSubmit} encType='multipart/form-data'>
                                            <div className="">

                                                <div className="did-floating-label-content">
                                                    <input
                                                        className="did-floating-input"
                                                        name='categoryType'
                                                        type="text"
                                                        // onChange={(e) => { setCategoryType(e.target.value) }} 
                                                        onChange={handleChange}
                                                        placeholder=" " />
                                                    <label className="did-floating-label">Category Type</label>
                                                    {errors.categoryType && <div style={{ color: 'red' }}>{errors.categoryType}</div>}
                                                </div>
                                                <div className="did-floating-label-content">
                                                    <select
                                                        className="did-floating-select"
                                                        name='status'
                                                        // onChange={(e) => { setStatus(e.target.value) }}
                                                        onChange={handleChange}
                                                    >
                                                        <option selected value="Select">Select</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </select>
                                                    <label className="did-floating-label">Status</label>
                                                    {errors.status && <div style={{ color: 'red' }}>{errors.status}</div>}
                                                </div>
                                                <div>
                                                    {/* <input type="file" onChange={handleFileChange} name="uploadfile" id="img" /> */}
                                                    <label for="fileName" class="file-upload-label">
                                                        <span>Upload File +</span>
                                                        <input
                                                            type="file"
                                                            accept=".png, .pdf, .jpeg, .jpg"
                                                            onChange={handleFileChange}
                                                            id="fileName" name="fileName" />
                                                    </label>
                                                    <p>{fileName ? fileName : ''}</p>
                                                    {errors.fileName && <div className='new-happeninng-file-err' style={{ color: 'red' }}>{errors.fileName}</div>}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCategory;
