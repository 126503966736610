import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./features/AuthService";
import userReducer from "./features/UserService";
import tenantReducer from "./features/TenantService";
import eventReducer from "./features/EventFeedService";
import adminReducer from "./features/AdminService";
import noticationReducer from "./features/NotificationService";
import categoryReducer from "./features/CategoryService";
import comonReducer from "./features/CommonFileService";
import miscReducer from "./features/MiscService";
import wheelTenantReducer from "./features/WheelTenantService";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  auths: authReducer,//auth
  users: userReducer,// user
  wheeltenants:wheelTenantReducer,
  tenants: tenantReducer,// tenants
  event: eventReducer,// events
  admin: adminReducer,// admin
  notification: noticationReducer,// notification
  category: categoryReducer,// category
  common: comonReducer,// commonfile
  misc: miscReducer, // misc
});

export { rootPersistConfig, rootReducer };
