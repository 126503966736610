import React, { useState, useEffect, useRef, useMemo } from "react";
import Header from "../Header";
import InnerHeader from "../InnerHeader";
import Sidebar from "../Sidebar";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import AddAdmin from "./AddAdmin";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { GetAllAdmin } from "../../redux/features/AdminService";
import _ from "lodash";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Common/Loader";
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners'; // Import RingLoader
import { toast } from "react-toastify";
import { setActiveTab } from "../../redux/features/MiscService";

const columns = [
  {
    id: 1,
    name: "Name",
    selector: (row) => row.fullName,
    sortable: true,
    reorder: true,
    width: "388px",
  },
  {
    id: 2,
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    reorder: true,
    textAlign: "center",
    headerStyle: (selector, id) => {
      return { textAlign: "center" };
    },
    width: "388px",
  },
  {
    id: 3,
    name: "Last Active",
    selector: (row) =>
      moment
        .tz(row.updatedAt, "america/los_angeles")
        .format("MM/DD/yyyy @ HH:mm") + "h PT",
    sortable: true,
    reorder: true,
    width: "388px",
  },
];

const AdminUsersList = () => {
  const [isComponentVisible, setComponentVisibility] = useState(false);
  // const [activeTab, setActiveTab] = useState(0);
  const miscState = useSelector((state) => state.misc);
  const activeTab = useMemo(() => miscState?.adminUsers?.activeTab || 0, [miscState?.adminUsers?.activeTab]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState();
  const [csvData, setCsvData] = useState([]);
  const { allActiveAdmin } = useSelector((state) => state.admin);
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const [currentPageInactive, setCurrentPageInactive] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabs = ["Active", "Disabled"];
  const [isVisible, setIsVisible] = useState({
    active: false,
    inActive: false
  });
  const inputRef = useRef(null);
  document.title = "Admin Users - Brea downtown";
  const [filterNoMatch, setFilterNoMatch] = useState();
  const [isSearchText, setSearchText] = useState({
    active: "",
    inActive: ""
  });

  const obj = {
    fullName: "",
    email: "",
    status: "",
  };

  const [formData, setFormData] = useState({
    active: obj,
    inActive: obj
  });

  //Active Inactive Tabs handler
  const handleTabClick = async (index) => {
    setLoading(true);
    // setActiveTab(index);
    dispatch(setActiveTab({ tabOf: 'adminUsers', activeTab: index }));
    if (index === 0) {
      const res = await dispatch(GetAllAdmin({ search: isSearchText?.active, page: currentPageActive, status: "1", limit: 10, filter: formData?.active }));
      if (res.type === "admin/GetAllAdmin/fulfilled") {
        setLoading(false);
      }
    } else {
      const res = await dispatch(GetAllAdmin({ search: isSearchText?.inActive, page: currentPageInactive, status: "0", limit: 10, filter: formData?.inActive }));
      if (res.type === "admin/GetAllAdmin/fulfilled") {
        setLoading(false);
      }
    }
  };

  //search
  const handleSearch = async (searchText) => {
    try {
      if (activeTab === 0) {
        setSearchText({ active: searchText, inActive: isSearchText?.inActive })
        await dispatch(
          GetAllAdmin({
            page: currentPageActive,
            search: searchText,
            status: "1",
          })
        );
      } else {
        setSearchText({ active: isSearchText?.active, inActive: searchText })
        await dispatch(
          GetAllAdmin({
            page: currentPageInactive,
            search: searchText,
            status: "0",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (!activeTab) {
      setFormData({ ...formData, active: { ...formData?.active, [name]: value } })
    } else {
      setFormData({ ...formData, inActive: { ...formData?.inActive, [name]: value } })
    }
  };

  const clearBtn = async () => {
    if (activeTab === 0) {
      await dispatch(
        GetAllAdmin({ page: currentPageActive, status: "1", limit: 10 })
      );
    } else if (activeTab === 1) {
      await dispatch(
        GetAllAdmin({ page: currentPageInactive, status: "0", limit: 10 })
      );
    }
    setFormData({
      active: obj,
      inActive: obj
    });
  };

  const filterApply = async (e) => {
    e.preventDefault();

    if (activeTab === 0) {
      await dispatch(
        GetAllAdmin({
          page: currentPageActive,
          status: "1",
          limit: 10,
          filter: formData?.active,
        })
      );
    } else if (activeTab === 1) {
      await dispatch(
        GetAllAdmin({
          page: currentPageInactive,
          status: "0",
          limit: 10,
          filter: formData?.inActive,
        })
      );
    }
    setFilterNoMatch(true);
  };
  // console.log(formData, 'AAAAAAAAAAAAAAAAAAAAA  ')
  //API calling
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        if (activeTab === 0) {
          const res = await dispatch(GetAllAdmin({ page: currentPageActive, status: "1", limit: 10 }));
          if (res.type === "admin/GetAllAdmin/fulfilled") {
            setLoading(false);
          }
        } else {
          const res = await dispatch(GetAllAdmin({ page: currentPageInactive, status: "0", limit: 10 }));
          if (res.type === "admin/GetAllAdmin/fulfilled") {
            setLoading(false);
          }
        }
        // setLoading(true);  
      } catch {
        toast.error("failed to load app users");
        setLoading(false);
      }
    }
    fetchData();
  }, []); // Set loading to true when fetching data

  const headers = [
    { label: "Name", key: "fullName" },
    { label: "Email", key: "email" },
    { label: "Last Active", key: "updatedAt" },
  ];

  const getCsvData = async () => {
    if (activeTab === 0) {
      const AdminUserTableData = await _.map(
        allActiveAdmin?.data,
        (adminDetails, userIndex) => {
          return {
            fullName: adminDetails.fullName,
            email: adminDetails.email,
            updatedAt:
              moment
                .tz(adminDetails.updatedAt, "america/los_angeles")
                .format("MM/DD/yyyy @ HH:mm") + "h PT",
          };
        }
      );
      setCsvData(AdminUserTableData);
    } else {
      const AdminUserTableData = await _.map(
        allActiveAdmin?.data,
        (adminDetails, userIndex) => {
          return {
            fullName: adminDetails.fullName,
            email: adminDetails.email,
            updatedAt:
              moment
                .tz(adminDetails.updatedAt, "america/los_angeles")
                .format("MM/DD/yyyy @ HH:mm") + "h PT",
          };
        }
      );
      setCsvData(AdminUserTableData);
    }
  };

  const toggleVisibility = async () => {
    if (activeTab === 0) {
      await dispatch(GetAllAdmin({ status: "1" }));
    } else {
      await dispatch(GetAllAdmin({ status: "0" }))
    }
    clearBtn();
    const visible = {
      active: !activeTab ? !isVisible?.active : activeTab === 1 ? isVisible?.active : false,
      inActive: activeTab ? !isVisible?.inActive : activeTab === 0 ? isVisible?.inActive : false
    }
    setIsVisible(visible);
    setFilterNoMatch();
  };

  const handleRowClick = (row) => {
    navigate(`/admin-users/${row?._id}`);
  };

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

  return (
    <>
      {/* {loading && <Loader />} */}
      <div>
        <div className="w-100 h-100 ">
          <Header />
          <div className="d-flex position-relative">
            <Sidebar />
            <div className="MainSection">
              {/* Inner Header Starts */}
              <InnerHeader text={"Admin Users"} arrow={false}>
                <div className="sidebar-btn">
                  <div className="me-3">
                    <CSVLink
                      className="csvLink"
                      data={csvData}
                      headers={headers}
                      filename="Admin-Users-List"
                      onClick={(e) => getCsvData()}
                    >
                      <button className="SiteBtn export-csv-btn btn">Export{" "}</button>
                    </CSVLink>
                  </div>
                  {isComponentVisible ? (
                    <button className="SiteBtn export-csv-btn btn">Save</button>
                  ) : (
                    <button
                      onClick={() => {
                        navigate("/admin-users/add");
                      }}
                      className="SiteBtn2 export-csv-btn btn"
                    >
                      Add User
                    </button>
                  )}
                </div>
              </InnerHeader>

              {isComponentVisible ? (
                <AddAdmin />
              ) : (
                <div className="conten-div">
                  {/* search and filter */}
                  <div className="row justify-content-between">
                    <div className="col-md-4 col-lg-3 col-md-4 col-8 mb-3 mb-lg-0">
                      <div className="searchbarmain ">
                        <input
                          type="text"
                          className="inputsearchbarmain"
                          name=""
                          id="search"
                          placeholder="Search"
                          value={!activeTab ? isSearchText?.active : isSearchText?.inActive}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <button className="searchbtn" >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          // onClick={handleSearch}
                          >
                            <g clipPath="url(#clip0_1479_513)">
                              <path
                                d="M18.0831 16.3333H17.1614L16.8348 16.0183C18.2348 14.385 18.9581 12.1567 18.5614 9.78833C18.0131 6.545 15.3064 3.955 12.0398 3.55833C7.10475 2.95166 2.95142 7.105 3.55809 12.04C3.95475 15.3067 6.54475 18.0133 9.78809 18.5617C12.1564 18.9583 14.3848 18.235 16.0181 16.835L16.3331 17.1617V18.0833L21.2914 23.0417C21.7698 23.52 22.5514 23.52 23.0298 23.0417C23.5081 22.5633 23.5081 21.7817 23.0298 21.3033L18.0831 16.3333ZM11.0831 16.3333C8.17809 16.3333 5.83309 13.9883 5.83309 11.0833C5.83309 8.17833 8.17809 5.83333 11.0831 5.83333C13.9881 5.83333 16.3331 8.17833 16.3331 11.0833C16.3331 13.9883 13.9881 16.3333 11.0831 16.3333Z"
                                fill="#42474E"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1479_513">
                                <rect width="28" height="28" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                    </div>
                    {(activeTab === 0 && isVisible?.active) || (activeTab === 1 && isVisible?.inActive) ? (
                      <>
                        <div className="col-lg-7 col-md-5 col-12 order-3 order-md-2">
                          <div className="col-md-12">
                            <div className="row AdminUserFilterBox">

                              <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="fullName"
                                    id="fullName"
                                    className="form-control"
                                    placeholder="Search by Name"
                                    value={!activeTab ? formData?.active?.fullName : formData?.inActive?.fullName}
                                    onChange={(e) => {
                                      handleInputChange(e);
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                <div className="input-group">
                                  <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    placeholder="Search by Email"
                                    value={!activeTab ? formData?.active?.email : formData?.inActive?.email}
                                    onChange={(e) => {
                                      handleInputChange(e);
                                    }}
                                  />
                                </div>
                              </div>

                              {/* <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                <select name="status" value={formData.status} onChange={(e) => handleInputChange(e)} className="form-select from-control select2-custom text-left-txt" aria-label="Default select example">
                                  <option selected>Search by Status </option>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                <DatePicker
                                  selected={dateNtime}
                                  onChange={(date) => setDateNtime(date)}
                                  showTimeSelect
                                  name="datetime"
                                  id="datetime"
                                  placeholderText="Search By Last Active"
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                  className="from-control select2-custom text-left-txt datetimepickerreact"
                                />
                              </div> */}

                            </div>
                          </div>
                          <div className="col-md-12 text-end">
                            <button
                              className="SiteBtn main-filter-btn btn"
                              onClick={clearBtn}
                            >
                              Clear
                            </button>
                            <button
                              className="SiteBtn main-filter-btn btn ms-2"
                              onClick={(e) => {
                                filterApply(e);
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </>
                    ) : <></>}
                    <div className="col-lg-2 col-md-3 col-4 order-2 order-md-3 text-end">
                      <button
                        className="SiteBtn main-filter-btn btn"
                        onClick={toggleVisibility}
                      >
                        Filter
                      </button>
                    </div>
                  </div>

                  <div className="col-md-3 d-flex mb-5 mt-4 min-btn-admin">
                    {tabs.map((tab, index) => {
                      return (
                        <div
                          key={`${index}-admin-tab`}
                          className="TabButton"
                          onClick={() => handleTabClick(index)}
                          style={{
                            borderBottom:
                              activeTab === index
                                ? "2px solid #00639B"
                                : "none",
                            color: activeTab === index ? "#1A1C1E" : "#42474E",
                          }}
                        >
                          {tab}
                        </div>
                      );
                    })}
                  </div>

                  {/* <Tenant data Table /> */}
                  {activeTab === 0 && (
                    <div>
                      <div className="TableData">
                        {loading ? (
                          <div className="sweet-loading m-auto">
                            <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                          </div>
                        ) : (
                          <DataTable
                            title=""
                            columns={columns}
                            defaultSortFieldId={1}
                            pagination
                            data={allActiveAdmin?.data}
                            onRowClicked={handleRowClick}
                            highlightOnHover
                            persistTableHead
                            // sortIcon={ArrowDropDownSVGIcon}
                            noDataComponent={
                              <div className="header-text-container">
                                {filterText ?
                                  <p className="header-text no-data-text">There are no records to display </p>
                                  : (filterNoMatch ?
                                    <p className="header-text no-data-text"> No results matching your filter. Try removing the Filter </p>
                                    : <p className="header-text">There are no records to display</p>)
                                }
                              </div>
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {activeTab === 1 && (
                    <div>
                      <div className="TableData">
                        {loading ? (
                          <div className="sweet-loading m-auto">
                            <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                          </div>
                        ) : (
                          <DataTable
                            title=""
                            columns={columns}
                            defaultSortFieldId={1}
                            pagination
                            data={allActiveAdmin?.data}
                            onRowClicked={handleRowClick}
                            highlightOnHover
                            persistTableHead
                            noDataComponent={
                              <div className="header-text-container">
                                {filterText ?
                                  <p className="header-text no-data-text">There are no records to display </p>
                                  : (filterNoMatch ?
                                    <p className="header-text no-data-text"> No results matching your filter. Try removing the Filter </p>
                                    : <p className="header-text no-data-text">There are no records to display</p>)
                                }
                              </div>
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUsersList;
