import React from "react";

const Button = ({ className, text, onClick, type }) => {
  return (
    <div>
      <button className={`${className}`} onClick={onClick} type={type ? type : 'submit'}>
        {text}
      </button>
    </div>
  );
};

export default Button;
