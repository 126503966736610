import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

// Get All Events Service
export const GetAllEvents = createAsyncThunk(
    "event/GetAllEvents",
    async (args, { rejectWithValue }) => {
        try {
            const { page, limit, search, eventType, filter } = args;
            const resp = await axios.post(`${url}/admin/get_all_event`, {
                "eventType": eventType,
                "search": search,
                "filter": filter,
            }, {
                headers: getAuthenticationHeader(),
            });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

// Get All Events Service
export const GetSingleEventService = createAsyncThunk(
    "event/GetSingleEventService",
    async (args, { rejectWithValue }) => {
        try {
            const { id } = args;
            const resp = await axios.get(`${url}/admin/get_single_event/${id}`, {
                headers: getAuthenticationHeader(),
            });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const EditEventService = createAsyncThunk(
    "event/EditEventService",
    async (args, { rejectWithValue }) => {
        try {
            const { id, data } = args;
            const resp = await axios.post(`${url}/admin/update_event/${id}`, data,
                {
                    headers: getAuthenticationHeader(),
                });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const AddEventService = createAsyncThunk(
    "event/AddEventService",
    async (args, { rejectWithValue }) => {
        try {
            const { id, data } = args;
            const resp = await axios.post(`${url}/admin/create_event`, data,
                {
                    headers: getAuthenticationHeader(),
                });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const DeleteEventService = createAsyncThunk(
    "event/DeleteEventService",
    async (args, { rejectWithValue }) => {
        try {
            const { id } = args;
            const resp = await axios.delete(`${url}/admin/delete_event/${id}`,
                {
                    headers: getAuthenticationHeader(),
                });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const RemoveSingleEvent = createAsyncThunk(
    "event/RemoveSingleEvent",
    async (_, { rejectWithValue }) => {
        try {
            return 'remove';
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);


// Event Slice
const EventSlice = createSlice({
    name: "event",
    initialState: {
        error: false,
        loading: false,
        allBreaEvents: [],
        allTenantEvents: [],
        allDailyHappeningEvents: [],
        singleEvent: null,
        editEvent: null,
        addEvent: [],
    },

    // reducers
    reducers: {
        setEventPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },

    // extra reducers and addcases
    extraReducers: (builder) => {
        builder

            // get all Bra Events
            .addCase(GetAllEvents.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetAllEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.allBreaEvents = action.payload;
            })
            .addCase(GetAllEvents.rejected, (state) => {
                state.loading = false;
            })

            // get Single Event
            .addCase(GetSingleEventService.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetSingleEventService.fulfilled, (state, action) => {
                state.loading = false;
                state.singleEvent = action?.payload?.event;
            })
            .addCase(GetSingleEventService.rejected, (state) => {
                state.loading = false;
            })

            //Edit Notification
            .addCase(EditEventService.pending, (state) => {
                state.loading = true;
            })
            .addCase(EditEventService.fulfilled, (state, action) => {
                state.loading = false;
                state.singleEvent = action.payload.event;
            })
            .addCase(EditEventService.rejected, (state) => {
                state.loading = false;
            })

            //Add Notification
            .addCase(AddEventService.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddEventService.fulfilled, (state, action) => {
                state.loading = false;
                state.addEvent = action.payload.data;
            })
            .addCase(AddEventService.rejected, (state) => {
                state.loading = false;
            })
            .addCase(RemoveSingleEvent.fulfilled, (state) => {
                state.singleEvent = null;
            })
            
    },
});

export const { setEventPage } = EventSlice.actions;

export default EventSlice.reducer;