import axios from "axios";
import { getHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

// Login Service
export const CheckEmailService = createAsyncThunk(
  "auth/CheckEmailService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/admin/check_admin_email`, args, {
        headers: getHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const SignInService = createAsyncThunk(
  "auth/SignInService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/admin/login`, args, {
        headers: getHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Reset Password Service 
export const CheckTokenExpireationService = createAsyncThunk(
  "auth/CheckTokenExpireationService",
  async (args, { rejectWithValue }) => {
    try {
      const { token } = args;
      const resp = await axios.post(
        `${url}/admin/check_token/${token}`,
        {
          headers: getHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Reset Password Link Service
export const ResetPasswordLinkService = createAsyncThunk(
  "auth/ResetPasswordLinkService",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(
        `${url}/admin/sendLink_for_passwordUpdate`,
        args,
        {
          headers: getHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Reset Password Service 
export const ResetPasswordService = createAsyncThunk(
  "auth/ResetPasswordService",
  async (args, { rejectWithValue }) => {
    try {
      const { data, token } = args;
      const resp = await axios.post(
        `${url}/admin/reset_password/${token}`,
        data,
        {
          headers: getHeader(),
        }
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    error: false,
    loading: false,
    aboutUsData: null,
  },
  reducers: {
    setAuthPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SignInService.pending, (state) => {
        state.loading = true;
      })
      .addCase(SignInService.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(SignInService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(ResetPasswordLinkService.pending, (state) => {
        state.loading = true;
      })
      .addCase(ResetPasswordLinkService.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(ResetPasswordLinkService.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setAuthPage } = AuthSlice.actions;

export default AuthSlice.reducer;
