import React, { useState, useEffect } from "react";
import Header from "../Header";
import InnerHeader from "../InnerHeader";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCategoryService } from "../../redux/features/CategoryService";
import { useNavigate } from "react-router-dom";
import AddCategory from "./AddCategory";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners'; // Import RingLoader
import { toast } from "react-toastify";

const columns = [
  {
    id: 1,
    name: "Name",
    selector: (row) => row.categoryType,
    sortable: true,
    reorder: true,
    width: "250px",
  },
  {
    id: 2,
    name: "Image",
    selector: (row) => (
      <img
        src={process.env.REACT_APP_BACKEND_URL + "uploads/" + row.categoryImage}
        className="image-row-datatable"
        alt="!Oops image is not available"
      />
    ),
    sortable: true,
    reorder: true,
  },
  {
    id: 3,
    name: "Created At",
    selector: (row) =>
      moment
        .tz(row.createdAt, "america/los_angeles")
        .format("MM/DD/yyyy @ HH:mm") + "h PT",
    sortable: true,
    reorder: true,
  },
];

const CategoryList = () => {
  document.title = "Brea downtown | Category";
  const [isComponentVisible, setComponentVisibility] = useState(false);
  const { allCategory } = useSelector((state) => state.category);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  //API calling
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await dispatch(GetAllCategoryService({ status: "1", limit: 10 }));
        if (res.type === "category/GetAllCategoryService/fulfilled") {
          setLoading(false);
        }
        // setLoading(true);
      } catch {
        toast.error("failed to load app users");
        setLoading(false);
      }
    }
    fetchData();
  }, []); // Set loading to true when fetching data

  const handleRowClick = (row) => {
    navigate(`/category/${row?._id}`);
  };

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


  return (
    <>
      <div>
        <div className="w-100 h-100 ">
          <Header />
          <div className="d-flex position-relative">
            <Sidebar />
            <div className="MainSection">
              {/* Inner Header Starts */}
              <InnerHeader text={"Tenant Category"} arrow={false}>
                <div className="sidebar-btn">
                  {isComponentVisible ? (
                    <button className="SiteBtn export-csv-btn btn">Save</button>
                  ) : (
                    <button
                      onClick={() => {
                        navigate("/category/add");
                      }}
                      className="SiteBtn2 export-csv-btn btn"
                    >
                      Add Category
                    </button>
                  )}
                </div>
              </InnerHeader>

              {isComponentVisible ? (
                <AddCategory />
              ) : (
                <div className="conten-div">
                  <div>
                    <div className="TableData">
                      {loading ? (
                        <div className="sweet-loading m-auto">
                          <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                        </div>
                      ) : (
                        <DataTable
                          title=""
                          columns={columns}
                          defaultSortFieldId={1}
                          pagination
                          data={allCategory?.data?.records}
                          onRowClicked={handleRowClick}
                          highlightOnHover
                          noDataComponent="There are no records to display"
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryList;
