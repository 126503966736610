import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import Cookies from "js-cookie";

const url = process.env.REACT_APP_API_URL;

// Get All Users Service
export const GetAllAppUsers = createAsyncThunk(
  "users/GetAllAppUsers",
  async (args, { rejectWithValue }) => {
    try {
      const { page, limit, search, filter, status } = args;
      const resp = await axios.post(`${url}/admin/get_all_users`, {
        // "page": page ? page : 1,
        // "limit": limit ? limit : 10,
        "status": status,
        "search": search,
        "filter" : filter,
      }, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Get Single User Service
export const GetSingleUser = createAsyncThunk(
  "users/GetSingleUser",
  async (args, { rejectWithValue }) => {
    try {
      const { id } = args;
      const resp = await axios.get(`${url}/admin/get_single_users/${id}/`,
        {
          headers: getAuthenticationHeader(),
        });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Update User Service
export const UpdateAppUserDetails = createAsyncThunk(
  "users/UpdateAppUserDetails",
  async (args, { rejectWithValue }) => {
    try {
      const { id, data } = args;
      const resp = await axios.patch(`${url}/admin/update_user/${id}/`, data,
        {
          headers: getAuthenticationHeader(),
        });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);


// User Slice
const UserSlice = createSlice({
  name: "users",
  initialState: {
    error: false,
    loading: false,
    allActiveUsers: [],
    allInactiveUsers: [],
    SingleUser: [],
    EditAppUSer: [],
  },

  // reducers
  reducers: {
    setUserPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },

  // extra reducers and addcases
  extraReducers: (builder) => {
    builder

      //get all Active user
      .addCase(GetAllAppUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllAppUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.allActiveUsers = action.payload;
      })
      .addCase(GetAllAppUsers.rejected, (state) => {
        state.loading = false;
      })

      //get single user
      .addCase(GetSingleUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSingleUser.fulfilled, (state, action) => {
        state.loading = false;
        state.SingleUser = action.payload;
      })
      .addCase(GetSingleUser.rejected, (state) => {
        state.loading = false;
      })

      //update user details
      .addCase(UpdateAppUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateAppUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.EditAppUSer = action.payload;
      })
      .addCase(UpdateAppUserDetails.rejected, (state) => {
        state.loading = false;
      })
  },
});

export const { setUserPage } = UserSlice.actions;

export default UserSlice.reducer;