import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;



export const CreateWheelTenantService = createAsyncThunk(
  "wheelTenant/CreateWheelTenant",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = args;
      const resp = await axios.post(`${url}/admin/add_wheel_tenant`, data, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UpdateWheelTenantService = createAsyncThunk(
  "wheelTenant/UpdateWheelTenant",
  async (args, { rejectWithValue }) => {
    try {
      const { id, data } = args;
      const resp = await axios.patch(`${url}/admin/update_wheel_tenant/${id}`, data, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeleteWheelTenantService = createAsyncThunk(
  "wheelTenant/DeleteWheelTenant",
  async (id, { rejectWithValue }) => {
    try {
      const resp = await axios.delete(`${url}/admin/delete_wheel_tenant/${id}`, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UpdateWheelTenantPositionsService = createAsyncThunk(
  'wheelTenant/updatePositions',
  async (positions, thunkAPI) => {
    try {

      const resp = await axios.put(`${url}/admin/update_positions`, {positions } ,{
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetAllWheelTenantsService = createAsyncThunk(
  "wheelTenant/GetAllWheelTenants",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/admin/get_all_wheel_tenant`, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetSingleWheelTenantService = createAsyncThunk(
  "wheelTenant/GetSingleWheelTenant",
  async (id, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/admin/get_single_wheel_tenant/${id}`, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Slice Definition
const wheelTenantSlice = createSlice({
  name: "wheelTenant",
  initialState: {
    loading: false,
    error: false,
    allWheelTenants: [],
    singleWheelTenant: null,
    createWheelTenant: null,
    updateWheelTenant: null,
    deleteWheelTenant: null,
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(CreateWheelTenantService.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(CreateWheelTenantService.fulfilled, (state, action) => {
        state.loading = false;
        state.createWheelTenant = action.payload;
      })
      .addCase(CreateWheelTenantService.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(UpdateWheelTenantService.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(UpdateWheelTenantService.fulfilled, (state, action) => {
        state.loading = false;
        state.updateWheelTenant = action.payload;
      })
      .addCase(UpdateWheelTenantService.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(DeleteWheelTenantService.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(DeleteWheelTenantService.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteWheelTenant = action.payload;
      })
      .addCase(DeleteWheelTenantService.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(GetAllWheelTenantsService.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetAllWheelTenantsService.fulfilled, (state, action) => {
        state.loading = false;
        state.allWheelTenants = action.payload;
      })
      .addCase(GetAllWheelTenantsService.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(GetSingleWheelTenantService.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(GetSingleWheelTenantService.fulfilled, (state, action) => {
        state.loading = false;
        state.singleWheelTenant = action.payload;
      })
      .addCase(GetSingleWheelTenantService.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setUserPage } = wheelTenantSlice.actions;

export default wheelTenantSlice.reducer;
