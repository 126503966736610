import React, { useEffect, useState } from "react";
import Header from "../Header";
import InnerHeader from "../InnerHeader";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  EditEventService,
  GetSingleEventService,
} from "../../redux/features/EventFeedService";
import { toast } from "react-toastify";
import { UploadMedia } from "../../redux/features/CommonFileService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { css } from "@emotion/react";
import { RingLoader } from "react-spinners"; // Importing RingLoader
import Loader from "../Common/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const isValidUrl = (url) =>  {
  try {
    // Regular expression for a URL
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3})|" + // OR IP (v4) address
        "localhost|" + // OR localhost
        "([a-zA-Z\\d-]+\\.([a-zA-Z]{2,}))\\.?)" + // subdomain
        "(:\\d+)?(\\/[-a-zA-Z\\d%@_.~+&:]*)*" + // port and path
        "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
        "(#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    return pattern.test(url);
  } catch (error) {
    return false;
  }
}

const SingleDailyHappening = () => {
  document.title = "Daily Happening - Brea downtown";
  const dispatch = useDispatch();
  const { singleEvent } = useSelector((state) => state.event);
  const params = useParams();
  const id = params.id;

  const [isComponentVisible, setComponentVisibility] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        setIsLoading(true);
        await dispatch(GetSingleEventService({ id }));
        setIsLoading(false);
      }
      fetchData(); 
    }
  }, [dispatch, id]);

  // useEffect(() => {
  //   if (singleEvent) {
  //     formik.setValues({
  //       title: singleEvent?.title || "",
  //       description: singleEvent?.description || "",
  //       link: singleEvent?.link || "",
  //       eventImage:
  //         process.env.REACT_APP_BACKEND_URL +
  //         "uploads/" +
  //         singleEvent?.eventImage || "",
  //     });
  //     setIsLoading(false);
  //   }
  // }, [singleEvent, fileName]);
  const formik = useFormik({
    initialValues: {
      title: singleEvent?.title || "",
      description: singleEvent?.description || "",
      link: singleEvent?.link || "",
      eventImage: id ? process.env.REACT_APP_BACKEND_URL +
        "uploads/" +
        singleEvent?.eventImage : "" || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter a title"),
      description: Yup.string().required("Please enter a description"),
      link: Yup.string()
        .test("url-validation", "Please enter a valid URL", (value) => {
          if (!isValidUrl(value)) {
            return false; // URL is not valid
          }
          return true;
        })
        .required("Please enter a link"),

    }),
    onSubmit: async (values) => {
      const { title, description, link } = values;
      const data = {
        eventType: "3",
        title,
        description,
        link,
        eventImage: fileName ? fileName : singleEvent?.eventImage,
      };
      const response = await dispatch(EditEventService({ id, data }));
      if (response.type === "event/EditEventService/fulfilled") {
        toast.success("Daily Happening details updated successfully", {
          autoClose: 3000,
        });
        setComponentVisibility(false);
        dispatch(GetSingleEventService({ id }));
      } else {
        toast.error(response?.payload?.response?.data?.message, {
          autoClose: 3000,
        });
      }
    },
  });

  const handleFileChange = async (file) => {
    if (file?.currentTarget?.files?.length) {
      console.log(file);
      const fileSize = file.currentTarget.files[0].size;
      const maxSize = 10 * 1024 * 1024;
      if (fileSize > maxSize) {
        toast.error("File size exceeds the maximum allowed limit (1 MB)");
        return;
      }
      setLoading(true);
      const formData = new FormData();
      formData.append("files", file.currentTarget.files[0]);
      const res = await dispatch(UploadMedia(formData));
      if (res.type === "common/UploadMedia/fulfilled") {
        setLoading(false);
        setFileName(res.payload?.files[0]?.mediaName);
        toast.success(res?.payload?.message);
        return res.payload?.files[0];
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="w-100 h-100 ">
        <Header />
        <div className="d-flex position-relative">
          <Sidebar />
          <div className="MainSection">
            <InnerHeader text={`Events / Daily Happenings`} arrow={true}>
              <div></div>
            </InnerHeader>
            <div className="conten-div">
              <div className="single-admin-data">
                <div className="d-flex justify-content-between w-100">
                  <h2 className="mb-4 BoxTitle">Daily Happenings</h2>
                  {isComponentVisible ? (
                    <button
                      type="submit"
                      className="SiteBtn export-csv-btn btn"
                      onClick={formik.handleSubmit}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => setComponentVisibility(true)}
                      className="SiteBtn2 export-csv-btn btn"
                    >
                      Edit
                    </button>
                  )}
                </div>
                {isLoading ? (
                  <div className="loader-container m-auto">
                    <RingLoader
                      css={override}
                      size={50}
                      color={"#00639B"}
                      loading={isLoading}
                    />
                  </div>
                ) : (
                  <>
                    {isComponentVisible ? (
                      <form
                        encType="multipart/form-data"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="">
                          <div className="did-floating-label-content">
                            <input
                              className="did-floating-input"
                              type="text"
                              name="title"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.title}
                              placeholder=" "
                            />
                            <label className="did-floating-label">Title</label>
                            {formik.touched.title && formik.errors.title && (
                              <div
                                className="error-message error-red"
                                style={{ color: "red" }}
                              >
                                {formik.errors.title}
                              </div>
                            )}
                          </div>
                          <div className="did-floating-label-content">
                            <textarea
                              className="did-floating-input txtarea-description"
                              type="text"
                              name="description"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.description}
                              placeholder=" "
                            ></textarea>
                            <label className="did-floating-label">
                              Description
                            </label>
                            {formik.touched.description &&
                              formik.errors.description && (
                                <div
                                  className="error-message error-red"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.description}
                                </div>
                              )}
                          </div>
                          <div className="did-floating-label-content">
                            <input
                              className="did-floating-input"
                              type="text"
                              name="link"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.link}
                              placeholder=" "
                            />
                            <label className="did-floating-label">Link</label>
                            {formik.touched.link && formik.errors.link && (
                              <div
                                className="error-message error-red"
                                style={{ color: "red" }}
                              >
                                {formik.errors.link}
                              </div>
                            )}
                          </div>
                          <div className="did-floating-label-content">
                            <label
                              htmlFor="file-upload"
                              className="file-upload-label m-0"
                            >
                              <span>
                                Upload File
                                <svg
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="ms-2 my-1"
                                >
                                  <path
                                    d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
                                    fill="#42474E"
                                  />
                                </svg>
                              </span>
                              <input
                                accept=".png, .pdf"
                                type="file"
                                onChange={handleFileChange}
                                id="file-upload"
                                name="file-upload"
                              />
                            </label>

                            <a
                              className="p-3"
                              href={
                                fileName
                                  ? `${process.env.REACT_APP_BACKEND_URL}uploads/${fileName}`
                                  : `${process.env.REACT_APP_BACKEND_URL}uploads/${singleEvent?.eventImage}`
                              }
                            ></a>
                            {/* <img
                              src={
                                fileName
                                  ? `${process.env.REACT_APP_BACKEND_URL}uploads/${fileName}`
                                  : `${process.env.REACT_APP_BACKEND_URL}uploads/${singleEvent?.eventImage}`
                              }
                              className="single-category-img"
                              // alt="!Oops image is not available"
                            /> */}
                            {/* {fileName &&
                            fileName.toLowerCase().endsWith(".pdf") ? (
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}uploads/${fileName}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                PDF Link
                              </a>
                            ) : (
                              <img
                                src={
                                  fileName
                                    ? `${process.env.REACT_APP_BACKEND_URL}uploads/${fileName}`
                                    : `${process.env.REACT_APP_BACKEND_URL}uploads/${singleEvent?.eventImage}`
                                }
                                alt="Uploaded File"
                                className="single-category-img"
                              />
                            )} */}
                            {fileName ? (
                              fileName.toLowerCase().endsWith(".pdf") ? (
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_URL}uploads/${fileName}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  PDF Link
                                </a>
                              ) : (
                                <img
                                  src={`${process.env.REACT_APP_BACKEND_URL}uploads/${fileName}`}
                                  alt="Uploaded File"
                                  className="single-category-img"
                                />
                              )
                            ) : singleEvent?.eventImage
                              .toLowerCase()
                              .endsWith(".pdf") ? (
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}uploads/${singleEvent?.eventImage}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                PDF Link
                              </a>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_BACKEND_URL}uploads/${singleEvent?.eventImage}`}
                                alt="Uploaded File"
                                className="single-category-img"
                              />
                            )}
                          </div>
                        </div>
                      </form>
                    ) : (
                      <div className="ms-3">
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Title</div>
                          <div className="single-user-p-desc">
                            {singleEvent?.title}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Description </div>
                          <div className="single-user-p-desc">
                            {singleEvent?.description}
                          </div>
                        </div>
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Link</div>
                          <div className="single-user-p-desc">
                            <a href={singleEvent?.link}>{singleEvent?.link}</a>
                          </div>
                        </div>
                        <div className="single-user-notification">
                          <div className="single-user-p-main">Image</div>
                          <div className="single-user-p-desc mb-1">
                            {/* <img
                              src={`${process.env.REACT_APP_BACKEND_URL}uploads/${singleEvent?.eventImage}`}
                              className="single-category-img"
                              // alt="!Oops image is not available"
                            /> */}
                            {singleEvent?.eventImage &&
                              singleEvent?.eventImage
                                .toLowerCase()
                                .endsWith(".pdf") ? (
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}uploads/${singleEvent?.eventImage}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                PDF Link
                              </a>
                            ) : (
                              <img
                                src={
                                  fileName
                                    ? `${process.env.REACT_APP_BACKEND_URL}uploads/${fileName}`
                                    : `${process.env.REACT_APP_BACKEND_URL}uploads/${singleEvent?.eventImage}`
                                }
                                alt="Uploaded File"
                                className="single-category-img"
                              />
                            )}
                          </div>
                          {singleEvent?.eventImage
                            ? singleEvent?.eventImage
                            : ""}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleDailyHappening;
