import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

// Add Category details
export const AddCategoryService = createAsyncThunk(
    "category/AddCategoryService",
    async (args, { rejectWithValue }) => {
        try {
            const { data } = args;
            const resp = await axios.post(`${url}/admin/create_tenantCategory`, data, {
                headers: getAuthenticationHeader(),
            });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

// All Category details
export const GetAllCategoryService = createAsyncThunk(
    "category/GetAllCategoryService",
    async (args, { rejectWithValue }) => {
        try {
            const { id, page, limit } = args;
            const resp = await axios.post(`${url}/admin/get_all_tenantCategory`, {
                "categoryType": "",
                "status": "",
            }, {
                headers: getAuthenticationHeader(),
            });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

// Update User Service
export const UpdateCategoryService = createAsyncThunk(
    "category/UpdateCategoryService",
    async (args, { rejectWithValue }) => {
        try {
            const { id, data } = args;
            const resp = await axios.post(`${url}/admin/update_tenantCategory/${id}/`, data,
                {
                    headers: getAuthenticationHeader(),
                });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

// Get Single User Service
export const GetSingleCategoryService = createAsyncThunk(
    "category/GetSingleCategoryService",
    async (args, { rejectWithValue }) => {
        try {
            const { id } = args;
            const resp = await axios.get(`${url}/admin/get_single_tenantCategory/${id}/`,
                {
                    headers: getAuthenticationHeader(),
                });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const DeleteCategory = createAsyncThunk(
    "category/DeleteCategory",
    async (args, { rejectWithValue }) => {
        try {
            const { id } = args;
            const resp = await axios.delete(`${url}/admin/delete_tenantCategory/${id}/`,
                {
                    headers: getAuthenticationHeader(),
                });
            return resp.data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);


// Category Slice
const CategorySlice = createSlice({
    name: "category",
    initialState: {
        error: false,
        loading: false,
        allCategory: [],
        createCategory: [],
        updateCategory: [],
        singleCategory: [],
    },

    // reducers
    reducers: {
        setCategoryPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },

    // extra reducers and addcases
    extraReducers: (builder) => {
        builder

            // get all category
            .addCase(GetAllCategoryService.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetAllCategoryService.fulfilled, (state, action) => {
                state.loading = false;
                state.allCategory = action.payload;
            })
            .addCase(GetAllCategoryService.rejected, (state) => {
                state.loading = false;
            })

            //get single category
            .addCase(GetSingleCategoryService.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetSingleCategoryService.fulfilled, (state, action) => {
                state.loading = false;
                state.singleCategory = action.payload;
            })
            .addCase(GetSingleCategoryService.rejected, (state) => {
                state.loading = false;
            })

            // create category
            .addCase(AddCategoryService.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddCategoryService.fulfilled, (state, action) => {
                state.loading = false;
                state.createCategory = action.payload;
            })
            .addCase(AddCategoryService.rejected, (state) => {
                state.loading = false;
            })

            //update category
            .addCase(UpdateCategoryService.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateCategoryService.fulfilled, (state, action) => {
                state.loading = false;
                state.singleCategory = action.payload;
            })
            .addCase(UpdateCategoryService.rejected, (state) => {
                state.loading = false;
            })
    },
});

export const { setCategoryPage } = CategorySlice.actions;

export default CategorySlice.reducer;