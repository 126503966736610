import React from "react";
import SignIn from "../components/Authentication/SignIn";
import ResetPassword from "../components/Authentication/ResetPassword";
import ResetPasswordToken from "../components/Authentication/ResetPasswordToken";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import PageNotFound from "../components/Common/PageNotFound";
import AppUserList from "../components/AppUsers/AppUserList";
import TenantsList from "../components/Tenants/TenantsList";
import EventsList from "../components/EventFeed/EventsList";
import AppSingleUSer from "../components/AppUsers/AppSingleUser";
import AddTenant from "../components/Tenants/AddTenant";
import SingleTenant from "../components/Tenants/SingleTenant";
import SystemSettings from "../components/SystemSettings";
import AdminUsersList from "../components/AdminUsers/AdminUsersList";
import AddAdmin from "../components/AdminUsers/AddAdmin";
import NotificationList from "../components/Notifications/NotificationList";
import SingleAdmin from "../components/AdminUsers/SingleAdmin";
import SingleEvent from "../components/EventFeed/SingleEvent";
import AddNotification from "../components/Notifications/AddNotification";
import SingleNotification from "../components/Notifications/SingleNotification";
import AddEvent from "../components/EventFeed/AddEvent";
import PrivateWrapper from "../components/DashboradWrapper/PrivateWrapper";
import PublicWrapper from "../components/DashboradWrapper/PublicWrapper";
import AddSingleDailyHappening from "../components/EventFeed/AddSingleDailyHappening";
import SingleDailyHappening from "../components/EventFeed/SingleDailyHappening";
import CategoryList from "../components/Category/CategoryList";
import AddCategory from "../components/Category/AddCategory";
import SingleCategory from "../components/Category/SingleCategory";
import AddWheel from "../components/Wheel/AddWheel";

const isLogin = Cookies.get('auth-token');

const routes = [
  {
    path: "/",
    element: <PublicWrapper />,
    children: [
      {
        exact: true,
        path: "/", // Default landing page, can be /sign-in
        element: <Navigate to="/sign-in" />,
      },
      {
        path: "/sign-in", // Sign-in page
        element: <SignIn />,
      },
      {
        path: "/reset-password", // Reset password
        element: <ResetPassword />,
      },
      {
        path: "/set-password/:id", // Set new password
        element: <ResetPasswordToken />,
      },
    ],
  },
  {
    path: "/",
    element: <PrivateWrapper />,
    children: [
      {
        path: "/app-users", // App users
        element: isLogin ? <AppUserList /> : <Navigate to="/sign-in" />,
      },
      {
        path: "/app-users/:id", // Get app user
        element: <AppSingleUSer />,
      },
      {
        path: "/tenants", // Tenant list
        element: <TenantsList />,
      },
      {
        path: "/wheel", // Wheel list
        element: <AddWheel />,
      },
      {
        path: "/tenants/:id", // Get single tenant
        element: <SingleTenant />,
      },
      {
        path: "/tenants/add", // Create tenant
        element: <AddTenant />,
      },
      {
        exact: true,
        path: "/events", // Events
        element: <EventsList />,
      },
      {
        exact: true,
        path: "/events/:id", // Events
        element: <SingleEvent />,
      },
      {
        path: "/events/add", // Events
        element: <AddEvent />,
      },
      {
        exact: true,
        path: "/daily-happening/:id", // Events
        element: <SingleDailyHappening />,
      },
      {
        path: "/daily-happening/add", // Events
        element: <AddSingleDailyHappening />,
      },
      {
        exact: true,
        path: "/notifications", // Notification
        element: <NotificationList />,
      },
      {
        exact: true,
        path: "/notifications/add", // Add Notification
        element: <AddNotification />,
      },
      {
        exact: true,
        path: "/notifications/:id", // Edit Notification
        element: <SingleNotification />,
      },
      {
        path: "/admin-users", // Admin Users
        element: <AdminUsersList />,
      },
      {
        path: "/admin-users/add", // Admin add
        element: <AddAdmin />,
      },
      {
        path: "/admin-users/:id", // Get single admin
        element: <SingleAdmin />,
      },
      {
        path: "/category", // Category list
        element: <CategoryList />,
      },
      {
        path: "/category/add", // Category add
        element: <AddCategory />,
      },
      {
        path: "/category/:id", // Single Category
        element: <SingleCategory />,
      },
      {
        path: "/system-settings", // System Settings
        element: <SystemSettings />,
      },
      {
        path: "*", // 404 not found
        element: <PageNotFound />,
      },
    ],
  },
];

export default routes;
