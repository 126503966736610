import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import InnerHeader from "../InnerHeader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AddEventService } from "../../redux/features/EventFeedService";
import { UploadMedia } from "../../redux/features/CommonFileService";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import validator from 'validator';

const AddEvent = () => {
  const [eventType, setEventType] = useState("");
  const [eventName, setEventName] = useState("");
  const [date, setDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [venue, setVenue] = useState("");
  const [description, setDescription] = useState("");
  const [flyerLink, setFlyerLink] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [ticketLink, setTicketLink] = useState("");
  const [show, setShow] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const handleClose = () => {
    setShow(false);
    if (eventType === "1") {
      window.location.href = '/events';
    } else {
      window.location.href = '/events';
    }
  };

  const [errors, setErrors] = useState({
    eventType: "",
    eventName: "",
    date: "",
    endDate : "",
    startTime: "",
    endTime: "",
    venue: "",
    description: "",
    flyerLink: "",
    ticketLink: "",
    eventImage: "",
  });

  const AddNewEvent = async () => {
    const data = {
      eventType: eventType,
      eventName: eventName,
      date: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
      endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      startTime: moment(startTime, "HH:mm").format("h:mm A"),
      endTime: moment(endTime, "HH:mm").format("h:mm A"),
      venue: venue,
      description: description,
      flyerLink: flyerLink,
      ticketLink: ticketLink,
      eventImage: eventImage,
    };

    const response = await dispatch(AddEventService({ data }));
    if (response.type === "event/AddEventService/fulfilled") {
      ;
      setResponseMessage(response?.payload?.message);
      handleShow();
    } else {
      toast.error(response?.payload?.response?.data?.error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      AddNewEvent();
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      if (extension !== 'png' && extension !== 'pdf' && extension !== 'jpeg' && extension !== 'jpg') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          eventImage: "Please upload a file with .png, .jpg, .jpeg and .pdf extension",
        }));
        return; // Stop further processing if the file extension is not valid
      }

      const formData = new FormData();
      formData.append("files", file);
      try {
        const res = await dispatch(UploadMedia(formData));
        if (res.type === "common/UploadMedia/fulfilled") {
          setEventImage(res.payload?.files[0]?.mediaName);
          setErrors((Clearerror) => ({
            ...Clearerror,
            eventImage: "", // Clear any previous errors related to eventImage
          }));
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      // If no file is selected, set the error message
      setErrors((prevErrors) => ({
        ...prevErrors,
        eventImage: "Please upload file",
      }));
    }
  };

  const isValidUrl = (url) =>  {
    try {
      // Regular expression for a URL
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3})|" + // OR IP (v4) address
          "localhost|" + // OR localhost
          "([a-zA-Z\\d-]+\\.([a-zA-Z]{2,}))\\.?)" + // subdomain
          "(:\\d+)?(\\/[-a-zA-Z\\d%@_.~+&:]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
          "(#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
  
      return pattern.test(url);
    } catch (error) {
      return false;
    }
  }


  const validate = () => {
    let isValid = true;
    const newErrors = {
      eventType: "",
      eventName: "",
      date: "",
      endDate : "",
      startTime: "",
      endTime: "",
      venue: "",
      description: "",
      flyerLink: "",
      ticketLink: "",
      eventImage: "",
    };

    // Trimmed values
    const trimmedEventType = eventType.trim();
    const trimmedEventName = eventName.trimStart();
    const trimmedDate = date.trim();
    const trimmedEndDate = endDate.trim();
    const trimmedStartTime = startTime.trim();
    const trimmedEndTime = endTime.trim();
    const trimmedVenue = venue.trimStart();
    const trimmedDescription = description.trimStart();
    const trimmedFlyerLink = flyerLink.trim();
    const trimmedTicketLink = ticketLink.trim();
    const trimmedEventImage = eventImage ? eventImage.trim() : "";

    if (!trimmedEventType) {
      newErrors.eventType = "Please select an option";
      isValid = false;
    }
    if (!trimmedEventName) {
      newErrors.eventName = "Please enter a name";
      isValid = false;
    }

    if (!trimmedDate) {
      newErrors.date = "Please enter a date";
      isValid = false;
    }else if(trimmedStartTime){
        const currentDateTime = moment().format("YYYY-MM-DD HH:mm");
        const selectedDateTime = moment(`${moment(trimmedDate).format('YYYY-MM-DD')} ${trimmedStartTime}`).format('YYYY-MM-DD HH:mm');
        const date1 = new Date(currentDateTime);
        const date2 = new Date(selectedDateTime);
        if((date1 > date2)){
          newErrors.startTime = 'Please select any future time';
          isValid = false;
        }
    }

    if (trimmedEndDate) {
        const selectedStartDate = moment(`${moment(trimmedDate).format('YYYY-MM-DD')}`).format("YYYY-MM-DD");
        const selectedEndDate = moment(`${moment(trimmedEndDate).format('YYYY-MM-DD')}`).format('YYYY-MM-DD');
        const date1 = new Date(selectedStartDate);
        const date2 = new Date(selectedEndDate);
        if((date1 > date2)){
          newErrors.endDate = 'Please select any future end date from selected start date';
          isValid = false;
        }
    }

    if (!trimmedStartTime) {
      newErrors.startTime = "Please enter a start time";
      isValid = false;
    }else if(trimmedDate){
      const currentDateTime = moment().format("YYYY-MM-DD HH:mm");
      const selectedDateTime = moment(`${moment(trimmedDate).format('YYYY-MM-DD')} ${trimmedStartTime}`).format('YYYY-MM-DD HH:mm');
      const date1 = new Date(currentDateTime);
      const date2 = new Date(selectedDateTime); 
      if((date1 > date2)){
        newErrors.startTime = 'Please select any future time';
        isValid = false;
      }
    }

    if (!trimmedEndTime) {
      newErrors.endTime = "Please enter an end time";
      isValid = false;
    } else if (trimmedEndTime <= trimmedStartTime) {
      newErrors.endTime = "End time must be after start time";
      isValid = false;
    }

    if (!trimmedVenue) {
      newErrors.venue = "Please enter a location";
      isValid = false;
    }
    if (!trimmedDescription) {
      newErrors.description = "Please enter a description";
      isValid = false;
    }

    // Validate flyerLink
    // if (!trimmedFlyerLink) {
    //   newErrors.flyerLink = "Please enter a link";
    //   isValid = false;
    // } else if (!isValidUrl(trimmedFlyerLink)) {
    //   newErrors.flyerLink = "Please enter a valid URL";
    //   isValid = false;
    // }

    // Validate ticketLink
    // if (!trimmedTicketLink) {
    //   newErrors.ticketLink = "Please enter a link";
    //   isValid = false;
    // } else if (!isValidUrl(trimmedTicketLink)) {
    //   newErrors.ticketLink = "Please enter a valid URL";
    //   isValid = false;
    // }

    if (!trimmedEventImage) {
      newErrors.eventImage = "Please upload file";
      isValid = false;
    } else {
      const extension = trimmedEventImage.split('.').pop().toLowerCase();
      if (extension !== 'png' && extension !== 'pdf' && extension !== 'jpeg' && extension !== 'jpg') {
        newErrors.eventImage = "Please upload a file with .png, .jpg, .jpeg and .pdf extension";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };


  const getMinTime = (isStartTime) => {
    const currentDate = new Date();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();

    if (!isStartTime) {
      minutes += 1;
    }

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${minutes}`;
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    await validate();

    switch (name) {
      case "eventType":
        setEventType(value);
        break;
      case "eventName":
        setEventName(value);
        break;
      case "date":
        setDate(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      case "startTime":
        setStartTime(value); // Update startTime directly with the value
        break;
      case "endTime":
        setEndTime(value); // Update endTime directly with the value
        break;
      case "venue":
        setVenue(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "flyerLink":
        setFlyerLink(value);
        break;
      case "ticketLink":
        setTicketLink(value);
      default:
        break;
    }
  };

const getMinEndDate = () => {
    if (date) {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      return newDate.toISOString().split("T")[0];
    }
    return new Date().toISOString().split("T")[0];
  };

  const currentDateTime = new Date().toISOString().slice(0, 16);
  return (
    <>
      <div>
        <div className="w-100 h-100 ">
          <Header />
          <div className="d-flex">
            <Sidebar />
            <main className="w-100 MainSection">
              <InnerHeader text="Events / New Event" arrow={true}>
                <div></div>
              </InnerHeader>

              <div className="conten-div">
                <div className="single-admin-data">
                  <div className="d-flex justify-content-between w-100 mb-4 ">
                    <h2 className="BoxTitle">Add a New Event</h2>

                    <div>
                      <button
                        onClick={handleSubmit}
                        className="SiteBtn2 export-csv-btn btn"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="">
                        <div className="did-floating-label-content">
                          <select
                            className="did-floating-select"
                            value={eventType}
                            onChange={handleChange}
                            name="eventType"
                          >
                            <option value="">Choose Event Type</option>
                            <option value="1">Brea</option>
                            <option value="2">Tenants</option>
                          </select>
                          <label className="did-floating-label">
                            Event Type
                          </label>
                          {errors.eventType && (
                            <div style={{ color: "red" }}>
                              {errors.eventType}
                            </div>
                          )}
                        </div>

                        <div className="did-floating-label-content">
                          <input
                            className="did-floating-input"
                            type="text"
                            value={eventName}
                            onChange={handleChange}
                            name="eventName"
                            placeholder=" "
                          />
                          <label className="did-floating-label">
                            Event Name
                          </label>
                          {errors.eventName && (
                            <div style={{ color: "red" }}>
                              {errors.eventName}
                            </div>
                          )}
                        </div>

                        <div className="did-floating-label-content">
                          <input
                            className="did-floating-input"
                            type="date"
                            value={date}
                            onChange={handleChange}
                            name="date"
                            placeholder=" "
                            min={new Date().toISOString().split("T")[0]} 
                          />
                          <label className="did-floating-label">Start Date</label>
                          {errors.date && (
                            <div style={{ color: "red" }}>{errors.date}</div>
                          )}
                        </div>

                        <div className="did-floating-label-content">
                          <input
                            className="did-floating-input"
                            type="date"
                            value={endDate}
                            onChange={handleChange}
                            name="endDate"
                            placeholder=" "
                            min={getMinEndDate()}
                          />
                          <label className="did-floating-label">End Date</label>
                          {errors.endDate && (
                            <div style={{ color: "red" }}>{errors.endDate}</div>
                          )}
                        </div>

                          {/* {errors.date && (
                            <div style={{ color: "red" }}>{errors.date}</div>
                          )} */}
                        <div className="did-floating-label-content">
                          <input
                            type="time"
                            className={`did-floating-input ${errors.startTime ? "is-invalid" : ""}`}
                            id="startTime"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            min={getMinTime(true)} // Set min attribute to the current time
                          />
                          <label className="did-floating-label">
                            Start Time
                          </label>
                          {errors.startTime && (
                            <div style={{ color: "red" }}>
                              {errors.startTime}
                            </div>
                          )}
                        </div>

                        <div className="did-floating-label-content">
                          <input
                            type="time"
                            className={`did-floating-input ${errors.endTime ? "is-invalid" : ""}`}
                            id="endTime"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            min={getMinTime(false)}// Set min attribute to the current time
                          />
                          <label className="did-floating-label">End Time</label>
                          {errors.endTime && (
                            <div style={{ color: "red" }}>{errors.endTime}</div>
                          )}
                        </div>

                        <div className="did-floating-label-content">
                          <input
                            className="did-floating-input"
                            type="text"
                            value={venue}
                            onChange={handleChange}
                            name="venue"
                            placeholder=" "
                          />
                          <label className="did-floating-label">Venue</label>
                          {errors.venue && (
                            <div style={{ color: "red" }}>{errors.venue}</div>
                          )}
                        </div>

                        <div className="did-floating-label-content">
                          <input
                            className="did-floating-input"
                            type="text"
                            value={description}
                            onChange={handleChange}
                            name="description"
                            placeholder=" "
                          />
                          <label className="did-floating-label">
                            Description
                          </label>
                          {errors.description && (
                            <div style={{ color: "red" }}>
                              {errors.description}
                            </div>
                          )}
                        </div>

                        <div className="did-floating-label-content">
                          <input
                            className="did-floating-input"
                            type="url"
                            value={flyerLink}
                            onChange={handleChange}
                            name="flyerLink"
                            placeholder=" "
                          />
                          <label className="did-floating-label">
                            Flyer Link
                          </label>
                          {errors.flyerLink && (
                            <div style={{ color: "red" }}>
                              {errors.flyerLink}
                            </div>
                          )}
                        </div>

                        <div className="did-floating-label-content">
                          <input
                            className="did-floating-input"
                            type="url"
                            value={ticketLink}
                            onChange={handleChange}
                            name="ticketLink"
                            placeholder=" "
                          />
                          <label className="did-floating-label">
                            Ticket Link
                          </label>
                          {errors.ticketLink && (
                            <div style={{ color: "red" }}>
                              {errors.ticketLink}
                            </div>
                          )}
                        </div>
                        <div className="did-floating-label-content">
                          <label
                            htmlFor="file-upload"
                            className="file-upload-label m-0"
                          >
                            <span>Upload File +</span>
                            <input
                              type="file"
                              accept=".png,.pdf,.jpg,.jpeg"
                              onChange={handleFileChange}
                              id="file-upload"
                              name="file-upload"
                            />
                          </label>
                          {errors.eventImage && (
                            <div style={{ color: "red" }}>
                              {errors.eventImage}
                            </div>
                          )}
                        </div>
                        {!eventImage
                          ?
                          <></>
                          :
                          <img
                            src={
                              process.env.REACT_APP_BACKEND_URL +
                              "uploads/" +
                              eventImage
                            }
                            className="single-category-img"
                            alt="!Oops image is not available"
                          />
                        }
                        <p>{eventImage ? eventImage : ''}</p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "0px solid white" }}>
          {/* <Modal.Title >Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="modal-body-text">{responseMessage}</Modal.Body>
        <Modal.Footer style={{ borderTop: "0px solid white" }}>
          <Button className="modal-btn-close my-auto" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEvent;
