import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import InnerHeader from "../InnerHeader";
import { useDispatch } from "react-redux";
import { AddNotificationService } from '../../redux/features/NotificationService';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetAllCategoryService } from "../../redux/features/CategoryService";
import _ from "lodash";

const AddNotification = () => {
  const { allCategory } = useSelector((state) => state.category);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [details, setDetails] = useState('');
  const [mobileAppPage, setMobileAppPage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    title: '',
    date: '',
    time: '',
    details: '',
    mobileAppPage: ''
  });

    //API calling
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await dispatch(GetAllCategoryService({ status: "1", limit: 10 }));
          if (res.type === "category/GetAllCategoryService/fulfilled") {
          }
          // setLoading(true);
        } catch {
          toast.error("failed to load app users");
        }
      }
      fetchData();
    }, []); // Set loading to true when fetching data

  const validate = () => {
    let isValid = true;
    const newErrors = {
      title: '',
      date: '',
      time: '',
      details: '',
      mobileAppPage: ''
    };
    if (!title.trim()) {
      newErrors.title = 'Please enter a title';
      isValid = false;
    }
    if (!date.trim()) {
      newErrors.date = 'Please enter a date';
      isValid = false;
    }else if(time){
        const currentDateTime = moment().tz('America/Los_Angeles').format("YYYY-MM-DD HH:mm");
        const selectedDateTime = moment(`${moment(date).format('YYYY-MM-DD')} ${time}`).format('YYYY-MM-DD HH:mm');
        const date1 = new Date(currentDateTime);
        const date2 = new Date(selectedDateTime);
        if((date1 > date2)){
          newErrors.time = 'Please select any future PT time';
          isValid = false;
        }
    }
    
    if (!time.trim()) {
      newErrors.time = 'Please enter a time';
      isValid = false;
    }else if(time){
      const currentDateTime = moment().tz('America/Los_Angeles').format("YYYY-MM-DD HH:mm");
      const selectedDateTime = moment(`${moment(date).format('YYYY-MM-DD')} ${time}`).format('YYYY-MM-DD HH:mm');
      const date1 = new Date(currentDateTime);
      const date2 = new Date(selectedDateTime); 
      if((date1 > date2)){
        newErrors.time = 'Please select any future PT time';
        isValid = false;
      }
    }

    if (!details.trim()) {
      newErrors.details = 'Please enter details';
      isValid = false;
    }
    if (!mobileAppPage.trim()) {
      newErrors.mobileAppPage = 'Please select an option';
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    const currentTime = moment();

    switch (name) {
      case 'title':
        setTitle(value);
        break;
      case 'date':
        setDate(value);
        break;
      case 'time':
        setTime(value);
        break;
      case 'details':
        setDetails(value);
        break;
      case 'mobileAppPage':
        setMobileAppPage(value);
        break;
      default:
        break;
    }
  };

  const AddNewNotification = async (e) => {
    const data = {
      title: title,
      date: date,
      time: time,
      details: details,
      mobileAppPage: mobileAppPage,
      status: "1"
    }

    const response = await dispatch(AddNotificationService({ data }));
    if (response.type === "notification/AddNotificationService/fulfilled") {
      toast.success(response?.payload?.message, { autoClose: 3000 });
      navigate("/notifications");
    } else {
      toast.error(response?.payload?.response?.data?.message, {
        autoClose: 3000,
      });
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      AddNewNotification();
    }
  }

  return (
    <>
      <div>
        <div className="w-100 h-100 ">
          <Header />
          <div className="d-flex">
            <Sidebar />
            <main className="w-100 MainSection">

              {/* Inner Header Starts */}
              <InnerHeader text="Add New Notification" arrow={true}>
                <div></div>
              </InnerHeader>

              {/* Display App user data */}
              <div className="conten-div">
                <div className="single-admin-data">
                  <div className="d-flex justify-content-between w-100 mb-4">
                    <h2 className='BoxTitle'>Notification Details</h2>

                    {/* Add button*/}
                    <div>
                      <button
                        onClick={onSubmit}
                        className="SiteBtn2 export-csv-btn btn">
                        Add
                      </button>
                    </div>
                  </div>
                  <div className=''>
                    <form
                      onSubmit={onSubmit}
                    >
                      <div className="">
                        <div className="did-floating-label-content">
                          <input className="did-floating-input" type="text"
                            onChange={handleChange}
                            value={title}
                            name='title'
                            placeholder=" " />
                          <label className="did-floating-label">Title</label>
                          {errors.title && <div style={{ color: 'red' }}>{errors.title}</div>}
                        </div>
                        <div className="did-floating-label-content">
                          <input className="did-floating-input" type="date"
                            onChange={handleChange}
                            value={date}
                            name='date'
                            min={moment().tz('America/Los_Angeles').format("YYYY-MM-DD")}
                            placeholder=" " />
                          <label className="did-floating-label">Date</label>
                          {errors.date && <div style={{ color: 'red' }}>{errors.date}</div>}
                        </div>
                        <div className="did-floating-label-content">
                          <input className="did-floating-input" type="time"
                            onChange={handleChange}
                            value={time}
                            name='time'
                            placeholder=" " />
                          <label className="did-floating-label">Time</label>
                          {errors.time && <div style={{ color: 'red' }}>{errors.time}</div>}
                        </div>
                        <div className="did-floating-label-content">
                          <input className="did-floating-input" type="text"
                            onChange={handleChange}
                            value={details}
                            name='details'
                            placeholder=" " />
                          <label className="did-floating-label">Details</label>
                          {errors.details && <div style={{ color: 'red' }}>{errors.details}</div>}
                        </div>
                        <div className="did-floating-label-content">
                          <select className="did-floating-select"
                            onChange={handleChange}
                            value={mobileAppPage}
                            name='mobileAppPage'
                          >
                            <option value="">Search by Page Link </option>
                            <option value="Home Page">Home Page</option>
                            <option value="Directory Page">Directory Page</option>
                            <option value="Featured Events Page">Featured Events Page</option>
                            <option value="Profile Page">Profile Page</option>
                            {allCategory?.data?.records.map((category, index) => {
                                return (
                                  <option value={category.categoryType}>{category.categoryType}</option>
                                );
                            })}
                          </select>
                          <label className="did-floating-label">Mobile App Page</label>
                          {errors.mobileAppPage && <div style={{ color: 'red' }}>{errors.mobileAppPage}</div>}
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
              </div>

            </main>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNotification;