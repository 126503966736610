import React from "react";
import BDlogo from "../images/BDlogo.png";

const MainCenterBox = (props) => {
  const { title, className, children, step } = props;
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100">
        <div className={className}>
          <div className="main_center_box">
            <div className="text-center">
              <img src={BDlogo} className="img-fluid" alt="logo_box" />
            </div>
            <h6 className="text-center py-3">{title}</h6>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainCenterBox;
