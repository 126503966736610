import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import InnerHeader from "../InnerHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AddEventService } from '../../redux/features/EventFeedService';
import { UploadMedia } from '../../redux/features/CommonFileService';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import validator from 'validator';

const AddSingleDailyHappening = () => {
  document.title = "Daily Happening - Brea downtown";
  const [isSaveImage, setIsSaveImage] = useState(false);
  const [isSaveData, setIsSaveData] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [eventImage, setEventImage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    link: "",
    eventImage: "",
  });

  const handleClose = () => {
    setShow(false);
    window.location.href = '/events';
    // navigate("/events");
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    // Update state based on field name
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "link":
        setLink(value);
        break;
      default:
        break;
    }

    // Clear validation message for the field being modified
    setErrors({ ...errors, [name]: "" });
    if (title.trim() && description.trim() && link.trim() && eventImage) {
      setIsSaveData(true); // Enable Save button for data
      setIsSaveImage(true); // Enable Save button for image
    } else {
      setIsSaveData(false); // Disable Save button for data
      setIsSaveImage(false); // Disable Save button for image
    }
  };

  const isValidUrl = (url) =>  {
    try {
      // Regular expression for a URL
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3})|" + // OR IP (v4) address
          "localhost|" + // OR localhost
          "([a-zA-Z\\d-]+\\.([a-zA-Z]{2,}))\\.?)" + // subdomain
          "(:\\d+)?(\\/[-a-zA-Z\\d%@_.~+&:]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
          "(#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
  
      return pattern.test(url);
    } catch (error) {
      return false;
    }
  }



  const validate = () => {
    let isValid = true;
    const newErrors = {
      title: "",
      description: "",
      link: "",
      file: "",
    };

    if (!title.trim()) {
      newErrors.title = "Please enter a title";
      isValid = false;
    }
    if (!description.trim()) {
      newErrors.description = "Please enter description";
      isValid = false;
    }
    if (!link.trim()) {
      newErrors.link = "Please enter a link";
      isValid = false;
    } else {
      if (!isValidUrl(link.trim())) {
        console.log('hello world validation call');
        newErrors.link = "Please enter a valid URL";
        isValid = false;
      }
    }
    if (!eventImage) {
      newErrors.eventImage = "Please upload file";
      isValid = false;
    } else {
      const extension = eventImage.split(".").pop().toLowerCase();
      if (extension !== 'png' && extension !== 'pdf' && extension !== 'jpeg' && extension !== 'jpg') {
        newErrors.eventImage = "Please upload a file with .png or .pdf extension";
        isValid = false;
      }
    }
    setErrors(newErrors);
    setIsSaveData(isValid);
    setIsSaveImage(isValid);
    return isValid;
  };



  const AddNewEvent = async () => {
    const data = {
      eventType: "3",
      title: title,
      description: description,
      link: link,
      eventImage: eventImage,
    };

    const response = await dispatch(AddEventService({ data }));
    if (response.type === "event/AddEventService/fulfilled") {
      // console.log(response?.payload?.message);
      setResponseMessage(response?.payload?.message);
      handleShow();
    } else {
      toast.error(response?.payload?.response?.message);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      if (extension !== 'png' && extension !== 'pdf' && extension !== 'jpeg' && extension !== 'jpg') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          eventImage: "Please upload a file with .png or .pdf extension",
        }));
        return; // Stop further processing if the file extension is not valid
      }

      const formData = new FormData();
      formData.append("files", file);
      try {
        const res = await dispatch(UploadMedia(formData));
        if (res.type === "common/UploadMedia/fulfilled") {
          setEventImage(res.payload?.files[0]?.mediaName);
          setErrors((Clearerror) => ({
            ...Clearerror,
            eventImage: "", // Clear any previous errors related to eventImage
          }));
          setIsSaveImage(true); // Enable Save button after successful file upload
          // Check if all fields are filled
          if (title.trim() && description.trim() && link.trim()) {
            setIsSaveData(true); // Enable Save button for data
          }
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      // If no file is selected, set the error message
      setErrors((prevErrors) => ({
        ...prevErrors,
        eventImage: "Please upload file",
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      AddNewEvent();
    }
  };

  return (
    <>
      <div>
        <div>
          <div className="w-100 h-100 ">
            <Header />
            <div className="d-flex">
              <Sidebar />
              <main className="w-100 MainSection">
                <InnerHeader text="Events / New Daily Happenings" arrow={true}>
                  <div></div>
                </InnerHeader>

                <div className="conten-div">
                  <div className="single-admin-data">
                    <div className="d-flex justify-content-between w-100 mb-4">
                      <h2 className="BoxTitle">Add a New Daily Happenings</h2>
                      <div>
                        <button
                          onClick={handleSubmit}
                          className={`SiteBtn2 export-csv-btn btn ${isSaveData ? "" : "disabled"
                            }`}
                          disabled={!isSaveData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <form
                        onSubmit={handleSubmit}
                        className=""
                        encType="multipart/form-data"
                      >
                        <div className="did-floating-label-content">
                          <input
                            className="did-floating-input"
                            type="text"
                            value={title}
                            onChange={handleChange}
                            name="title"
                            placeholder=" "
                          />
                          <label className="did-floating-label">Title</label>
                          {errors.title && (
                            <div style={{ color: "red" }}>{errors.title}</div>
                          )}
                        </div>
                        <div class="did-floating-label-content">
                          <textarea
                            className="did-floating-input txtarea-description"
                            id="description"
                            name="description"
                            value={description} // Add value attribute
                            onChange={handleChange} // Connect onChange to handleChange
                            placeholder=" "
                          />
                          <label class="did-floating-label" for="description">
                            Description
                          </label>
                          {errors.description && (
                            <div style={{ color: "red" }}>
                              {errors.description}
                            </div>
                          )}
                        </div>
                        <div className="did-floating-label-content">
                          <input
                            className="did-floating-input"
                            type="url"
                            value={link}
                            onChange={handleChange}
                            name="link"
                            placeholder=" "
                          />
                          <label className="did-floating-label">Link</label>
                          {errors.link && (
                            <div style={{ color: "red" }}>{errors.link}</div>
                          )}
                        </div>
                        <div className="did-floating-label-content">
                          <label
                            htmlFor="file-upload"
                            className="file-upload-label m-0"
                          >
                            <span>Upload File +</span>
                            <input
                              accept="image/*"
                              type="file"
                              onChange={handleFileChange}
                              id="file-upload"
                              name="file-upload"
                            />
                          </label>
                          {errors.eventImage && (
                            <div
                              className="new-happeninng-file-err m-auto"
                              style={{ color: "red" }}
                            >
                              {errors.eventImage}
                            </div>
                          )}
                        </div>
                        {eventImage &&
                              eventImage
                                .toLowerCase()
                                .endsWith(".pdf") ? (
                              <a
                                href={`${process.env.REACT_APP_BACKEND_URL}uploads/${eventImage}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                PDF Link
                              </a>
                            ) : (
                              !eventImage ?
                                <></>
                              :
                              <img
                                src={
                                  eventImage
                                    ? `${process.env.REACT_APP_BACKEND_URL}uploads/${eventImage}`
                                    : ``
                                }
                                alt="Uploaded File"
                                className="single-category-img"
                              />
                            )}
                      </form>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "0px solid white" }}>
          {/* <Modal.Title >Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="modal-body-text">{responseMessage}</Modal.Body>
        <Modal.Footer style={{ borderTop: "0px solid white" }}>
          <Button className="modal-btn-close my-auto" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  );
};

export default AddSingleDailyHappening;
