import React, { useState } from "react";
import MainCenterBox from "../MainCenterBox";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ResetPasswordLinkService } from "../../redux/features/AuthService";
import { useNavigate } from "react-router-dom";
import Button from "../Common/Button";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "*Email is required to reset password";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "*Please check your entry and try again";
  }
  return errors;
};

const ResetPassword = () => {

  document.title = "Reset Password | Brea Downtown";
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseMessageError, setResponseMessageError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleErrorClose = () => setShowError(false);
  const handleErrorShow = () => setShowError(true);

  const handleClose = () => {
    setShow(false);
    navigate("/sign-in");
  };
  
 
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    // enableReinitialize: true,
    validate,
    onSubmit: async (values) => {
      // console.log(values?.email);
      Cookies.set(values?.email, 'signin-email-bd');
      const response = await dispatch(ResetPasswordLinkService(values));
      if (response.type === "auth/ResetPasswordLinkService/fulfilled") {
        handleShow();
        Cookies.remove("signin-email-bd") 
        setResponseMessage(response?.payload?.message);
      
        setResponseMessageError(""); // Clear error message on success
        formik.values.email = "";
      } else {
        setResponseMessageError(response?.payload?.response?.data?.message);
        handleErrorShow();
      }
    },
  });

  return (
    <>
      <MainCenterBox
        title={"Reset Password"}
        className="col-lg-4 col-md-8  m-auto "
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="main_form_box mb-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_1659_28499)">
                <path
                  d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"
                  fill="#42474E"
                />
              </g>
              <defs>
                <clipPath id="clip0_1659_28499">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="form-group">
              <input
                className="input-field"
                type="email"
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
                name="email"
              />
            </div>
          </div>
          
          {formik.errors.email || responseMessageError? (
            <div className="error">{formik.errors.email ? formik.errors.email : responseMessageError ? responseMessageError: ""}</div>
          ) : " "}
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div
              className="link_pasword fw-medium"
              onClick={() =>
                navigate("/sign-in", {
                  state: {
                    passwordTab: true,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            >
              Back
            </div>
            <Button
              type="submit"
              // onClick={ handleCookie() }
              className="cust-btn"
              text="Reset"
            />
          </div>
        </form>
      </MainCenterBox>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          style={{ borderBottom: "0px solid white" }}
        ></Modal.Header>
        <Modal.Body className='modal-body-text'>{responseMessage}</Modal.Body>
        <Modal.Footer style={{ borderTop: "0px solid white" }}>
          <Button
            className="modal-btn-ok my-auto"
            onClick={handleClose}
            text="OK"
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={showError}
        onHide={handleErrorClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          style={{ borderBottom: "0px solid white" }}
        ></Modal.Header>
        <Modal.Body className='modal-body-text'>{responseMessageError}</Modal.Body>
        <Modal.Footer style={{ borderTop: "0px solid white" }}>
          <Button
            className="modal-btn-ok my-auto"
            onClick={handleErrorClose}
            text="OK"
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default ResetPassword;
