import React, { useEffect, useState } from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'
import InnerHeader from "../InnerHeader";
import { GetSingleUser, UpdateAppUserDetails } from "../../redux/features/UserService";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners'; // Importing RingLoader

const validate = (values) => {
    const errors = {};
    if (!values?.email) {
        errors.email = "*Please enter an email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "*Please type in a valid email";
    }
    if (!values?.notificationStatus) {
        errors.notificationStatus = "Please enter an notification status";
    }
    if (!values?.status) {
        errors.status = "Please select an option";
    }
    if (!values?.discountBadge) {
        errors.discountBadge = "Please select an option";
    }
    return errors;
};

const AppSingleUSer = () => {
    document.title = "App Users | Brea Downtown";
    const dispatch = useDispatch();
    const [isComponentVisible, setComponentVisibility] = useState(false);
    const [loading, setLoading] = useState(false);
    const { SingleUser } = useSelector((state) => state?.users)
    const params = useParams();
    const id = params.id;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            if (id) {
                const res = await dispatch(GetSingleUser({ id }));
                if (res.type === "users/GetSingleUser/fulfilled") {
                    setIsLoading(false);
                }
            }
        };
        fetchData();
    }, [id]);

    const formik = useFormik({
        initialValues: {
            email: SingleUser?.resData?.email || "",
            notificationStatus: SingleUser?.resData?.notificationStatus || "",
            status: SingleUser?.resData?.status || "",
            discountBadge: SingleUser?.resData?.discountBadge || "",
        },
        enableReinitialize: true,
        validate,
        onSubmit: async (values) => {
            const response = await dispatch(UpdateAppUserDetails({ id, data: values }));
            if (response.type === "users/UpdateAppUserDetails/fulfilled") {
                setComponentVisibility(false);
                await dispatch(GetSingleUser({ id }));

            } else if (response.type === "users/UpdateAppUserDetails/rejected") {

                toast.error(response?.payload?.response?.data?.message, { autoClose: 3000 })
            }
            // setIsLoading(false);
        },
    });

    const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

    return (
        <>
            <div>
                <div className="w-100 h-100 ">
                    <Header />
                    <div className="d-flex  position-relative">
                        <Sidebar />
                        <main className="w-100 MainSection">

                            {/* Inner Header Starts */}
                            <InnerHeader text={`App Users / ${SingleUser?.resData?.email ? SingleUser?.resData?.email : " "}`} arrow={true}>
                                <div></div>
                            </InnerHeader>

                            {/* Display App user data */}
                            {/* {loading && <Loader />} */}
                            <div className="conten-div">
                                <div className="singleUserGeneralDataTable">

                                    {/* Edit and Display Design */}
                                    {isComponentVisible ?
                                        <>
                                            <div className=''>
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="d-flex justify-content-between w-100">
                                                        <h2 className='Title'>General</h2>
                                                        <button type='submit' className="SiteBtn2 export-csv-btn btn rounded-5 ">
                                                            Save
                                                        </button>
                                                    </div>
                                                    <div className="mt-4">
                                                        <div className="did-floating-label-content">
                                                            <input className="did-floating-input" type="text" id='email' name='email' onChange={formik?.handleChange} value={formik?.values?.email} placeholder=" " />
                                                            <label className="did-floating-label">Email</label>
                                                            {formik?.touched?.email && formik?.errors?.email ? (
                                                                <div className="error">{formik?.errors?.email}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="did-floating-label-content diabled-div-nlo">
                                                            <input className="did-floating-input diabled-input-nlo" disabled type="text" value={SingleUser.resData?.newsLetterOptIn === "0" ? "No" : "Yes"} placeholder=" " />
                                                            <label className="did-floating-label diabled-label-nlo">Newsletter Opt in</label>
                                                        </div>

                                                        <div className="did-floating-label-content">
                                                            <select className="did-floating-select" name='notificationStatus' id='notificationStatus' onChange={formik?.handleChange} value={formik?.values?.notificationStatus} placeholder=" ">
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <label className="did-floating-label">Push Notification</label>
                                                            {formik?.touched?.notificationStatus && formik?.errors?.notificationStatus ? (
                                                                <div className="error">{formik?.errors?.notificationStatus}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="did-floating-label-content">
                                                            <select className="did-floating-select" id='status' name='status' onChange={formik?.handleChange} value={formik?.values?.status} placeholder=" ">
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>
                                                            </select>
                                                            <label className="did-floating-label">Status</label>
                                                            {formik?.touched?.status && formik?.errors?.status ? (
                                                                <div className="error">{formik?.errors?.status}</div>
                                                            ) : null}
                                                        </div>
                                                        {/* <div className="did-floating-label-content">
                                                            <select className="did-floating-select" id='discountBadge' name='discountBadge' onChange={formik?.handleChange} value={formik?.values?.discountBadge} placeholder=" ">
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <label className="did-floating-label">Discount</label>
                                                            {formik?.touched?.discountBadge && formik?.errors?.discountBadge ? (
                                                                <div className="error">{formik?.errors?.discountBadge}</div>
                                                            ) : null}
                                                        </div> */}
                                                    </div>
                                                </form>

                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="d-flex justify-content-between w-100">
                                                <h2 className='Title'>General</h2>
                                                <button onClick={(e) => { e.preventDefault(); setComponentVisibility(true) }} className="SiteBtn export-csv-btn btn rounded-5 ">
                                                    Edit
                                                </button>
                                            </div>
                                            {isLoading ? (
                                                <div className="loader-container-app m-auto">
                                                    <RingLoader css={override} size={50} color={"#00639B"} loading={isLoading} />
                                                </div>
                                            ) : (
                                                <div className="ms-3">
                                                    <div className="single-username mt-3">
                                                        <div className='single-user-p-main'>Email</div>
                                                        <div className='single-user-p-desc'>{SingleUser?.resData?.email}</div>
                                                    </div>
                                                    <div className="single-user-notification mt-4">
                                                        <div className='single-user-p-main'>Push Notification</div>
                                                        <div className='single-user-p-desc'>
                                                            {SingleUser?.resData?.notificationStatus === "1" ? "Yes" : "No"}

                                                        </div>
                                                    </div>
                                                    <div className="single-user-status mt-4">
                                                        <div className='single-user-p-main'>Status</div>
                                                        <div className='single-user-p-desc'>
                                                            {SingleUser?.resData?.status === "1" ? "Active" : "Inactive"}
                                                        </div>
                                                    </div>
                                                    {/* <div className="single-user-discount mt-4">
                                                    <div className='single-user-p-main'>Discount</div>
                                                    <div className='single-user-p-desc'>
                                                        {SingleUser?.resData?.discountBadge === "1" ? "Yes" : "No"}
                                                    </div>
                                                </div> */}
                                                </div>
                                            )}
                                        </>
                                    }
                                </div>
                                {/* Display About Data */}
                                < div className="singleUserAboutDataTable">
                                    <h2 className='Title'>About</h2>
                                    <div className='ms-3'>
                                        <div className="single-username mt-3">
                                            <div className='single-user-p-main'>Newsletter Opt in </div>
                                            <div className='single-user-p-desc '>
                                                {SingleUser.resData?.newsLetterOptIn === "0" ? "No" : "Yes"}
                                            </div>
                                        </div>
                                        <div className="single-user-notification mt-4">
                                            <div className='single-user-p-main'>Sign Up Date</div>
                                            <div className='single-user-p-desc '>{moment.tz(SingleUser.resData?.signUpDate, 'america/los_angeles').format('MM/DD/yyyy @ HH:mm') + 'h PT'}</div>
                                        </div>
                                        <div className="single-user-status mt-4">
                                            <div className='single-user-p-main'>Last Active Date</div>
                                            <div className='single-user-p-desc '> {moment(SingleUser.resData?.updatedAt).format('MM/DD/yyyy @ hh:mm') + 'h PT'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div >
            </div >
        </>
    )
}
export default AppSingleUSer;


