import React, { useState, useEffect, useRef, useMemo } from "react";
import Header from "../Header";
import InnerHeader from "../InnerHeader";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import AddNotification from "./AddNotification";
import { GetAllActiveNotification } from "../../redux/features/NotificationService";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Common/Loader";
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners'; // Import RingLoader
import { toast } from "react-toastify";
import { setActiveTab } from "../../redux/features/MiscService";
import { GetAllCategoryService } from "../../redux/features/CategoryService";
import _ from "lodash";


const formatDate = (dateString) => {
  if(dateString == "") {
    return "";
  }
  const date = moment(dateString);
  const pacificTime = date.tz('America/Los_Angeles').format('MM/DD/YYYY');
  return pacificTime;
};

const columns = [
  {
    id: 1,
    name: "Title Message",
    selector: (row) => row.title,
    reorder: true,
  },
  {
    id: 2,
    name: "Page Link",
    selector: (row) => row.mobileAppPage,
    reorder: true,
  },
  {
    id: 3,
    name: "Date and Time",
    selector: (row) =>
      formatDate(row?.date) + " " + (row.time) + "h PT",
    reorder: true,
  },
];

const NotificationList = () => {
  document.title = "Brea downtown | Notifications";
  const tabs = ["Scheduled", "Sent"];
  const { allActiveNotifications } = useSelector((state) => state.notification);
  const [isVisible, setIsVisible] = useState({
    active: false,
    inActive: false
  });
  const { allCategory } = useSelector((state) => state.category);
  const [filterNoMatch, setFilterNoMatch] = useState();
  const [isComponentVisible, setComponentVisibility] = useState(false);
  // const [activeTab, setActiveTab] = useState(0);
  const miscState = useSelector((state) => state.misc);
  const activeTab = useMemo(() => miscState?.notification?.activeTab || 0, [miscState?.notification?.activeTab]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSearchText, setSearchText] = useState({
    active: "",
    inActive: ""
  });

  const obj = {
    title: "",
    mobileAppPage: "",
  };

  const [formData, setFormData] = useState({
    active: obj,
    inActive: obj
  });

  //API calling
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await dispatch(GetAllCategoryService({ status: "1", limit: 10 }));
        if (res.type === "category/GetAllCategoryService/fulfilled") {
          setLoading(false);
        }
        // setLoading(true);
      } catch {
        toast.error("failed to load app users");
        setLoading(false);
      }
    }
    fetchData();
  }, []); // Set loading to true when fetching data



  //Active Inactive tab handler
  const handleTabClick = async (index) => {
    // setIsVisible(!isVisible);
    dispatch(setActiveTab({ tabOf: 'notification', activeTab: index }));
    setLoading(true);
    if (index === 0) {
      const res = await dispatch(GetAllActiveNotification({ search: isSearchText?.active, status: "1", filter: formData?.active }));
      if (res.type === "notification/GetAllActiveNotification/fulfilled") {
        setLoading(false);
      }
    } else {
      const res = await dispatch(GetAllActiveNotification({ search: isSearchText?.inActive, status: "0", filter: formData?.inActive }));
      if (res.type === "notification/GetAllActiveNotification/fulfilled") {
        setLoading(false);
      }
    }
    setIsVisible(isVisible);
    // setActiveTab(index);
  };

  const toggleVisibility = async () => {
    if (activeTab === 0) {
      await dispatch(GetAllActiveNotification({ status: "1" }));
    } else {
      await dispatch(GetAllActiveNotification({ status: "0" }))
    }
    clearBtn();
    const visible = {
      active: !activeTab ? !isVisible?.active : activeTab === 0 ? isVisible?.active : false,
      inActive: activeTab ? !isVisible?.inActive : activeTab === 1 ? isVisible?.inActive : false
    }
    setIsVisible(visible);
    setSearchText(obj)
    setFilterNoMatch();
  };

  //search
  const handleSearch = async (searchText, number) => {
    try {
      if (activeTab === 0 && number === 0) {
        setSearchText({ active: searchText, inActive: isSearchText?.inActive })
        await dispatch(GetAllActiveNotification({ search: searchText, status: "1" }));
      } else {
        setSearchText({ active: isSearchText?.active, inActive: searchText })
        await dispatch(GetAllActiveNotification({ search: searchText, status: "0" }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // API calling
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        if (activeTab === 0) {
          const res = await dispatch(GetAllActiveNotification({ status: "1" }));
          if (res.type === "notification/GetAllActiveNotification/fulfilled") {
            setLoading(false);
          }
        } else {
          const res = await dispatch(GetAllActiveNotification({ status: "0" }));
          if (res.type === "notification/GetAllActiveNotification/fulfilled") {
            setLoading(false);
          }
        }
        // setLoading(true);
      } catch {
        toast.error("failed to load app users");
        setLoading(false);
      }
    }
    fetchData();
  }, []); // Set loading to true when fetching data


  const handleRowClick = (row) => {
    navigate(`/notifications/${row?._id}`);
  };

  const clearBtn = async () => {
    if (activeTab === 0) {
      await dispatch(
        GetAllActiveNotification({ status: "1", limit: 10 })
      );
    } else if (activeTab === 1) {
      await dispatch(
        GetAllActiveNotification({ status: "0", limit: 10 })
      );
    }
    setFormData({
      active: obj,
      inActive: obj
    });
  };

  const filterApply = async (e) => {
    e.preventDefault();
    if (activeTab === 0) {
      await dispatch(GetAllActiveNotification({ status: "1", limit: 10, filter: formData?.active }));
    } else if (activeTab === 1) {
      await dispatch(GetAllActiveNotification({ status: "0", limit: 10, filter: formData?.inActive }));
    }
    setFilterNoMatch(true);
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (!activeTab) {
      setFormData({ ...formData, active: { ...formData?.active, [name]: value } })
    } else {
      setFormData({ ...formData, inActive: { ...formData?.inActive, [name]: value } })
    }
  };

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

  return (
    <>
      {/* {loading && <Loader />} */}
      <div className="w-100 h-100 ">
        <Header />
        <div className="d-flex position-relative">
          <Sidebar />
          <div className="MainSection">
            {/* Inner Header Starts */}
            <InnerHeader text={"Notifications "} arrow={false}>
              <div className="sidebar-btn">
                <button
                  onClick={() => {
                    navigate("/notifications/add");
                  }}
                  className="SiteBtn2 export-csv-btn btn"
                >
                  Add Notification
                </button>
              </div>
            </InnerHeader>

            {isComponentVisible ? (
              <AddNotification />
            ) : (
              <div className="conten-div">
                {/* search and filter */}
                <div className="row justify-content-between">
                  <div className="col-md-4 col-lg-3 col-md-4 col-8 mb-3 mb-lg-0">
                    <div className="searchbarmain ">
                      <input
                        type="text"
                        className="inputsearchbarmain"
                        name=""
                        id="search"
                        placeholder="Search"
                        value={!activeTab ? isSearchText?.active : isSearchText?.inActive}
                        onChange={(e) => handleSearch(e.target.value, activeTab)}
                      />
                      <button className="searchbtn" >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1479_513)">
                            <path
                              d="M18.0831 16.3333H17.1614L16.8348 16.0183C18.2348 14.385 18.9581 12.1567 18.5614 9.78833C18.0131 6.545 15.3064 3.955 12.0398 3.55833C7.10475 2.95166 2.95142 7.105 3.55809 12.04C3.95475 15.3067 6.54475 18.0133 9.78809 18.5617C12.1564 18.9583 14.3848 18.235 16.0181 16.835L16.3331 17.1617V18.0833L21.2914 23.0417C21.7698 23.52 22.5514 23.52 23.0298 23.0417C23.5081 22.5633 23.5081 21.7817 23.0298 21.3033L18.0831 16.3333ZM11.0831 16.3333C8.17809 16.3333 5.83309 13.9883 5.83309 11.0833C5.83309 8.17833 8.17809 5.83333 11.0831 5.83333C13.9881 5.83333 16.3331 8.17833 16.3331 11.0833C16.3331 13.9883 13.9881 16.3333 11.0831 16.3333Z"
                              fill="#42474E"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1479_513">
                              <rect width="28" height="28" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>
                  {(activeTab === 0 && isVisible?.active) || (activeTab === 1 && isVisible?.inActive) ?
                    <>
                      <div className="col-lg-7 col-md-5 col-12 order-3 order-md-2">
                        <div className="col-md-12 ">
                          <div className="row NotificationFilterBox">
                            <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                              <div className="input-group">
                                <input
                                  type="text"
                                  name="title"
                                  value={!activeTab ? formData?.active?.title : formData?.inActive?.title}
                                  onChange={(e) => handleInputChange(e)}
                                  id="title"
                                  className="form-control"
                                  placeholder="Search by Title Message"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                              <select name="mobileAppPage" value={!activeTab ? formData?.active?.mobileAppPage : formData?.inActive?.mobileAppPage} onChange={(e) => handleInputChange(e)} className="form-select from-control select2-custom text-left-txt" aria-label="Default select example">
                                <option value="">Search by Page Link </option>
                                <option value="Home Page">Home Page</option>
                                <option value="Directory Page">Directory Page</option>
                                <option value="Featured Events Page">Featured Events Page</option>
                                <option value="Profile Page">Profile Page</option>
                                {allCategory?.data?.records.map((category, index) => {
                                  return (
                                    <option value={category.categoryType}>{category.categoryType}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-end">
                          <button
                            className="SiteBtn main-filter-btn btn"
                            onClick={clearBtn}
                          >
                            Clear
                          </button>
                          <button
                            className="SiteBtn main-filter-btn btn ms-2"
                            onClick={(e) => {
                              filterApply(e);
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </> : <></>
                  }
                  <div className="col-lg-2 col-md-3 col-4 order-2 order-md-3 text-end">
                    <button
                      className="SiteBtn main-filter-btn btn"
                      onClick={toggleVisibility}
                    >
                      Filter
                    </button>
                  </div>
                </div>

                <div className="col-md-4 d-flex mb-5 mt-4 min-btn-notification">
                  {tabs.map((tab, index) => {
                    return (
                      <div
                        key={`${index}-notification-tab`}
                        className="TabButton"
                        onClick={() => handleTabClick(index)}
                        style={{
                          borderBottom:
                            activeTab === index ? "2px solid #00639B" : "none",
                          color: activeTab === index ? "#1A1C1E" : "#42474E",
                        }}
                      >
                        {tab}
                      </div>
                    );
                  })}
                </div>

                {/* <Notifications data Table /> */}
                {activeTab === 0 && (
                  <div>
                    <div className="TableData">
                      {loading ? (
                        <div className="sweet-loading m-auto">
                          <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                        </div>
                      ) : (
                        <DataTable
                          title=""
                          columns={columns}
                          defaultSortFieldId={1}
                          pagination
                          data={allActiveNotifications?.data?.records}
                          onRowClicked={handleRowClick}
                          persistTableHead
                          noDataComponent={
                            <div className="header-text-container">
                              {filterText ?
                                <p className="header-text no-data-text">There are no records to display </p>
                                : (filterNoMatch ?
                                  <p className="header-text no-data-text"> No results matching your filter. Try removing the Filter </p>
                                  : <p className="header-text no-data-text">There are no records to display</p>)
                              }
                            </div>
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {activeTab === 1 && (
                  <div>
                    <div className="TableData">
                      {loading ? (
                        <div className="sweet-loading m-auto">
                          <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                        </div>
                      ) : (
                        <DataTable
                          title=""
                          columns={columns}
                          // data={allUsers}
                          defaultSortFieldId={1}
                          pagination
                          data={allActiveNotifications?.data?.records}
                          onRowClicked={handleRowClick}
                          highlightOnHover
                          persistTableHead
                          noDataComponent={
                            <div className="header-text-container">
                              {filterText ?
                                <p className="header-text no-data-text">There are no records to display </p>
                                : (filterNoMatch ?
                                  <p className="header-text no-data-text"> No results matching your filter. Try removing the Filter </p>
                                  : <p className="header-text no-data-text">There are no records to display</p>)
                              }
                            </div>
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationList;
