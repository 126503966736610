import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import InnerHeader from "../InnerHeader";
import { useDispatch } from "react-redux";
import { AddNewAdmin } from '../../redux/features/AdminService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddAdmin = () => {

  const [isComponentVisible, setComponentVisibility] = useState(false);
  const [adminName, setAdminName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [adminStatus, setAdminStatus] = useState('');
  const [singleAdminData, setSingleAdminData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState('');

  const AddnewAdminData = async (e) => {
    const data = {
      fullName: adminName,
      email: adminEmail,
      status: adminStatus,
    }
    const response = await dispatch(AddNewAdmin({ data }));
    if (response.type === "admin/AddNewAdmin/fulfilled") {
      toast.success(response?.payload?.message, {autoClose : 3000});
      navigate('/admin-users')
    }
  }
  const validate = () => {
    let isValid = true;
    const newErrors = {
      adminName: '',
      adminEmail: '',
      adminStatus: '',
    };

    if (!adminName.trim()) {
      newErrors.adminName = 'Please enter a name';
      isValid = false;
    }
    if (!adminEmail.trim()) {
      newErrors.adminEmail = 'Please enter an email address';
      isValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(adminEmail)) {
      newErrors.adminEmail = '*Please type in a valid email';
      isValid = false;
    }
    if (!adminStatus.trim()) {
      newErrors.adminStatus = 'Please select an option';
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Clear validation message for the field being modified
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    switch (name) {
      case 'adminName':
        setAdminName(value);
        break;
      case 'adminEmail':
        setAdminEmail(value);
        break;
      case 'adminStatus':
        setAdminStatus(value);
        break;
      default:
        break;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      AddnewAdminData();
    }
  };

  return (
    <>
      <div>
        <div className="w-100 h-100 ">
          <Header />
          <div className="d-flex position-relative">
            <Sidebar />
            <main className="MainSection">

              {/* Inner Header Starts */}
              <InnerHeader text="Admin User / New User" arrow={true}>
                <div></div>
              </InnerHeader>

              {/* Display App user data */}
              <div className="conten-div">
                <div className="single-admin-data">
                  <div className="w-100">
                    <div className="d-flex justify-content-between w-100 mb-4 ">
                      <h2 className='BoxTitle'>About</h2>

                      {/* Add button*/}

                      <button className="SiteBtn2 export-csv-btn btn" onClick={onSubmit}>
                        Add
                      </button>

                    </div>
                    <form onSubmit={onSubmit}>
                      <div className="">
                        <div className="did-floating-label-content">
                          <input className="did-floating-input" 
                          value={adminName} 
                          type="text" 
                          name='adminName'
                          onChange={handleChange}
                          placeholder=" " 
                          />
                          <label className="did-floating-label">Full Name</label>
                          {errors.adminName && <div style={{ color: 'red' }}>{errors.adminName}</div>}
                        </div>

                        <div className="did-floating-label-content">
                          <input className="did-floating-input" 
                          value={adminEmail} 
                          type="text" 
                          name='adminEmail'
                          onChange={handleChange} 
                          placeholder=" "
                           />
                          <label className="did-floating-label">Email</label>
                          {errors.adminEmail && <div style={{ color: 'red' }}>{errors.adminEmail}</div>}
                        </div>

                        <div className="did-floating-label-content">
                          <select className="did-floating-select" 
                          value={adminStatus} 
                          onChange={handleChange}
                          name='adminStatus'>
                            <option  selected value="Select">Select</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                          <label className="did-floating-label">Status</label>
                          {errors.adminStatus && <div style={{ color: 'red' }}>{errors.adminStatus}</div>}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </main>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddAdmin;
