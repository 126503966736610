import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import InnerHeader from "../InnerHeader";
import { useDispatch, useSelector } from "react-redux";
import Menu from "../../images/menu.svg";
import {
	CreateWheelTenantService,
	GetAllWheelTenantsService,
	UpdateWheelTenantService,
	DeleteWheelTenantService,
	UpdateWheelTenantPositionsService,
} from "../../redux/features/WheelTenantService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaTrash } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { GetAllTenants } from "../../redux/features/TenantService";

const MAX_TENANTS = 15;
const INITIAL_TENANTS_COUNT = 2;

const AddWheel = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { allActiveTenants } = useSelector((state) => state.tenants);
	const { allWheelTenants } = useSelector((state) => state.wheeltenants);
	const [tenants, setTenants] = useState([]);

	useEffect(() => {
		const fetchTenants = async () => {
			try {
				const response = await dispatch(GetAllTenants({ page: 1 }));
				if (response.type === "tenant/GetAllTenants/fulfilled") {
					console.log("Tenants fetched successfully:", response.payload);
				} else {
					console.error("Failed to fetch tenants:", response.error);
				}
			} catch (error) {
				console.error("Failed to load tenants:", error);
				toast.error("Failed to load tenants");
			}
		};

		fetchTenants();
	}, [dispatch]);

	useEffect(() => {
		const fetchData = async () => {
			const response = await dispatch(GetAllWheelTenantsService());
			if (response.type === "wheelTenant/GetAllWheelTenants/fulfilled") {
				const fetchedTenants = response.payload.wheelTenants.map((wt) => ({
					_id: wt._id,
					tenantId: wt.tenantId._id,
					displayName: wt.displayName || "",
					errors: { tenantId: "", displayName: "" },
					showSave: false,
				}));
				setTenants(fetchedTenants);
			}
		};
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		if (tenants.length === 0) {
			setTenants(
				[...Array(INITIAL_TENANTS_COUNT)].map(() => ({
					_id: "",
					tenantId: "",
					displayName: "",
					errors: { tenantId: "", displayName: "" },
					showSave: false,
				}))
			);
		}
	}, [tenants]);

	const validate = (tenant) => {
		let isValid = true;
		const newErrors = { tenantId: "", displayName: "" };
		if (!tenant.tenantId.trim()) {
			newErrors.tenantId = "Please select a tenant";
			isValid = false;
		}
		if (!tenant.displayName.trim()) {
			newErrors.displayName = "Please enter a display name";
			isValid = false;
		}
		return { isValid, newErrors };
	};

	const handleChange = (index, e) => {
		const { name, value } = e.target;
		const newTenants = tenants.map((tenant, i) => {
			if (i === index) {
				return {
					...tenant,
					[name]: value,
					errors: { ...tenant.errors, [name]: "" },
					showSave: true,
				};
			}
			return tenant;
		});
		setTenants(newTenants);
	};
	const handleSave = async (index) => {
		const tenant = tenants[index];
		const { isValid, newErrors } = validate(tenant);
		if (isValid) {
			try {
				const data = {
					tenantId: tenant.tenantId,
					displayName: tenant.displayName,
				};
				let response;
				if (tenant._id) {
					response = await dispatch(
						UpdateWheelTenantService({ id: tenant._id, data })
					);
				} else {
					response = await dispatch(CreateWheelTenantService({ data }));
				}

				if (
					response.type === "wheelTenant/CreateWheelTenant/fulfilled" ||
					response.type === "wheelTenant/UpdateWheelTenant/fulfilled"
				) {
					toast.success("Wheel Tenant saved successfully");

					setTenants((prevTenants) =>
						prevTenants.map((t, i) =>
							i === index ? { ...t, showSave: false } : t
						)
					);
				} else {
					toast.error("Failed to save Wheel Tenant");
				}
			} catch (error) {
				console.error("Error saving Wheel Tenant:", error);
				toast.error("Internal Server Error");
			}
		} else {
			setTenants((prevTenants) =>
				prevTenants.map((t, i) =>
					i === index ? { ...t, errors: newErrors } : t
				)
			);
		}
	};

	const handleDelete = async (index) => {
		const tenant = tenants[index];
		if (tenant._id) {
			try {
				const response = await dispatch(DeleteWheelTenantService(tenant._id));
				if (response.type === "wheelTenant/DeleteWheelTenant/fulfilled") {
					toast.success("Wheel Tenant deleted successfully");
					const newTenants = tenants.filter((t, i) => i !== index);
					setTenants(newTenants);
				} else {
					toast.error("Failed to delete Wheel Tenant");
				}
			} catch (error) {
				console.error("Error deleting Wheel Tenant:", error);
				toast.error("Internal Server Error");
			}
		} else {
			const newTenants = tenants.filter((t, i) => i !== index);
			setTenants(newTenants);
		}
	};
	const addTenant = () => {
		if (tenants.length < MAX_TENANTS) {
			setTenants((prevTenants) => [
				...prevTenants,
				{
					_id: "",
					tenantId: "",
					displayName: "",
					errors: { tenantId: "", displayName: "" },
					showSave: true, // New tenants should show the Save button by default
				},
			]);
		} else {
			toast.warning(`Maximum ${MAX_TENANTS} tenants allowed.`);
		}
	};

	const onDragEnd = async (result) => {
		if (!result.destination) {
			return;
		}
		const reorderedTenants = Array.from(tenants);
		const [removed] = reorderedTenants.splice(result.source.index, 1);
		reorderedTenants.splice(result.destination.index, 0, removed);

		setTenants(reorderedTenants);

		await dispatch(UpdateWheelTenantPositionsService(reorderedTenants));
	};

	return (
		<>
			<div>
				<Header />
				<div className="d-flex position-relative">
					<Sidebar />
					<main className="MainSection">
						<InnerHeader text="Wheel" arrow={true}>
							<button
								onClick={addTenant}
								className="SiteBtn2 export-csv-btn btn"
								disabled={tenants.length >= MAX_TENANTS}
							>
								Add
							</button>
						</InnerHeader>

						<div className="conten-div">
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="tenants">
									{(provided) => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{tenants.map((tenant, index) => (
												<Draggable
													key={tenant._id || index}
													draggableId={tenant._id || index.toString()}
													index={index}
												>
													{(provided) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className="single-admin-data d-flex align-items-center Wheel-Section"
														>
															<div className="w-100 wheel-box d-flex gap-4 align-items-center">
																<div className="ml-2">
																	<img src={Menu} alt="menu icon" />
																</div>
																<form style={{ width: "100%" }}>
																	<div className="display-wheel-tenants">
																		<div className="did-floating-label-content">
																			<select
																				className="did-floating-select"
																				name="tenantId"
																				value={tenant.tenantId}
																				onChange={(e) => handleChange(index, e)}
																			>
																				<option value="">Select Tenant</option>
																				{allActiveTenants?.data?.records?.map(
																					(tenant) => (
																						<option
																							key={tenant._id}
																							value={tenant._id}
																						>
																							{tenant.tenant}
																						</option>
																					)
																				)}
																			</select>
																			<label className="did-floating-label">
																				Tenant
																			</label>
																			{tenant.errors.tenantId && (
																				<div style={{ color: "red" }}>
																					{tenant.errors.tenantId}
																				</div>
																			)}
																			{/* {index >= INITIAL_TENANTS_COUNT ? ( */}
																			<div className="ml-2 delete">
																				<FaTrash
																					onClick={() => handleDelete(index)}
																					style={{
																						cursor: "pointer",
																						color: "#42474E",
																					}}
																				/>
																			</div>
																			{/* ) : null} */}
																		</div>

																		<div className="did-floating-label-content">
																			<input
																				className="did-floating-input"
																				type="text"
																				name="displayName"
																				value={tenant.displayName || ""}
																				onChange={(e) => handleChange(index, e)}
																				placeholder="Enter Display Name"
																			/>
																			<label className="did-floating-label">
																				Display Name
																			</label>
																			{tenant.errors.displayName && (
																				<div style={{ color: "red" }}>
																					{tenant.errors.displayName}
																				</div>
																			)}
																		</div>

																		{tenant.showSave && (
																			<button
																				type="button"
																				onClick={() => handleSave(index)}
																				className={`btn-id-unique-${index} SiteBtn2 export-csv-btn btn`}
																			>
																				Save
																			</button>
																		)}
																	</div>
																</form>
															</div>
														</div>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
					</main>
				</div>
			</div>
		</>
	);
};

export default AddWheel;
