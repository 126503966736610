import Cookies from "js-cookie";

//for without authorization token
export const getHeader = () => {
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "mode": "cors",
  };
  return headers;
};

//for with authorization token
export const getAuthenticationHeader = () => {
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + Cookies.get("auth-token"),
    // "Access-Control-Allow-Origin": "*",
    "mode": "cors",
  };
  return headers;
};

//for with authorization token
export const CommongetAuthenticationHeader = () => {
  const headers = {
    "Accept": "multipart/form-data",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + Cookies.get("auth-token"),
    // "Access-Control-Allow-Origin": "*",
    "mode": "cors",
  };
  return headers;
};