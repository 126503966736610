import { createSlice } from "@reduxjs/toolkit";


// Event Slice
const MiscSlice = createSlice({
    name: "misc",
    initialState: {
        appUsers: {
            activeTab: 0
        },
        tenants: {
            activeTab: 0
        },
        eventFeed: {
            activeTab: 0
        },
        notification: {
            activeTab: 0
        },
        adminUsers: {
            activeTab: 0
        },
    },

    // reducers
    reducers: {
        setActiveTab: (state, action) => {
            const { tabOf, activeTab } = action.payload;
            if (!tabOf) return;
            state[tabOf] = {
                ...state[tabOf],
                activeTab: activeTab || 0
            }
        },
    },
});

export const { setActiveTab } = MiscSlice.actions;

export default MiscSlice.reducer;
