import axios from "axios";
import { getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

// Get All Admin Service
export const GetAllAdmin = createAsyncThunk(
  "admin/GetAllAdmin",
  async (args, { rejectWithValue }) => {
    try {
      const { page, limit, search, status, filter } = args;
      const resp = await axios.post(`${url}/admin/get_all_admin`, {
        // "page": page ? page : 1,
        // "limit": limit ? limit : 10,
        "status": status,
        "search" : search,
        "filter": filter,
      }, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Get Single Admin Service
export const GetSingleAdmin = createAsyncThunk(
  "admin/GetSingleAdmin",
  async (args, { rejectWithValue }) => {
    try {
      const { id } = args;
      const resp = await axios.get(`${url}/admin/get_single_admin/${id}/`,
        {
          headers: getAuthenticationHeader(),
        });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Edit Single Admin Service
export const EditAdminData = createAsyncThunk(
  "admin/EditAdminData",
  async (args, { rejectWithValue }) => {
    try {
      const { id, data } = args;
      const resp = await axios.post(`${url}/admin/update_admin/${id}/`, data,
        {
          headers: getAuthenticationHeader(),
        });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);


// Edit Single Admin Service
export const AddNewAdmin = createAsyncThunk(
  "admin/AddNewAdmin",
  async (args, { rejectWithValue }) => {
    try {
      const {data } = args;
      const resp = await axios.post(`${url}/admin/add_admin/`, data,
        {
          headers: getAuthenticationHeader(),
        });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Admin Slice
const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    error: false,
    loading: false,
    allActiveAdmin: [],
    allInactiveAdmin: [],
    singleAdmin: null,
    editAdmin: [],
    newAdmin: [],
  },

  // reducers
  reducers: {
    setAdminPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },

  // extra reducers and addcases
  extraReducers: (builder) => {
    builder

      // get all Admin
      .addCase(GetAllAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.allActiveAdmin = action.payload;
      })
      .addCase(GetAllAdmin.rejected, (state) => {
        state.loading = false;
      })

      // get single admin
      .addCase(GetSingleAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSingleAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.singleAdmin = action?.payload?.data?.length ? action.payload?.data[0] : null ;
      })
      .addCase(GetSingleAdmin.rejected, (state) => {
        state.loading = false;
      })

      // Edit admin
      .addCase(EditAdminData.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditAdminData.fulfilled, (state, action) => {
        state.loading = false;
        state.editAdmin = action.payload;
      })
      .addCase(EditAdminData.rejected, (state) => {
        state.loading = false;
      })

      // Add admin
      .addCase(AddNewAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddNewAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.newAdmin = action.payload;
      })
      .addCase(AddNewAdmin.rejected, (state) => {
        state.loading = false;
      })
  },
});

export const { setAdminPage } = AdminSlice.actions;

export default AdminSlice.reducer;