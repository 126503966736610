import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import { createRoot } from 'react-dom/client';

// createRoot(
//   <Provider store={store}>
//     <Router basename="/">
//       <App />
//     </Router>
//   </Provider>,
//   document.getElementById("root")
// );

createRoot(document.getElementById('root')).render(<Provider store={store}>
  <Router basename="/">
    <App />
  </Router>
</Provider>);

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
