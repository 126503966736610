import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Header from '../Header';
import Sidebar from '../Sidebar';
import InnerHeader from "../InnerHeader";
import { DeleteNotificationService, GetSingleNotificationService, EditNotificationService } from '../../redux/features/NotificationService';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import { useFormik } from 'formik';
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners'; // Importing RingLoader
import { set } from 'lodash';
import { GetAllCategoryService } from "../../redux/features/CategoryService";
import _ from "lodash";

const formatDate = (dateString) => {
    if(dateString == "") {
      return "";
    }
    const date = moment(dateString);
    const pacificTime = date.tz('America/Los_Angeles').format('MM/DD/YYYY');
    return pacificTime;
};

const validate = (values) => {
    const errors = {};
    if (!values?.title) {
        errors.title = "Please enter a title";
    }
    if (!values?.date) {
        errors.date = "Please enter a date";
    }else if(values?.time){
        const currentDateTime = moment().tz('America/Los_Angeles').format("YYYY-MM-DD HH:mm");
        const selectedDateTime = moment(`${moment(values?.date).format('YYYY-MM-DD')} ${values?.time}`).format('YYYY-MM-DD HH:mm');
        const date1 = new Date(currentDateTime);
        const date2 = new Date(selectedDateTime); 
        if((date1 > date2)){
            errors.time = "Please select any future PT time";
        }
    }
   
    if (!values?.time) {
        errors.time = "Please enter a valid time";
    }else if(values?.time){
        const currentDateTime = moment().tz('America/Los_Angeles').format("YYYY-MM-DD HH:mm");
        const selectedDateTime = moment(`${moment(values?.date).format('YYYY-MM-DD')} ${values?.time}`).format('YYYY-MM-DD HH:mm');
        const date1 = new Date(currentDateTime);
        const date2 = new Date(selectedDateTime); 
        if((date1 > date2)){
            errors.time = "Please select any future PT time";
        }
    }
    if (!values?.details) {
        errors.details = "Please enter details";
    }
    if (!values?.mobileAppPage) {
        errors.mobileAppPage = "Please select an option";
    }
    return errors;
};


const SingleNotification = () => {
    const { allCategory } = useSelector((state) => state.category);
    const [isComponentVisible, setComponentVisibility] = useState(false);
    const params = useParams();
    const id = params.id; // user_id
    const [date, setDate] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { singleNotification } = useSelector((state) => state.notification);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            if (id) {
                const res = await dispatch(GetSingleNotificationService({ id }));
                if (res.type === "notification/GetSingleNotificationService/fulfilled") {
                    setIsLoading(false);
                }
            }
        };
        fetchData();
    }, [id]);

    const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

    //API calling
    useEffect(() => {
        const fetchData = async () => {
        try {
            const res = await dispatch(GetAllCategoryService({ status: "1", limit: 10 }));
            if (res.type === "category/GetAllCategoryService/fulfilled") {
            }
            // setLoading(true);
        } catch {
            toast.error("failed to load app users");
        }
        }
        fetchData();
    }, []); // Set loading to true when fetching data


    const formik = useFormik({
        initialValues: {
            title: singleNotification && singleNotification.length > 0 ? singleNotification[0].title : "",
            date: singleNotification && singleNotification.length > 0 ? singleNotification[0].date : "",
            time: singleNotification && singleNotification.length > 0 ? moment(singleNotification[0].time, "HH:mm").format("HH:mm") : "",
            details: singleNotification && singleNotification.length > 0 ? singleNotification[0].details : "",
            mobileAppPage: singleNotification && singleNotification.length > 0 ? singleNotification[0].mobileAppPage : "",
        },
        enableReinitialize: true,
        validate,
        onSubmit: async (values) => {
            const response = await dispatch(EditNotificationService({ id, data: values }));
            if (response.type === "notification/EditNotificationService/fulfilled") {
                setComponentVisibility(false);
                toast.success("Notification Details Updated Successfully", { autoClose: 3000 });
                await dispatch(GetSingleNotificationService({ id }));
            } else if (response.type === "notification/EditNotificationService/rejected") {
                toast.error(response?.payload?.response?.data, { autoClose: 3000 });
            }
            // setIsLoading(false);
        },
    });

    const handleDeleteNotification = async () => {
        const response = await dispatch(DeleteNotificationService({ id }));
        if (response.type === "notification/DeleteNotificationService/fulfilled") {
            navigate(`/notifications`);
            toast.success("Scheduled notification successfully deleted.");
        } else {
            toast.error("Delete failed, please try again.");
        }
    };

    useEffect(() => {
        const currentDate = moment().format('YYYY-MM-DD');
        const currentTime = moment().format('HH:mm') + " PT";
        const dateInput = document.getElementById('dateInput');
        if (dateInput) {
            dateInput.min = currentDate;
            dateInput.addEventListener('input', function () {
                if (this.value < currentDate) {
                    this.value = currentDate;
                }
            });
        }
        const timeInput = document.getElementById('timeInput');
        if (timeInput) {
            timeInput.min = currentTime;
            timeInput.addEventListener('input', function () {
                if (date === currentDate && this.value < currentTime) {
                    this.value = currentTime;
                }
            });
        }
    }, [date]);

    return (
        <div>
            <div>
                <div className="w-100 h-100 ">
                    <Header />
                    <div className="d-flex">
                        <Sidebar />
                        <main className="w-100 MainSection">

                            {/* Inner Header Starts */}
                            <InnerHeader text={singleNotification?.length && singleNotification[0]?.title} arrow={true}>
                                <div></div>
                            </InnerHeader>

                            {/* Display App user data */}
                            {/* {loading && <Loader />} */}
                            <div className="conten-div">
                                <div className="single-admin-data Single-Notification">
                                    <div className="d-flex justify-content-between w-100 mb-4 ">
                                        <h2 className='BoxTitle'>Notification Details</h2>

                                        {/* Edit and  save button */}
                                        {isComponentVisible ?
                                            <div>
                                                <button onClick={formik.handleSubmit} className="SiteBtn2 export-csv-btn btn">
                                                    Save
                                                </button>
                                            </div> :
                                            <>
                                                <div>
                                                    <button onClick={handleDeleteNotification} className="DeleteBtn export-csv-btn btn me-2">
                                                        Delete
                                                    </button>
                                                    <button onClick={(e) => { e.preventDefault(); setComponentVisibility(true) }} className="SiteBtn export-csv-btn btn">
                                                        Edit
                                                    </button>
                                                </div>
                                            </>

                                        }
                                    </div>

                                    {isLoading ? (
                                        <div className="loader-container m-auto">
                                            <RingLoader css={override} size={50} color={"#00639B"} loading={isLoading} />
                                        </div>
                                    ) : (
                                        <>
                                            {/* Edit and Display Design */}
                                            {isComponentVisible ?
                                                <div className=''>
                                                    <form onSubmit={formik.handleSubmit}>
                                                        <div className="">
                                                            <div className="did-floating-label-content">
                                                                <input className="did-floating-input" name='title' type="text"
                                                                    onChange={formik?.handleChange}
                                                                    value={formik?.values?.title}
                                                                    placeholder=" " />
                                                                <label className="did-floating-label">Title</label>
                                                                {formik?.touched?.title && formik?.errors?.title ? (
                                                                    <div className="error">{formik?.errors?.title}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="did-floating-label-content">
                                                                <input className="did-floating-input" type="date"
                                                                    id="dateInput"
                                                                    name='date'
                                                                    value={formik?.values?.date.split('T')[0]}
                                                                    min={moment().tz('America/Los_Angeles').format("YYYY-MM-DD")}
                                                                    onChange={formik?.handleChange}
                                                                    placeholder=" " />
                                                                <label className="did-floating-label">Date</label>
                                                                {formik?.touched?.date && formik?.errors?.date ? (
                                                                    <div className="error">{formik?.errors?.date}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="did-floating-label-content">
                                                                <input className="did-floating-input" type="time"
                                                                    id="timeInput"
                                                                    name='time'
                                                                    // value={formik?.values?.time}
                                                                    value={moment(formik?.values?.time, "HH:mm").format("HH:mm")}
                                                                    onChange={formik?.handleChange}
                                                                    placeholder=" " />
                                                                <label className="did-floating-label">Time</label>
                                                                {formik?.touched?.time && formik?.errors?.time ? (
                                                                    <div className="error">{formik?.errors?.time}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="did-floating-label-content">
                                                                <input className="did-floating-input" type="text"
                                                                    onChange={formik?.handleChange}
                                                                    name='details'
                                                                    defaultValue={formik?.values?.details}
                                                                    placeholder=" " />
                                                                <label className="did-floating-label">Details</label>
                                                                {formik?.touched?.details && formik?.errors?.details ? (
                                                                    <div className="error">{formik?.errors?.details}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="did-floating-label-content">
                                                                <select className="did-floating-select"
                                                                    name='mobileAppPage'
                                                                    onChange={formik?.handleChange}>
                                                                    {/* <option disabled selected value="Select">Select</option>     */}
                                                                    <option hidden selected value={formik?.values?.mobileAppPage}>{formik?.values?.mobileAppPage}</option>
                                                                    <option value="">Search by Page Link </option>
                                                                    <option value="Home Page">Home Page</option>
                                                                    <option value="Directory Page">Directory Page</option>
                                                                    <option value="Featured Events Page">Featured Events Page</option>
                                                                    <option value="Profile Page">Profile Page</option>
                                                                    {allCategory?.data?.records.map((category, index) => {
                                                                        return (
                                                                        <option value={category.categoryType}>{category.categoryType}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                                <label className="did-floating-label">Mobile App Page</label>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                :
                                                <>
                                                {!isLoading && (
                                                    <div className=''>
                                                        <div className="ms-3">
                                                            <div className="single-username mb-4">
                                                                <div className='single-user-p-main'>Title</div>
                                                                <div className='single-user-p-desc'>
                                                                    {singleNotification?.length && singleNotification[0]?.title}
                                                                </div>
                                                            </div>
                                                            <div className="single-user-notification mb-4">
                                                                <div className='single-user-p-main'>Date</div>
                                                                <div className='single-user-p-desc'>

                                                                    {singleNotification?.length && formatDate(singleNotification[0]?.date)}
                                                                </div>
                                                            </div>
                                                            <div className="single-user-notification mb-4">
                                                                <div className='single-user-p-main'>Time</div>
                                                                <div className='single-user-p-desc'>
                                                                    {singleNotification?.length && moment(singleNotification[0]?.time, "hh:mm A").format("hh:mm A") + " PT"}
                                                                </div>
                                                            </div>
                                                            <div className="single-user-notification mb-4">
                                                                <div className='single-user-p-main'>Details</div>
                                                                <div className='single-user-p-desc'>
                                                                    {singleNotification?.length && singleNotification[0]?.details}
                                                                </div>
                                                            </div>
                                                            <div className="single-user-notification mb-4">
                                                                <div className='single-user-p-main'>Mobile App Page</div>
                                                                <div className='single-user-p-desc'>
                                                                    {singleNotification?.length && singleNotification[0]?.mobileAppPage}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                </>
                                            }
                                        </>
                                    )}

                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default SingleNotification;