import React, { useState, useEffect } from 'react';
import Header from '../Header';
import InnerHeader from '../InnerHeader';
import Sidebar from '../Sidebar';
import { DeleteEventService, EditEventService, GetSingleEventService } from '../../redux/features/EventFeedService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { UploadMedia } from '../../redux/features/CommonFileService';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners'; // Importing RingLoader
import defaultImg from '../../images/tenatPrimaryImg.png';
import validator from 'validator';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const SingleEvent = () => {
  const [isComponentVisible, setComponentVisibility] = useState(false);
  const [isVisibleDelete, setsVisibleDelete] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { singleEvent } = useSelector((state) => state.event);
  const params = useParams();
  const id = params.id; //user_id
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = () => {
    EditEventDetails();
  };

  const [eventType, setEventType] = useState("");
  const [eventName, setEventName] = useState("");
  const [date, setDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [eventTime, setEventTime] = useState("");
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [venue, setVenue] = useState("");
  const [description, setDescription] = useState("");
  const [flyerLink, setFlyerLink] = useState("");
  const [ticketLink, setTicketLink] = useState("");
  const [etype, setEtype] = useState("");
  const [show, setShow] = useState(false);
  const [saveShow, setSaveShow] = useState(false);
  const [responseMessageError, setResponseMessageError] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [fileError, setFileError] = useState('');
  const [isAPIShow, setAPIShow] = useState(false);

  const url = process.env.REACT_APP_BACKEND_URL;
  // console.log(singleEvent);

  const isValidUrl = (url) =>  {
    try {
      // Regular expression for a URL
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3})|" + // OR IP (v4) address
          "localhost|" + // OR localhost
          "([a-zA-Z\\d-]+\\.([a-zA-Z]{2,}))\\.?)" + // subdomain
          "(:\\d+)?(\\/[-a-zA-Z\\d%@_.~+&:]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
          "(#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
  
      return pattern.test(url);
    } catch (error) {
      return false;
    }
  }

  const handleClose = () => {
    setShow(false)
    navigate("/events");
  };
  const handleShow = () => setShow(true);

  const handleSaveClose = () => {
    setSaveShow(false);
  }
  const handleSaveShow = () => setSaveShow(true);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setIsLoading(true);
        const res = await dispatch(GetSingleEventService({ id }));
        if (res.type === "event/GetSingleEventService/fulfilled") {
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id])

  useEffect(() => {
    const initDate = moment(singleEvent?.date).format("YYYY-MM-DD");
    const initStartTime = moment(`${initDate} ${singleEvent?.startTime}`, "YYYY-MM-DD HH:mm");
    const initEndTime = moment(`${initDate} ${singleEvent?.endTime}`, "YYYY-MM-DD HH:mm");
    console.log('Load Date:', singleEvent, initDate);
    setStartTime(isComponentVisible ? moment(initStartTime, "h:mm A") : startTime);
    setEndTime(isComponentVisible ? moment(initEndTime, "h:mm A") : endTime);
  }, [isComponentVisible])

  const handleValidation = () => {
    const newErrors = {};
    let isValid = true;
    // Validate flyerLink
    // if (!flyerLink.trim()) {
    //   newErrors.flyerLink = "Please enter a link";
    // } else if (!isValidUrl(flyerLink.trim())) {
    //     newErrors.flyerLink = "Please enter a valid URL";
    // }

    // // Validate ticketLink
    // if (!ticketLink.trim()) {
    //   newErrors.ticketLink = "Please enter a link";
    // } else if (!isValidUrl(ticketLink.trim())) {
    //     newErrors.ticketLink = "Please enter a valid URL";
    // }

    if (!eventType.trim()) {
      newErrors.eventType = "Please select an option";
    }

    if (!eventName.trim()) {
      newErrors.eventName = "Please enter a name";
    }

    if (!date.trim()) {
      newErrors.date = "Please enter a date";
    } else if(startTime){
      const currentDateTime = moment().format("YYYY-MM-DD HH:mm");
      const selectedDateTime = moment(`${moment(date).format('YYYY-MM-DD')} ${moment(startTime).format('HH:mm')}`).format('YYYY-MM-DD HH:mm');
      const date1 = new Date(currentDateTime);
      const date2 = new Date(selectedDateTime);
      if((date1 > date2)){
        newErrors.startTime = 'Please select any future time';
        isValid = false;
      }
    }

    if (endDate) {
      const selectedStartDate = moment(`${moment(date).format('YYYY-MM-DD')}`).format("YYYY-MM-DD");
      const selectedEndDate = moment(`${moment(endDate).format('YYYY-MM-DD')}`).format('YYYY-MM-DD');
      const date1 = new Date(selectedStartDate);
      const date2 = new Date(selectedEndDate);
      if((date1 > date2)){
        newErrors.endDate = 'Please select any future end date from selected start date';
        isValid = false;
      }
    }

    if (!startTime) {
      newErrors.startTime = "Please enter a start time";
      isValid = false;
    } else if(startTime){
      const currentDateTime = moment().format("YYYY-MM-DD HH:mm");
      const selectedDateTime = moment(`${moment(date).format('YYYY-MM-DD')} ${moment(startTime).format('HH:mm')}`).format('YYYY-MM-DD HH:mm');
      const date1 = new Date(currentDateTime);
      const date2 = new Date(selectedDateTime);
      if((date1 > date2)){
        newErrors.startTime = 'Please select any future time';
        isValid = false;
      }
    }

    if (!endTime) {
      newErrors.endTime = "Please enter an end time";
      isValid = false;
    } else if (endTime <= startTime) {
      newErrors.endTime = "End time must be after start time";
      isValid = false;
    }

    if (!venue.trim()) {
      newErrors.venue = "Please enter a location";
    }

    if (!description.trim()) {
      newErrors.description = "Please enter a description";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getMinTime = (isStartTime) => {
    const currentDate = new Date();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();

    if (!isStartTime) {
      minutes += 1;
    }

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${minutes}`;
  };

  const EditEventDetails = async (e) => {
    e.preventDefault();
    const newErrors = {};
    let isValid = true;

    // Call validation function
    isValid = handleValidation();

    if (isValid) {
      const data = {
        eventType: eventType,
        eventName: eventName,
        date: moment(date).toISOString(),
        endDate: moment(endDate).toISOString(),
        startTime: moment(startTime, "HH:mm").format("h:mm A"),
        endTime: moment(endTime, "HH:mm").format("h:mm A"),
        venue: venue,
        description: description,
        flyerLink: flyerLink,
        ticketLink: ticketLink,
      };

      const response = await dispatch(EditEventService({ id: id, data: data }));
      if (response.type === "event/EditEventService/fulfilled") {
        await dispatch(GetSingleEventService({ id }));
        setAPIShow(false)
        setComponentVisibility(false);
        handleSaveShow();
        setResponseMessage(response?.payload?.message, { autoClose: 3000 });
      } else {
        toast.error(response?.payload?.response?.data?.message, { autoClose: 3000 });
      }
    } else {
      // console.log('Form has validation errors. Please fix them.');
    }
  };

  useEffect(() => {
    if (id && singleEvent && !isAPIShow) {
      setEventType(singleEvent?.eventType);
      setEventName(singleEvent?.eventName);
      setDate(singleEvent?.date);
      setEndDate(singleEvent?.endDate);
      setVenue(singleEvent?.venue);
      setDescription(singleEvent?.description);
      setFlyerLink(singleEvent?.flyerLink);
      setTicketLink(singleEvent?.ticketLink);
      if (singleEvent?.eventType === "1") {
        setEtype("Brea");
      } else if (singleEvent?.eventType === "2") {
        setEtype("Tenants");
      } else {
        setEtype("Daily Happening");
      }
    }
  }, [id, singleEvent, isAPIShow]);

  // console.log(singleEvent);

  const handleFileChange = async (file) => {
    if (file) {
      const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']; // Allowed file types
      const selectedFileType = file?.currentTarget?.files[0]?.type;

      if (!allowedFileTypes.includes(selectedFileType)) {
        // console.error('Unsupported file type. Please select a PNG or PDF file.');
        setFileError('Please upload a file with .png, .jpg, .jpeg and .pdf extension')
        return; // Exit the function if file type is not supported
      }
      setFileError()
      const formData = new FormData();
      formData.append("files", file?.currentTarget?.files[0]);
      const res = await dispatch(UploadMedia(formData));
      // console.log(eventName);
      if (res.type === "common/UploadMedia/fulfilled") {
        // debugger
        const data = {
          eventImage: res?.payload?.files[0]?.mediaName,
          // eventName: eventName,
        };
        toast.success("File uploaded successfully");
        setAPIShow(true)
        const response = await dispatch(EditEventService({ id: id, data: data }));
        // dispatch(GetSingleEventService({ id: id }));
        return res?.payload?.files[0];
      }
    }
  };


  const DeleteEvent = async () => {
    const res = await dispatch(DeleteEventService({ id: id }));
    if (res.type === "event/DeleteEventService/fulfilled") {
      handleShow();
      setResponseMessageError(res?.payload?.message);
    } else {
      toast.error(res?.payload?.response?.data?.message);
    }
  };

  const getMinEndDate = () => {
    if (date) {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      return newDate.toISOString().split("T")[0];
    }
    return new Date().toISOString().split("T")[0];
  };

  return (
    <>
      <div className="w-100 h-100 ">
        <Header />
        <div className="d-flex position-relative">
          <Sidebar />
          <div className="MainSection">
            {/* {/ Inner Header Starts /} */}
            <InnerHeader
              text={`Events / ${singleEvent?.eventName}`}
              arrow={true}
            >
              <div></div>
            </InnerHeader>

              <div className="conten-div single-tenant-data-tbl ">
                {/* {/ Tenant Images /} */}
                <div className="tenant-img-ui">
                  <div className="d-flex justify-content-between w-100">
                    <h2 className="BoxTitle">Event Image</h2>
                    {isComponentVisible ? (
                      <button disabled
                        onClick={() => {
                          setIsEditMode(true);
                        }}
                        className="SiteBtn2 export-csv-btn btn">
                        Delete
                      </button>
                    ) :
                      (<button
                        onClick={DeleteEvent}
                        className="SiteBtn2 export-csv-btn btn">
                        Delete
                      </button>)}
                  </div>
                  {isLoading ? (
                    <div className="loader-container m-auto">
                      <RingLoader css={override} size={50} color={"#00639B"} loading={isLoading} />
                    </div>
                  ) : (
                  <div className="img-tenant-row">
                    <div className="">
                      <img className="tenant-img"
                        src={(singleEvent?.eventImage === "" || singleEvent?.eventImage === undefined) ? defaultImg : process.env.REACT_APP_BACKEND_URL + 'uploads/' + singleEvent?.eventImage}
                        alt="" />
                      <div className='add-img-tenant'>
                        <input type="file" accept='.png, .pdf, .jpeg, .jpg' onChange={handleFileChange} name="uploadfile" id="img" style={{ display: "none" }} />
                        <label htmlFor="img" className='m-0'>Event Image</label>
                      </div>
                      {fileError && <span className="validation-error-message">{fileError}</span>}
                    </div>
                  </div>
                  )}
                </div>
              </div>

            <div className="conten-div">
              <div className="single-admin-data">
                <div className="d-flex justify-content-between w-100">
                  <h2 className='mb-4 BoxTitle'>About</h2>

                  {/* Edit and  save buttom*/}
                  {isComponentVisible ?
                    <div>
                      <button onClick={EditEventDetails} className="SiteBtn2 export-csv-btn btn">
                        Save
                      </button>
                    </div>
                    : <div>
                      <button onClick={(e) => { e.preventDefault(); setComponentVisibility(true) }} className="SiteBtn export-csv-btn btn">
                        Edit
                      </button>
                    </div>
                  }
                </div>
                {isLoading ? (
                  <div className="loader-container m-auto">
                    <RingLoader css={override} size={50} color={"#00639B"} loading={isLoading} />
                  </div>
                ) : (
                  <>
                    {/* {/ Edit and Display Design /} */}
                    {isComponentVisible ? (
                      <div className=''>
                        <form onSubmit={onSubmit} encType='multipart/form-data'>
                          <div className="">

                            <div className="did-floating-label-content">
                              <select
                                className="did-floating-select"
                                defaultValue={singleEvent?.eventType}
                                name='eventType'
                                onChange={(e) => {
                                  setEventType(e.target.value);
                                }}
                                onBlur={handleValidation}
                              >
                                <option disabled value="Select">
                                  Select Event Type
                                </option>
                                {/* {/ <option value={singleEvent?.length && singleEvent[0]?.mobileAppPage}>{singleEvent?.length && singleEvent[0]?.mobileAppPage}</option> /} */}
                                <option value="1">Brea</option>
                                <option value="2">Tenants</option>
                              </select>
                              <label className="did-floating-label">
                                Event Type
                              </label>
                              {errors.eventType && <span className="validation-error-message">{errors.eventType}</span>}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                type="text"
                                name='eventName'
                                defaultValue={singleEvent?.eventName}
                                onChange={(e) => {
                                  setEventName(e.target.value);
                                }}
                                onBlur={handleValidation}
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Event Name
                              </label>
                              {errors.eventName && <span className="validation-error-message">{errors.eventName}</span>}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                type="date"
                                name='date'
                                defaultValue={moment(singleEvent?.date).tz('America/Los_Angeles').format("YYYY-MM-DD")}
                                onChange={(e) => {
                                  console.log('e.target.value', e.target.value);
                                  setDate(e.target.value);
                                }}
                                // onBlur={handleValidation}
                                min={new Date().toISOString().split("T")[0]}
                                placeholder=" "
                              />
                              <label className="did-floating-label">Start Date </label>
                              {errors.date && <span className="validation-error-message">{errors.date}</span>}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                type="date"
                                name='endDate'
                                defaultValue={moment(singleEvent?.endDate).tz('America/Los_Angeles').format("YYYY-MM-DD")}
                                onChange={(e) => {
                                  console.log('e.target.value', e.target.value);
                                  setEndDate(e.target.value);
                                }}
                                // onBlur={handleValidation}
                                min={getMinEndDate()}
                                placeholder=" "
                              />
                              <label className="did-floating-label">End Date</label>
                              {errors.endDate && <span className="validation-error-message">{errors.endDate}</span>}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                type="time"
                                name='startTime'
                                value={startTime ? startTime.format("HH:mm") : ""}
                                onChange={(e) =>
                                  setStartTime(moment(e.target.value, "HH:mm"))
                                }
                                min={getMinTime(true)}
                                placeholder=" "
                                pattern="[0-9]{2}:[0-9]{2}"
                              />
                              <label className="did-floating-label">Time</label>
                              {errors.startTime && <span className="validation-error-message">{errors.startTime}</span>}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                type="time"
                                name='endTime'
                                value={endTime ? endTime.format("HH:mm") : ""}
                                onChange={(e) =>
                                  setEndTime(moment(e.target.value, "HH:mm"))
                                }
                                min={getMinTime(false)}
                                placeholder=" "
                                pattern="[0-9]{2}:[0-9]{2}"
                              />
                              <label className="did-floating-label">Time</label>
                              {errors.endTime && <span className="validation-error-message">{errors.endTime}</span>}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                type="text"
                                name='venue'
                                defaultValue={singleEvent?.venue}
                                onChange={(e) => {
                                  setVenue(e.target.value);
                                }}
                                onBlur={handleValidation}
                                placeholder=" "
                                disabled={!startTime}
                              />
                              <label className="did-floating-label">Venue</label>
                              {errors.venue && <span className="validation-error-message">{errors.venue}</span>}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                type="text"
                                name='description'
                                defaultValue={singleEvent?.description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                                onBlur={handleValidation}
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Description
                              </label>
                              {errors.description && <span className="validation-error-message">{errors.description}</span>}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                type="url"
                                name='flyerLink'
                                defaultValue={singleEvent?.flyerLink}
                                onChange={(e) => {
                                  setFlyerLink(e.target.value);
                                }}
                                onBlur={handleValidation} // Call validation function onBlur
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Flyer Link
                              </label>
                              {errors.flyerLink && <span className="validation-error-message">{errors.flyerLink}</span>}
                            </div>

                            <div className="did-floating-label-content">
                              <input
                                className="did-floating-input"
                                name='ticketLink'
                                type="url"
                                defaultValue={singleEvent?.ticketLink}
                                onChange={(e) => {
                                  setTicketLink(e.target.value);
                                }}
                                onBlur={handleValidation} // Call validation function onBlur
                                placeholder=" "
                              />
                              <label className="did-floating-label">
                                Ticket Link
                              </label>
                              {errors.ticketLink && <span className="validation-error-message">{errors.ticketLink}</span>}
                            </div>
                          </div>

                        </form>
                      </div>
                    ) : (
                      <div className="ms-3">

                        <div className="single-username mb-4">
                          <div className="single-user-p-main">Event Type</div>
                          <div className="single-user-p-desc">{etype}</div>
                        </div>

                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Event Name</div>
                          <div className="single-user-p-desc">
                            {singleEvent?.eventName}
                          </div>
                        </div>

                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Date(s)</div>
                          <div className="single-user-p-desc">
                            {/* {/ {singleEvent?.date} /} */}
                            {/* {moment(singleEvent?.date).format('MMMM Do, YYYY') ((singleEvent?.endDate) ? +'-' +moment(singleEvent?.endDate).format('MMMM Do, YYYY') : '')} */}
                            {moment(singleEvent.date).tz('America/Los_Angeles').format('MMMM Do, YYYY') +`${singleEvent.endDate ? ` to ${moment(singleEvent.endDate).tz('America/Los_Angeles').format('MMMM Do, YYYY')}` : ''}`}
                          </div>
                        </div>
                      {/* {singleEvent?.endDate ? 
                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">End Date</div>
                          <div className="single-user-p-desc">
                            {moment(singleEvent?.endDate).format('MMMM Do, YYYY')}
                          </div>
                        </div>
                        :
                        <></>
                      } */}

                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Time</div>
                          <div className="single-user-p-desc">
                            {singleEvent?.startTime + ' to ' + singleEvent?.endTime}
                          </div>
                        </div>

                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Venue</div>
                          <div className="single-user-p-desc">
                            {singleEvent?.venue}
                          </div>
                        </div>

                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Description </div>
                          <div className="single-user-p-desc">
                            {singleEvent?.description}
                          </div>
                        </div>

                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Flyer Link</div>
                          <div className="single-user-p-desc">
                            {singleEvent?.flyerLink}
                          </div>
                        </div>

                        <div className="single-user-notification mb-4">
                          <div className="single-user-p-main">Ticket Link</div>
                          <div className="single-user-p-desc">
                            {singleEvent?.ticketLink}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div >
      </div >
      <Modal
        show={saveShow}
        onHide={handleSaveClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "0px solid white" }} >
          {/* <Modal.Title >Save Event</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className='modal-body-text'>
          {responseMessage}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "0px solid white" }}>
          <Button className="modal-btn-close my-auto" onClick={handleSaveClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "0px solid white" }} >
        </Modal.Header>
        <Modal.Body className='modal-body-text' >
          {responseMessageError}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "0px solid white" }}>
          <Button className="modal-btn-close my-auto" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  );
};

export default SingleEvent;
