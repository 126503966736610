import React, { useEffect, useState } from "react";
import MainCenterBox from "../MainCenterBox";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { CheckTokenExpireationService, ResetPasswordService, SignInService } from "../../redux/features/AuthService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../Common/Button";
import Cookies from "js-cookie";
import Modal from "react-bootstrap/Modal";
import Loader from "../Common/Loader";

const validate = (values) => {
  const errors = {};
  const regex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})$/
  if (!values.newPassword) {
    errors.newPassword = "*Please enter your new password";
  } else if (!regex.test(values.newPassword)
  ) {
    errors.newPassword =
      "*Please make sure the password meets all of the criteria below.";
  }
  return errors;
};

const ResetPasswordToken = () => {
  document.title = "Set Password | Brea Downtown";
  const [show, setShow] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessageError, setResponseMessageError] = useState("");
  const [isExpired, setIsExpired] = useState();
  const { id } = useParams();
  const Appurl = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleShow = () => setShow(true);
  const handleErrorClose = () => setShowError(false);
  const handleErrorShow = () => setShowError(true);

  const handleClose = () => {
    setShow(false);
    window.location.replace(`${Appurl}` + "/app-users");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    const res = await dispatch(CheckTokenExpireationService({ token: id }));
    setIsExpired(res.payload?.response?.data?.status === 0);
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
    },
    validate,
    onSubmit: async (values) => {
      const response = await dispatch(
        ResetPasswordService({ data: values, token: id })
      );
      // console.log(response);
      if (response.type === "auth/ResetPasswordService/fulfilled") {
        handleShow();
        // let signInEmail = Cookies.get("signin-email-bd");
        const data = {
          email: response?.payload?.email,
          password: values.newPassword,
        };
        // console.log(data);
        const res = await dispatch(SignInService(data));
        if (res.type === "auth/SignInService/fulfilled") {
          Cookies.set("breadowntown-email-login", data.email, {
            expires: 36400,
          });
          Cookies.set("auth-token", res?.payload?.token);
          Cookies.set("username", res?.payload?.fullName);
          // toast.success(response?.payload?.message, { autoClose: 3000 });
        } else {
          if (res?.payload?.response?.status === 403) {
            setResponseMessageError(res?.payload?.response?.data?.error);
            handleErrorShow();
            window.location.reload();
            Cookies.remove("signin-email-bd");
          } else {

          }
        }
      } else {
        toast.error(response?.payload?.response?.data?.message, {
          autoClose: 3000,
        });
      }
    },
  });

  return (
    <>
      {isExpired === undefined ? (
        <div> {loading && <Loader />} </div>
      ) :
        isExpired ? (
          <div className="pagenotfound">
            <div
              className="404notfound"
              style={{ fontFamily: "Roboto", position: "relative", top: "10%" }}
            >
              <p className="text-404">!Ooops...</p>
              <p className="text-pagenotfound my-4">
                Token has been expire or invalid
              </p>
              <p className="text-dess my-4">
                <a href="/reset-password">
                  <u>Go back to reset password</u>
                </a>
              </p>
            </div>
          </div>
        ) : (
          // Render the main content when token is valid
          <MainCenterBox
            title={"Set New Password"}
            className="col-lg-4 col-md-8  m-auto"
          >
            {/* <div className="text-center fw-medium pb-4">Pick something creative yet memorable.</div> */}
            <form onSubmit={formik.handleSubmit}>
              {/* <div className="main_form_box d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="21"
                  viewBox="0 0 16 21"
                  fill="none"
                >
                  <path
                    d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM5 7V5C5 3.34 6.34 2 8 2C9.66 2 11 3.34 11 5V7H5Z"
                    fill="#42474E"
                  />
                </svg>
                <div className="form-group d-flex align-items-center">
                  <input
                    type="password"
                    className="input-field"
                    name="newPassword"
                    id="newPassword"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    value={formik.values.newPassword}
                  />
                </div>
              </div> */}
               <div className="main_form_box d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="21"
                  viewBox="0 0 16 21"
                  fill="none"
                >
                  <path
                    d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM5 7V5C5 3.34 6.34 2 8 2C9.66 2 11 3.34 11 5V7H5Z"
                    fill="#42474E"
                  />
                </svg>
                <div className="form-group d-flex align-items-center">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className="input-field"
                    name="newPassword"
                    id="newPassword"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newPassword}
                  />
                  {passwordVisible ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={togglePasswordVisibility}
                    >
                      <path
                        d="M11.825 16.75C13.0083 16.75 14.0125 16.3375 14.8375 15.5125C15.6625 14.6875 16.075 13.6833 16.075 12.5C16.075 11.3167 15.6625 10.3125 14.8375 9.4875C14.0125 8.6625 13.0083 8.25 11.825 8.25C10.6417 8.25 9.6375 8.6625 8.8125 9.4875C7.9875 10.3125 7.575 11.3167 7.575 12.5C7.575 13.6833 7.9875 14.6875 8.8125 15.5125C9.6375 16.3375 10.6417 16.75 11.825 16.75ZM11.825 15.3C11.0417 15.3 10.3792 15.0292 9.8375 14.4875C9.29583 13.9458 9.025 13.2833 9.025 12.5C9.025 11.7167 9.29583 11.0542 9.8375 10.5125C10.3792 9.97083 11.0417 9.7 11.825 9.7C12.6083 9.7 13.2708 9.97083 13.8125 10.5125C14.3542 11.0542 14.625 11.7167 14.625 12.5C14.625 13.2833 14.3542 13.9458 13.8125 14.4875C13.2708 15.0292 12.6083 15.3 11.825 15.3ZM11.825 20C9.525 20 7.42917 19.375 5.5375 18.125C3.64583 16.875 2.18333 15.225 1.15 13.175C1.1 13.0917 1.0625 12.9917 1.0375 12.875C1.0125 12.7583 1 12.6333 1 12.5C1 12.3667 1.0125 12.2417 1.0375 12.125C1.0625 12.0083 1.1 11.9083 1.15 11.825C2.18333 9.775 3.64583 8.125 5.5375 6.875C7.42917 5.625 9.525 5 11.825 5C14.125 5 16.2208 5.625 18.1125 6.875C20.0042 8.125 21.4667 9.775 22.5 11.825C22.55 11.9083 22.5875 12.0083 22.6125 12.125C22.6375 12.2417 22.65 12.3667 22.65 12.5C22.65 12.6333 22.6375 12.7583 22.6125 12.875C22.5875 12.9917 22.55 13.0917 22.5 13.175C21.4667 15.225 20.0042 16.875 18.1125 18.125C16.2208 19.375 14.125 20 11.825 20Z"
                        fill="#44464E"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="17"
                      viewBox="0 0 22 17"
                      fill="none"
                      onClick={togglePasswordVisibility}
                    >
                      <path
                        d="M10.825 12.75C12.0083 12.75 13.0125 12.3375 13.8375 11.5125C14.6625 10.6875 15.075 9.68333 15.075 8.5C15.075 7.31667 14.6625 6.3125 13.8375 5.4875C13.0125 4.6625 12.0083 4.25 10.825 4.25C9.64167 4.25 8.6375 4.6625 7.8125 5.4875C6.9875 6.3125 6.575 7.31667 6.575 8.5C6.575 9.68333 6.9875 10.6875 7.8125 11.5125C8.6375 12.3375 9.64167 12.75 10.825 12.75ZM10.825 11.3C10.0417 11.3 9.37917 11.0292 8.8375 10.4875C8.29583 9.94583 8.025 9.28333 8.025 8.5C8.025 7.71667 8.29583 7.05417 8.8375 6.5125C9.37917 5.97083 10.0417 5.7 10.825 5.7C11.6083 5.7 12.2708 5.97083 12.8125 6.5125C13.3542 7.05417 13.625 7.71667 13.625 8.5C13.625 9.28333 13.3542 9.94583 12.8125 10.4875C12.2708 11.0292 11.6083 11.3 10.825 11.3ZM10.825 16C8.525 16 6.42917 15.375 4.5375 14.125C2.64583 12.875 1.18333 11.225 0.15 9.175C0.1 9.09167 0.0625 8.99167 0.0375 8.875C0.0125 8.75833 0 8.63333 0 8.5C0 8.36667 0.0125 8.24167 0.0375 8.125C0.0625 8.00833 0.1 7.90833 0.15 7.825C1.18333 5.775 2.64583 4.125 4.5375 2.875C6.42917 1.625 8.525 1 10.825 1C13.125 1 15.2208 1.625 17.1125 2.875C19.0042 4.125 20.4667 5.775 21.5 7.825C21.55 7.90833 21.5875 8.00833 21.6125 8.125C21.6375 8.24167 21.65 8.36667 21.65 8.5C21.65 8.63333 21.6375 8.75833 21.6125 8.875C21.5875 8.99167 21.55 9.09167 21.5 9.175C20.4667 11.225 19.0042 12.875 17.1125 14.125C15.2208 15.375 13.125 16 10.825 16Z"
                        fill="#44464E"
                      />
                      <path
                        d="M3 16L19 1"
                        stroke="#44464E"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  )}
                </div>
              </div>
              {formik.errors.newPassword ? (
                <div className="error">{formik.errors.newPassword}</div>
              ) : null}
              <div className="pwdreqments mt-3">At least 1 lowercase letter</div>
              <div className="pwdreqments ">At least 1 UPPERCASE letter</div>
              <div className="pwdreqments ">At least 1 number</div>
              <div className="pwdreqments mb-3">At least 6 characters</div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <div
                  className="link_pasword"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/sign-in")}
                >
                  Back
                </div>
                <Button type="submit" className="cust-btn" text="Set Password" />
              </div>
            </form>
          </MainCenterBox>
        )}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "0px solid white" }}>
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="modal-body-text">
          Your new password has been set.
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "0px solid white" }}>
          <Button
            className="modal-btn-close my-auto"
            onClick={() => {
              handleClose();
            }}
            text="OK"
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showError}
        onHide={handleErrorClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          style={{ borderBottom: "0px solid white" }}
        ></Modal.Header>
        <Modal.Body className="modal-body-text">
          {responseMessageError}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "0px solid white" }}>
          <Button
            className="modal-btn-close my-auto"
            onClick={handleErrorClose}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResetPasswordToken;
