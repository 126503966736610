import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import InnerHeader from "./InnerHeader";

const SystemSettings = () => {
  const [isVisible, setisVisible] = useState(false);
  const [saveDisable, setSaveDisable] = useState(true); // Change saveDisable to true by default
  const [adminEmail, setAdminEmail] = useState("");
  const [errors, setErrors] = useState("");

  document.title = "Brea downtown | System Settings";

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setAdminEmail(email); // Update adminEmail state
    validate(email); // Validate email whenever it changes
  };

  const handleEmailClick = (e) => {
    e.preventDefault();

    if (validate(adminEmail)) {
      window.location.href = `mailto:${adminEmail}`;
    }
  };

  const validate = (email) => {
    let isValid = true;
    const newErrors = {
      adminEmail: "",
    };

    // Validate Admin Email
    if (!email.trim()) {
      newErrors.adminEmail = "Please enter an email address";
      isValid = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      newErrors.adminEmail = "*Please type in a valid email";
      isValid = false;
    }

    setErrors(newErrors);
    setSaveDisable(!isValid); // Disable save button if email is not valid
    return isValid;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate(adminEmail)) {
      setSaveDisable(false);
      // Add any additional logic for form submission if needed
    }
  };

  return (
    <>
      <div className="w-100 h-100 ">
        <Header />
        <div className="d-flex position-relative">
          <Sidebar />
          <div className="MainSection">
            {/* Inner Header Starts */}
            <InnerHeader text="System Settings" arrow={false}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  flex: 1,
                  paddingRight: "10px",
                  gap: "10px",
                }}
              >
                {/* CSV download button */}
                <div className="">
                  {isVisible ? (
                    <button
                      onClick={onSubmit}
                      className="SiteBtn3 edit-btn-tenant btn"
                      disabled={saveDisable} // Set disabled attribute based on saveDisable state
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setisVisible(true);
                      }}
                      disabled={saveDisable} // Set disabled attribute based on saveDisable state
                      className="SiteBtn2 edit-btn-tenant btn"
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </InnerHeader>

            <div className="mailDiv ms-5">
              <label className="mail-lable" htmlFor="">
                Contact Support
              </label>
              <div onClick={handleEmailClick}>
                <input
                  className={`mail-input-setting ${
                    errors.adminEmail ? "invalid" : ""
                  }`}
                  onChange={handleEmailChange} // Call handleEmailChange on input change
                  value={adminEmail} // Set value of input field to adminEmail state
                  type="email"
                  name=""
                  placeholder="support@breadowntown.com"
                  id=""
                  required
                />
              </div>
              {errors.adminEmail && (
                <div style={{ color: "red" }}>{errors.adminEmail}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SystemSettings;
