import React, { useState, useEffect, useRef, useMemo } from "react";
import { GetAllEvents, RemoveSingleEvent } from "../../redux/features/EventFeedService";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Header";
import Sidebar from "../Sidebar";
import moment from "moment";
import DataTable from "react-data-table-component";
import InnerHeader from "../InnerHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners'; // Import RingLoader
import { toast } from "react-toastify";
import { isAction } from "redux";
import { setActiveTab } from "../../redux/features/MiscService";

// Table Columns
const columns = [
    {
        id: 1,
        name: "Event Name",
        selector: (row) => row.eventName,
        sortable: true,
        reorder: true,
        // width: '150px',
    },
    {
        id: 2,
        name: "Time",
        selector: (row) => row.startTime + '-' + row.endTime,
        sortable: true,
        reorder: true,
    },
    {
        id: 3,
        name: "Date(s)",
        selector: (row) => moment(row.date).format('MMMM Do, YYYY') +`${row.endDate ? ` to ${moment(row.endDate).format('MMMM Do, YYYY')}` : ''}`,
        sortable: true,
        reorder: true,
        width: '250px',
    },
    {
        id: 4,
        name: "Venue",
        selector: (row) => row.venue,
        sortable: true,
        reorder: true,
        // width: '200px',
    },
    {
        id: 5,
        name: "Flyer Link",
        // selector: (row) => row.flyerLink,
        selector: (row) => {
            if (row.flyerLink === undefined || row.flyerLink === "") {
                row = "No";
            } else {
                row = "Yes";
            }
            return row;
        },
        sortable: true,
        reorder: true,
    },
    {
        id: 6,
        name: "Ticket Link",
        selector: (row) => {
            if (row.ticketLink === undefined || row.ticketLink === "") {
                row = "No";
            } else {
                row = "Yes";
            }
            return row;
        },
        sortable: true,
        reorder: true,
    }, {
        id: 7,
        name: "Image?",
        selector: (row) => {
            if (row.eventImage === undefined || row.eventImage === "") {
                row = "No";
            } else {
                row = "Yes";
            }
            return row;
        },
        sortable: true,
        reorder: true,
    },
];

const isValidUrl = (url) =>  {
    try {
      // Regular expression for a URL
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3})|" + // OR IP (v4) address
          "localhost|" + // OR localhost
          "([a-zA-Z\\d-]+\\.([a-zA-Z]{2,}))\\.?)" + // subdomain
          "(:\\d+)?(\\/[-a-zA-Z\\d%@_.~+&:]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
          "(#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
  
      return pattern.test(url);
    } catch (error) {
      return false;
    }
  }

const eventColumns = [
    {
        id: 1,
        name: "Title",
        selector: (row) => row.title,
        sortable: true,
        reorder: true,
    },
    {
        id: 2,
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
        reorder: true,
        width: '350px',
    },
    {
        id: 3,
        name: "Link",
        // selector: (row) => row.flyerLink,
        selector: (row) => {
            if (row.link === undefined || row.link === "" || !row.link.trim()) {
                return "No";
            } else {
                return isValidUrl(row.link.trim()) ? "Yes" : "No";
            }
        },
        sortable: true,
        reorder: true,
    }, {
        id: 4,
        name: "Image?",
        selector: (row) => {
            if (row.eventImage === undefined || row.eventImage === "") {
                row = "No";
            } else {
                row = "Yes";
            }
            return row;
        },
        sortable: true,
        reorder: true,
    },
];

const EventsList = () => {

    document.title = "Events - Brea downtown";
    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState();
    // const [activeTab, setActiveTab] = useState(0);
    const miscState = useSelector((state) => state.misc);
    const activeTab = useMemo(() => miscState?.eventFeed?.activeTab || 0, [miscState?.eventFeed?.activeTab]);
    console.log('activeTab', activeTab);
    const [filterNoMatch, setFilterNoMatch] = useState();
    const [isVisible, setIsVisible] = useState({
        active: false,
        inActive: false,
        third: false
    });
    const { allBreaEvents } = useSelector((state) => state.event)
    const tabs = ["Brea", "Tenants", "Daily Happening"];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [isSearchText, setSearchText] = useState({
        brea: "",
        tenants: "",
        happening: ""
    });
    const obj = {
        eventName: '',
        venue: '',
        flyerLink: '',
        ticketLink: '',
        title: '',
        description: '',
        link: '',
        image: ''
    };
    const [formData, setFormData] = useState({
        brea: obj,
        tenants: obj,
        happening: obj
    });

    useEffect(() => {
        dispatch(RemoveSingleEvent())
    }, [])

    // useEffect to fetch data based on activeTab
    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        dispatch(RemoveSingleEvent());

        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(location.search);
                const tabValue = queryParams.get('tab');
                let eventType = activeTab + 1; // Default eventType
                if (tabValue === "1") {
                    setActiveTab(0);
                    eventType = "1";
                } else if (tabValue === "2") {
                    setActiveTab(1);
                    eventType = "2";
                } else if (tabValue === "3") {
                    setActiveTab(2);
                    eventType = "3";
                }
                console.log('useEffect', eventType);
                const response = await dispatch(GetAllEvents({ eventType }));
                if (response.type === "event/GetAllEvents/fulfilled") {
                    setLoading(false); // Set loading to false after data is fetched
                }
            } catch (error) {
                toast.error("Failed to fetch events data");
                setLoading(false); // Set loading to false on error
            }
        };

        fetchData();

    }, [dispatch, location.search]);


    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value;
        if (!activeTab) {
            setFormData({ ...formData, brea: { ...formData?.brea, [name]: value } })
        } else if (activeTab === 1) {
            setFormData({ ...formData, tenants: { ...formData?.tenants, [name]: value } })
        } else {
            setFormData({ ...formData, happening: { ...formData?.happening, [name]: value } })
        }
    };

    const handleTabClick = async (index) => {
        // setActiveTab(index);
        setLoading(true);
        dispatch(setActiveTab({ tabOf: 'eventFeed', activeTab: index }));
        const search = !index ? isSearchText?.brea : index === 1 ? isSearchText?.tenants : isSearchText?.happening;
        const eventType = !index ? "1" : index === 1 ? "2" : "3";
        const formDatas = !index ? formData?.brea : index === 1 ? formData?.tenants : formData?.happening;
        const res = await dispatch(GetAllEvents({ search: search, eventType: eventType, filter: formDatas }))
        if (res.type === "users/GetAllEvents/fulfilled") {
            setLoading(false);
        }
        setLoading(false);
    };

    //search 
    const handleSearch = async (searchText) => {
        if (activeTab === 0) {
            setSearchText({ brea: searchText, tenants: isSearchText?.tenants, happening: isSearchText?.happening })
            await dispatch(GetAllEvents({ search: searchText, eventType: "1" }));
        } else if (activeTab === 1) {
            setSearchText({ brea: isSearchText?.brea, tenants: searchText, happening: isSearchText?.happening })
            await dispatch(GetAllEvents({ search: searchText, eventType: "2" }))
        } else {
            setSearchText({ brea: isSearchText?.brea, tenants: isSearchText?.tenants, happening: searchText })
            await dispatch(GetAllEvents({ search: searchText, eventType: "3" }))
        }
    };


    const clearBtn = async () => {
        if (activeTab === 0) {
            await dispatch(GetAllEvents({ eventType: "1" }));
        } else if (activeTab === 1) {
            await dispatch(GetAllEvents({ eventType: "2" }))
        } else {
            await dispatch(GetAllEvents({ eventType: "3" }))
        }
        setFormData({
            brea: obj,
            tenants: obj,
            happening: obj
        })
    }

    const filterApply = async (e) => {
        e.preventDefault();
        if (activeTab === 0) {
            await dispatch(GetAllEvents({ eventType: "1", filter: formData?.brea }));
        } else if (activeTab === 1) {
            await dispatch(GetAllEvents({ eventType: "2", filter: formData?.tenants }))
        } else {
            await dispatch(GetAllEvents({ eventType: "3", filter: formData?.happening }))
        }
        setFilterNoMatch(true);
    }

    const toggleVisibility = async () => {
        const visible = {
            active: !activeTab ? !isVisible?.active : activeTab === 1 ? isVisible?.active : activeTab === 2 ? isVisible?.active : false,
            inActive: activeTab === 1 ? !isVisible?.inActive : activeTab === 0 ? isVisible?.inActive : activeTab === 2 ? isVisible?.inActive : false,
            third: activeTab === 2 ? !isVisible?.third : activeTab === 0 ? isVisible?.third : activeTab === 1 ? isVisible?.third : false,
        }
        setIsVisible(visible);

        // await dispatch(GetAllEvents({ eventType: activeTab === 0 ? "1" : activeTab === 1 ? "2" : "3" }));
        // clearBtn();
        setFilterNoMatch(false);
    };

    const handleRowClick = (row) => {
        navigate(`/events/${row?._id}`);
    };

    const handleRowClickEvent = (row) => {
        navigate(`/daily-happening/${row?._id}`);
    };

    const NavAdd = () => {
        navigate('/events/add')
    };

    const NewDHAdd = () => {
        navigate('/daily-happening/add')
    };

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    return (
        <>
            {/* {loading && <Loader />} */}

            <div className="w-100 h-100 ">
                <Header></Header>
                <div className="d-flex position-relative">
                    <Sidebar />
                    <main className="MainSection">

                        {/* Inner Header */}
                        <InnerHeader text="Events" arrow={false}>
                            <div className="sidebar-btn">
                                <div className="me-3">
                                    <button onClick={NavAdd} className="SiteBtn export-csv-btn btn">
                                        Add Event
                                    </button>
                                </div>
                                <div>
                                    <button onClick={NewDHAdd} className="SiteBtn2 add-save-btn btn">
                                        Add Daily Happenings
                                    </button>
                                </div>
                            </div>
                        </InnerHeader>

                        <div className="conten-div">
                            {/* Search and Filter Button */}
                            <div className="row justify-content-between">

                                <div className="col-md-4 col-lg-3 col-md-4 col-8 mb-3 mb-lg-0">
                                    <div className="searchbarmain ">
                                        <input type="text"
                                            className="inputsearchbarmain"
                                            name=""
                                            id="search"
                                            placeholder="Search"
                                            value={!activeTab ? isSearchText?.brea : activeTab === 1 ? isSearchText?.tenants : isSearchText.happening}
                                            onChange={(e) => handleSearch(e.target.value)} />

                                        <button className="searchbtn" >
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1479_513)">
                                                    <path d="M18.0831 16.3333H17.1614L16.8348 16.0183C18.2348 14.385 18.9581 12.1567 18.5614 9.78833C18.0131 6.545 15.3064 3.955 12.0398 3.55833C7.10475 2.95166 2.95142 7.105 3.55809 12.04C3.95475 15.3067 6.54475 18.0133 9.78809 18.5617C12.1564 18.9583 14.3848 18.235 16.0181 16.835L16.3331 17.1617V18.0833L21.2914 23.0417C21.7698 23.52 22.5514 23.52 23.0298 23.0417C23.5081 22.5633 23.5081 21.7817 23.0298 21.3033L18.0831 16.3333ZM11.0831 16.3333C8.17809 16.3333 5.83309 13.9883 5.83309 11.0833C5.83309 8.17833 8.17809 5.83333 11.0831 5.83333C13.9881 5.83333 16.3331 8.17833 16.3331 11.0833C16.3331 13.9883 13.9881 16.3333 11.0831 16.3333Z" fill="#42474E" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1479_513">
                                                        <rect width="28" height="28" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                {(activeTab === 0 && isVisible?.active) || (activeTab === 1 && isVisible?.inActive) || (activeTab === 2 && isVisible?.third) ?
                                    <>
                                        <div className="col-lg-7 col-md-5 col-12 order-3 order-md-2">
                                            {activeTab === 1 || activeTab === 0 ? (
                                                <>
                                                    <div className="col-md-12">
                                                        <div className="row EventFilterBox">
                                                            <>
                                                                <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                                                    <div className="input-group">
                                                                        <input type="text" name="eventName" value={!activeTab ? formData?.brea?.eventName : activeTab === 1 ? formData?.tenants?.eventName : formData?.happening?.eventName} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Search by Name" />
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                                                    <div className="input-group">
                                                                        <input type="text" name="venue" value={!activeTab ? formData?.brea?.venue : activeTab === 1 ? formData?.tenants?.venue : formData?.happening?.venue} onChange={(e) => handleInputChange(e)} id="venue" className="form-control" placeholder="Search by Venue" />
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-6 col-md-12 mb-3">

                                                                    <select name="flyerLink" value={!activeTab ? formData?.brea?.flyerLink : activeTab === 1 ? formData?.tenants?.flyerLink : formData?.happening?.flyerLink} onChange={(e) => handleInputChange(e)} className="form-select from-control select2-custom text-left-txt" aria-label="Default select example">
                                                                        <option selected>Search by Flyer </option>
                                                                        <option value="1">Yes</option>
                                                                        <option value="0">No</option>
                                                                    </select>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                                                    <select name="ticketLink" value={!activeTab ? formData?.brea?.ticketLink : activeTab === 1 ? formData?.tenants?.ticketLink : formData?.happening?.ticketLink} onChange={(e) => handleInputChange(e)} className="form-select from-control select2-custom text-left-txt" aria-label="Default select example">
                                                                        <option selected>Search by Ticket </option>
                                                                        <option value="1">Yes</option>
                                                                        <option value="0">No</option>
                                                                    </select>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                                                    <select name="image" value={!activeTab ? formData?.brea?.image : activeTab === 1 ? formData?.tenants?.image : formData?.happening?.image} onChange={(e) => handleInputChange(e)} className="form-select from-control select2-custom text-left-txt" aria-label="Default select example">
                                                                        <option selected>Search by Image? </option>
                                                                        <option value="1">Yes</option>
                                                                        <option value="0">No</option>
                                                                    </select>
                                                                </div>
                                                            </>


                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 text-end">
                                                        <button className="SiteBtn main-filter-btn btn" onClick={clearBtn}>
                                                            Clear
                                                        </button>
                                                        <button className="SiteBtn main-filter-btn btn ms-2 " onClick={(e) => { filterApply(e) }}>
                                                            Apply
                                                        </button>
                                                    </div>
                                                </>
                                            )
                                                :
                                                <>
                                                    <div className="col-md-12 ">
                                                        <div className="row EventFilterBox">
                                                            <>
                                                                <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                                                    <div className="input-group">
                                                                        <input type="text" name="title" value={activeTab === 2 ? formData?.happening?.title : 0} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Search by Title" />
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                                                    <div className="input-group">
                                                                        <input type="text" name="description" value={activeTab === 2 ? formData?.happening?.description : 0} onChange={(e) => handleInputChange(e)} id="description" className="form-control" placeholder="Search by Description" />
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                                                    <select name="link" value={activeTab === 2 ? formData?.happening?.link : 0} onChange={(e) => handleInputChange(e)} className="form-select from-control select2-custom text-left-txt" aria-label="Default select example">
                                                                        <option selected>Search by Link </option>
                                                                        <option value="1">Yes</option>
                                                                        <option value="0">No</option>
                                                                    </select>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                                                                    <select name="image" value={activeTab === 2 ? formData?.happening?.image : 0} onChange={(e) => handleInputChange(e)} className="form-select from-control select2-custom text-left-txt" aria-label="Default select example">
                                                                        <option selected>Search by Image? </option>
                                                                        <option value="1">Yes</option>
                                                                        <option value="0">No</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 text-end" >
                                                        <button className="SiteBtn main-filter-btn btn" onClick={clearBtn}>
                                                            Clear
                                                        </button>
                                                        <button className="SiteBtn main-filter-btn btn ms-2 " onClick={(e) => { filterApply(e) }}>
                                                            Apply
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </> : <></>
                                }
                                <div className="col-lg-2 col-md-3 col-4 order-2 order-md-3 text-end">
                                    <button className="SiteBtn main-filter-btn btn" onClick={toggleVisibility}>
                                        Filter
                                    </button>
                                </div>
                            </div>
                            <div className="main">
                                <div className="col-md-8 d-flex mb-5 mt-4 min-btn-event">
                                    {/* Tabs for Brea, Tenants and Daily Happening */}
                                    {tabs.map((tab, index) => {
                                        return (
                                            <div key={`${index}-event-tab`} className="TabButton"
                                                onClick={() => handleTabClick(index)}
                                                style={{
                                                    borderBottom: activeTab === index ? "2px solid #00639B" : "none",
                                                    color: activeTab === index ? "#1A1C1E" : "#42474E",
                                                }}
                                            >
                                                {tab}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {/* {loading && (
                                            <div className="sweet-loading m-auto">
                                                <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                                            </div>
                            )} */}
                            {/* <AppUser Table /> */}
                            {activeTab === 0 && (
                                <div>
                                    <div className="TableData">
                                        {loading ? (
                                            <div className="sweet-loading m-auto">
                                                <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                                            </div>
                                        ) : (
                                            <DataTable
                                                title=""
                                                columns={columns}
                                                pagination
                                                data={allBreaEvents?.data?.records || []}
                                                onRowClicked={handleRowClick}
                                                highlightOnHover
                                                persistTableHead
                                                noDataComponent={
                                                    <div className="header-text-container">
                                                        {isSearchText?.brea || isSearchText?.tenants || isSearchText?.happening ?
                                                            <p className="header-text">There are no records to display </p>
                                                            : (filterNoMatch ?
                                                                <p className="header-text no-data-text"> No results matching your filter. Try removing the Filter </p>
                                                                : <p className="header-text no-data-text">There are no records to display</p>)
                                                        }
                                                    </div>
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                            {activeTab === 1 && (
                                <div>
                                    <div className="TableData">
                                        {loading ? (
                                            <div className="sweet-loading m-auto">
                                                <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                                            </div>
                                        ) : (
                                            <DataTable
                                                title=""
                                                columns={columns}
                                                pagination
                                                data={allBreaEvents?.data?.records || []}
                                                onRowClicked={handleRowClick}
                                                highlightOnHover
                                                persistTableHead
                                                noDataComponent={
                                                    <div className="header-text-container">
                                                        {filterText ?
                                                            <p className="header-text">There are no records to display </p>
                                                            : (filterNoMatch ?
                                                                <p className="header-text no-data-text"> No results matching your filter. Try removing the Filter </p>
                                                                : <p className="header-text no-data-text">There are no records to display</p>)
                                                        }
                                                    </div>
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                            {activeTab === 2 && (
                                <div>
                                    <div className="TableData">
                                        {loading ? (
                                            <div className="sweet-loading m-auto">
                                                <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                                            </div>
                                        ) : (
                                            <DataTable
                                                title=""
                                                columns={eventColumns}
                                                pagination
                                                data={allBreaEvents?.data?.records || []}
                                                onRowClicked={handleRowClickEvent}
                                                highlightOnHover
                                                persistTableHead
                                                noDataComponent={
                                                    <div className="header-text-container">
                                                        {filterText ?
                                                            <p className="header-text no-data-text ">There are no records to display </p>
                                                            : (filterNoMatch ?
                                                                <p className="header-text no-data-text"> No results matching your filter. Try removing the Filter </p>
                                                                : <p className="header-text no-data-text">There are no records to display</p>)
                                                        }
                                                    </div>
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default EventsList
