import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const accessToken = Cookies.get("auth-token");
  // const [authStatus, setAuthStatus] = useState(AuthStatus.loading);
  // const [authStatus, setAuthStatus] = useState(0);
  const [authStatus, setAuthStatus] = useState(accessToken ? 1 : 0);


  const updateAuthStatus = (status) => {
    setAuthStatus(status);
    if(accessToken) {
      setAuthStatus(1);
    } else {
      setAuthStatus(status);
    }
  };
  
  useEffect(() => {
    if(accessToken) {
      updateAuthStatus(1)
    } else {
      updateAuthStatus(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthContext.Provider  value={{ authStatus, updateAuthStatus }}>{props.children}</AuthContext.Provider>
  );
};
