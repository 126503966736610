import { AuthProvider } from "./contexts/AuthContexts";
import InitialRoute from "./routes/InitialRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <div className="App">
        <AuthProvider>
          <InitialRoute />
        </AuthProvider>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
