import React, { useState, useEffect, useMemo } from "react";
import { GetAllAppUsers } from "../../redux/features/UserService";
import { setActiveTab } from "../../redux/features/MiscService";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Header";
import Sidebar from "../Sidebar";
import DataTable from "react-data-table-component";
import moment from 'moment-timezone';
import { CSVLink } from "react-csv";
import { useLocation, useNavigate } from "react-router-dom";
import InnerHeader from "../InnerHeader";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Common/Loader";
import _ from "lodash";
import { css } from '@emotion/react';
import { RingLoader } from 'react-spinners'; // Import RingLoader
import { toast } from "react-toastify";

const columns = [
  {
    id: 1,
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    // reorder: true,
    width: "380px ",
    // maxWidth : "300px",// added line here
    // headerStyle: (selector, id) => {
    //   return { textAlign: "center" };   // removed partial line here
    // },
    // sortFunction: (a, b) => {
    //   if (a.email < b.email) return -1;
    //   if (a.email > b.email) return 1;
    //   // if () return 1;
    //   return 0;
    // },
    autoResetSortBy: false,
  },
  {
    id: 2,
    name: "Newsletter opt in",
    selector: (row) => {
      if (row.newsLetterOptIn === "1") {
        row = "Yes";
      } else {
        row = "No";
      }
      return row;
    },
    reorder: true,
    width: "200px",
    // sortFunction: (a, b) => {
    //   if (a.newsLetterOptIn < b.newsLetterOptIn) return -1;
    //   if (a.newsLetterOptIn > b.newsLetterOptIn) return 1;
    //   // if () return 1;
    //   return 0;
    // }
  },
  {
    id: 3,
    name: "Notification Status",
    selector: (row) => {
      if (row.notificationStatus === "1") {
        row = "Yes";
      } else {
        row = "No";
      }
      return row;
    },
    reorder: true,
    width: "200px ",
    // sortFunction: (a, b) => {
    //   if (a.notificationStatus < b.notificationStatus) return -1;
    //   if (a.notificationStatus > b.notificationStatus) return 1;
    //   // if () return 1;
    //   return 0;
    // }
  },
  // {
  //   id: 4,
  //   name: "Discount Badge",
  //   selector: (row) => {
  //     if (row.discountBadge === "1") {
  //       row = "Yes";
  //     } else {
  //       row = "No";
  //     }
  //     return row;
  //   },
  //   reorder: true,
  //   width: "200px",
  //   // sortFunction: (a, b) => {
  //   //   if (a.discountBadge < b.discountBadge) return -1;
  //   //   if (a.discountBadge > b.discountBadge) return 1;
  //   //   // if () return 1;
  //   //   return 0;
  //   // }
  // },
  {
    id: 5,
    name: "Sign Up Date",
    selector: (row) => moment.tz(row.signUpDate, 'america/los_angeles').format('MM/DD/yyyy @ hh:mmA PT'),
    reorder: true,
    width: "230px ",
    headerStyle: (selector, id) => {
      return { textAlign: "center" };   // removed partial line here
    },
    // sortFunction: (a, b) => {
    //   if (a.signUpDate < b.signUpDate) return -1;
    //   if (a.signUpDate > b.signUpDate) return 1;
    //   // if () return 1;
    //   return 0;
    // }
  },
  {
    id: 6,
    name: "Last Active",
    selector: (row) => moment.tz(row.updatedAt, 'america/los_angeles').format('MM/DD/yyyy @ hh:mmA PT'),
    // sortable: true,
    reorder: true,
    width: "230px ",
    textAlign: "center",
    headerStyle: (selector, id) => {
      return { textAlign: "center" };   // removed partial line here
    },
    // sortFunction: (a, b) => {
    //   if (a.updatedAt < b.updatedAt) return -1;
    //   if (a.updatedAt > b.updatedAt) return 1;
    //   // if () return 1;
    //   return 0;  
    // }
  },
];

const AppUserList = () => {
  const [isSearchText, setSearchText] = useState({
    active: "",
    inActive: ""
  });
  const [csvData, setCsvData] = useState([]);
  // const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const { allActiveUsers } = useSelector((state) => state.users);
  const miscState = useSelector((state) => state.misc);
  const activeTab = useMemo(() => miscState?.appUsers?.activeTab || 0, [miscState?.appUsers?.activeTab]);
  const [isVisible, setIsVisible] = useState({
    active: false,
    inActive: false
  });

  const [formData, setFormData] = useState({
    active: {
      email: '',
      newsLetterOptIn: '',
      notificationStatus: '',
      // discountBadge: '',
    },
    inActive: {
      email: '',
      newsLetterOptIn: '',
      notificationStatus: '',
      // discountBadge: '',
    }
  });
  const tabs = ["Active", "Disabled"];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  document.title = "App Users | Brea Downtown";
  const [filterNoMatch, setFilterNoMatch] = useState();
  const location = useLocation();
  console.log('location outer page', location);

  //Active Inactive tab handler
  const handleTabClick = async (index) => {
    setLoading(true);
    // setIsVisible(!isVisible);
    // inputRef.current.value = ''; //search field blank
    dispatch(setActiveTab({ tabOf: 'appUsers', activeTab: index }));

    if (index === 0) {
      const res = await dispatch(GetAllAppUsers({ search: isSearchText?.active, status: "1", filter: formData?.active }));
      if (res.type === "users/GetAllAppUsers/fulfilled") {
        setLoading(false);
      }
    } else {
      const res = await dispatch(GetAllAppUsers({ search: isSearchText?.inActive, status: "0", filter: formData?.inActive }));
      if (res.type === "users/GetAllAppUsers/fulfilled") {
        setLoading(false);
      }
    }
    setIsVisible(isVisible);
    // setActiveTab(index);
  };

  //search 
  const handleSearch = async (searchText, number) => {
    // debugger;
    if (activeTab === 0 && number === 0) {
      setSearchText({ active: searchText, inActive: isSearchText?.inActive })
      await dispatch(GetAllAppUsers({ search: searchText, status: "1" }));
    } else {
      setSearchText({ active: isSearchText?.active, inActive: searchText })
      await dispatch(GetAllAppUsers({ search: searchText, status: "0" }))
    }
    setFormData({
      active: {
        email: '',
        newsLetterOptIn: '',
        notificationStatus: '',
        // discountBadge: '',
      },
      inActive: {
        email: '',
        newsLetterOptIn: '',
        notificationStatus: '',
        // discountBadge: '',
      }
    });
  };

  const handleInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value;
    if (!activeTab) {
      setFormData({ ...formData, active: { ...formData?.active, [name]: value } })
    } else {
      setFormData({ ...formData, inActive: { ...formData?.inActive, [name]: value } })
    }
  };

  const clearBtn = async () => {
    if (activeTab === 0) {
      await dispatch(GetAllAppUsers({ status: "1", limit: 10 }));
    } else if (activeTab === 1) {
      await dispatch(GetAllAppUsers({ status: "0", limit: 10 }))
    }
    setFormData({
      active: {
        email: '',
        newsLetterOptIn: '',
        notificationStatus: '',
        // discountBadge: '',
      },
      inActive: {
        email: '',
        newsLetterOptIn: '',
        notificationStatus: '',
        // discountBadge: '',
      }
    })
  }

  const filterApply = async (e) => {
    e.preventDefault();
    if (activeTab === 0) {
      await dispatch(
        GetAllAppUsers({
          status: "1",
          limit: 10,
          filter: formData?.active
        })
      );
    } else if (activeTab === 1) {
      await dispatch(
        GetAllAppUsers({
          status: "0",
          limit: 10,
          filter: formData?.inActive
        })
      );
    }
    setFilterNoMatch(true);
  };

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        if (activeTab === 0) {
          const res = await dispatch(GetAllAppUsers({ status: "1" }));
          if (res.type === "users/GetAllAppUsers/fulfilled") {
            setLoading(false);
          }
        } else {
          const res = await dispatch(GetAllAppUsers({ status: "0" }));
          if (res.type === "users/GetAllAppUsers/fulfilled") {
            setLoading(false);
          }
        }
        setLoading(false); // Set loading to true when fetching data

      } catch {
        toast.error("failed to load app users");
        setLoading(false);
      }
    }
    fetchData();
  }, []);


  const headers = [
    { label: "Email", key: "email" },
    { label: "Newsletter opt in", key: "newsLetterOptIn" },
    { label: "Notification Status", key: "notificationStatus" },
    // { label: "Discount Badge", key: "discountBadge" },
    { label: "Sign Up date", key: "signUpDate" },
    { label: "Last Active", key: "updatedAt" },
  ];

  const getCsvData = async () => {
    if (activeTab === 0) {
      const appuserTableData = await _.map(allActiveUsers?.data?.users, (userDetail, userIndex) => {
        return {
          email: userDetail.email,
          newsLetterOptIn: userDetail.newsLetterOptIn === "1" ? "Yes" : "No",
          notificationStatus: userDetail.notificationStatus === "1" ? "Yes" : "No",
          // discountBadge: userDetail.discountBadge === "1" ? "Yes" : "No",
          signUpDate: moment.tz(userDetail.signUpDate, 'america/los_angeles').format('MM/DD/yyyy @ HH:mm') + 'h PT',
          updatedAt: moment.tz(userDetail.updatedAt, 'america/los_angeles').format('MM/DD/yyyy @ HH:mmA PT')
        }
      })
      setCsvData(appuserTableData);
    } else {
      const appuserTableData = await _.map(allActiveUsers?.data?.users, (userDetail, userIndex) => {
        return {
          email: userDetail.email,
          newsLetterOptIn: userDetail.newsLetterOptIn === "1" ? "Yes" : "No",
          notificationStatus: userDetail.notificationStatus === "1" ? "Yes" : "No",
          // discountBadge: userDetail.discountBadge === "1" ? "Yes" : "No",
          signUpDate: moment.tz(userDetail.signUpDate, 'america/los_angeles').format('MM/DD/yyyy @ HH:mm') + 'h PT',
          updatedAt: moment.tz(userDetail.updatedAt, 'america/los_angeles').format('MM/DD/yyyy @ HH:mmA PT')
        }
      })
      setCsvData(appuserTableData);
    }
  };

  const toggleVisibility = async (e) => {
    setSearchText({
      active: "",
      inActive: ""
    })
    // inputRef.current.value = ''; //search field blank
    if (activeTab === 0) {
      await dispatch(GetAllAppUsers({ status: "1" }));
    } else {
      await dispatch(GetAllAppUsers({ status: "0" }))
    }
    // if (isVisible === true) {
    //   clearBtn();
    // }
    const visible = {
      active: !activeTab ? !isVisible?.active : activeTab === 1 ? isVisible?.active : false,
      inActive: activeTab ? !isVisible?.inActive : activeTab === 0 ? isVisible?.inActive : false
    }
    setIsVisible(visible);
    setFilterNoMatch();
  };

  const handleRowClick = (row) => {
    navigate(`/app-users/${row?._id}`);
  };

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

  return (
    <>
      {/*      {loading && <Loader />} */}
      <div className="w-100 h-100 ">
        <Header />
        <div className="d-flex position-relative">
          <Sidebar />
          <div className="MainSection">

            {/* {/ Inner Header Starts /} */}
            <InnerHeader text="App Users" arrow={false}>
              <div className="sidebar-btn">
                <CSVLink className="csvLink"
                  data={csvData}
                  filename="App-Users-List"
                  headers={headers}
                  onClick={(e) => getCsvData()}
                > <button className="SiteBtn  btn rounded-5 ">
                    Export</button></CSVLink>
              </div>
            </InnerHeader>

            <div className="conten-div">
              <div className="row justify-content-between">
                <div className="col-lg-3 col-md-4 col-8 mb-3 mb-lg-0">
                  <div className="searchbarmain">
                    {
                      !activeTab ?
                        (<input type="text"
                          className="inputsearchbarmain"
                          name="search"
                          id="searchAppUsers"
                          placeholder="Search"
                          value={isSearchText?.active}
                          onChange={(e) => handleSearch(e.target.value, 0)} />) :
                        (<input type="text"
                          className="inputsearchbarmain"
                          name="search"
                          id="searchAppUsers"
                          placeholder="Search"
                          value={isSearchText?.inActive}
                          onChange={(e) => handleSearch(e.target.value, 1)} />)
                    }
                    <button className="searchbtn" >
                      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1479_513)">
                          <path d="M18.0831 16.3333H17.1614L16.8348 16.0183C18.2348 14.385 18.9581 12.1567 18.5614 9.78833C18.0131 6.545 15.3064 3.955 12.0398 3.55833C7.10475 2.95166 2.95142 7.105 3.55809 12.04C3.95475 15.3067 6.54475 18.0133 9.78809 18.5617C12.1564 18.9583 14.3848 18.235 16.0181 16.835L16.3331 17.1617V18.0833L21.2914 23.0417C21.7698 23.52 22.5514 23.52 23.0298 23.0417C23.5081 22.5633 23.5081 21.7817 23.0298 21.3033L18.0831 16.3333ZM11.0831 16.3333C8.17809 16.3333 5.83309 13.9883 5.83309 11.0833C5.83309 8.17833 8.17809 5.83333 11.0831 5.83333C13.9881 5.83333 16.3331 8.17833 16.3331 11.0833C16.3331 13.9883 13.9881 16.3333 11.0831 16.3333Z" fill="#42474E" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1479_513">
                            <rect width="28" height="28" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </div>
                {(activeTab === 0 && isVisible?.active) || (activeTab === 1 && isVisible?.inActive) ?
                  <>
                    <div className="col-lg-7 col-md-5 col-12 order-3 order-md-2">
                      <div className="col-md-12">
                        <div className="row UserFilterBox">
                          <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <div className="input-group">
                              <input type="text" value={!activeTab ? formData?.active?.email : formData?.inActive?.email} onChange={(e) => handleInputChange(e)} name="email" id="email" className="form-control" placeholder="Search by Email" />
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <select name="newsLetterOptIn" value={!activeTab ? formData?.active?.newsLetterOptIn : formData?.inActive?.newsLetterOptIn} onChange={(e) => handleInputChange(e)} className="form-select from-control select2-custom text-left-txt" aria-label="Default select example">
                              <option selected value="">Search by Newsletter </option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <select name="notificationStatus" value={!activeTab ? formData?.active?.notificationStatus : formData?.inActive?.notificationStatus} onChange={(e) => handleInputChange(e)} className="form-select from-control select2-custom text-left-txt" aria-label="Default select example">
                              <option selected value="">Search by Notification </option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          {/* <div className="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <select name="discountBadge" value={!activeTab ? formData?.active?.discountBadge : formData?.inActive?.discountBadge} onChange={(e) => handleInputChange(e)} className="form-select from-control select2-custom text-left-txt" aria-label="Default select example">
                              <option selected>Search by Discount </option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-md-12 text-end">
                        <button className="SiteBtn main-filter-btn btn" onClick={clearBtn}>
                          Clear
                        </button>
                        <button className="SiteBtn main-filter-btn btn ms-2" onClick={(e) => { filterApply(e) }}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </> : <>
                  </>}
                <div className="col-lg-2 col-md-3 col-4 order-2 order-md-3 text-end">
                  <button className="SiteBtn main-filter-btn btn " onClick={toggleVisibility}>
                    Filter
                  </button>
                </div>
              </div>

              {/* Active/Disabled User taba */}
              <div className="col-sm-4 d-flex mb-5 mt-4 min-btn">
                {tabs.map((tab, index) => {

                  return (
                    <div key={`${index}-appuser-tab`} className="TabButton"
                      onClick={() => handleTabClick(index)}
                      style={{
                        borderBottom: activeTab === index ? "2px solid #00639B" : "1px solid #DEE3EB",
                        color: activeTab === index ? "#1A1C1E" : "#42474E",
                      }}
                    >
                      {tab}
                    </div>
                  );
                })}
              </div>

              {/* AppUser Table */}
              {activeTab === 0 && (
                <div>
                  <div className="TableData">
                    {loading ? (
                      <div className="sweet-loading m-auto">
                        <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                      </div>
                    ) : (
                      <DataTable
                        title=""
                        columns={columns}
                        // defaultSortFieldId={1}
                        pagination
                        data={allActiveUsers?.data?.users}
                        onRowClicked={handleRowClick}
                        highlightOnHover
                        persistTableHead
                        noDataComponent={
                          <div className="header-usertext-container">
                            {isSearchText?.inActive || isSearchText?.active ?
                              <p className="header-usertext no-data-text">There are no records to display </p>
                              : (filterNoMatch ?
                                <p className="header-usertext no-data-text"> No results matching your filter. Try removing the Filter </p>
                                : <p className="header-usertext no-data-text">There are no records to display</p>)
                            }
                          </div>
                        }
                      />
                    )}
                  </div>
                </div>
              )}
              {activeTab === 1 && (
                <div>
                  <div className="TableData">
                    {loading ?
                      <div className="sweet-loading m-auto">
                        <RingLoader css={override} size={50} color={"#00639B"} loading={true} />
                      </div>
                      :
                      <DataTable
                        title=""
                        columns={columns}
                        data={allActiveUsers?.data?.users}
                        onRowClicked={handleRowClick}
                        pagination
                        highlightOnHover
                        persistTableHead
                        noDataComponent={
                          <div className="header-usertext-container">
                            {isSearchText?.inActive || isSearchText?.active ?
                              <p className="header-usertext no-data-text">There are no records to display </p>
                              : (filterNoMatch ?
                                <p className="header-usertext no-data-text"> No results matching your filter. Try removing the Filter </p>
                                : <p className="header-usertext no-data-text">There are no records to display</p>)
                            }
                          </div>
                        }
                      />
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AppUserList;