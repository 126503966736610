import axios from "axios";
import { CommongetAuthenticationHeader, getAuthenticationHeader } from "../../utils/axiosHeader";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

// Get All Tenants Service
export const GetAllTenants = createAsyncThunk(
  "tenant/GetAllTenants",
  async (args, { rejectWithValue }) => {
    try {
      const { page, limit, search, filter, status } = args;
      const resp = await axios.post(`${url}/admin/get_all_tenant`, {
        // "page": page ? page : 1,
        // "limit": limit ? limit : 10,
        "status": status,
        "search" : search,
        "filter": filter
      }, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Get Single Tenant
export const GetSingleTenant = createAsyncThunk(
  "tenant/GetSingleTenant",
  async (args, { rejectWithValue }) => {
    try {
      const { id } = args;
      const resp = await axios.get(`${url}/admin/get_single_tenant/${id}/`,
        {
          headers: getAuthenticationHeader(),
        });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Create Tenant
export const CreateTenant = createAsyncThunk(
  "tenant/CreateTenant",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = args; 
      const resp = await axios.post(`${url}/admin/create_tenant/`, data, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Edit Tenant details
export const UpdateTenant = createAsyncThunk(
  "tenant/UpdateTenant",
  async (args, { rejectWithValue }) => {
    try {
      const { id, data } = args;
      const resp = await axios.post(`${url}/admin/update_tenant/${id}`, data, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// check tenant wheel position
export const PostTenantWheelPosition = createAsyncThunk(
  "tenant/PostTenantWheelPosition",
  async (args, { rejectWithValue }) => {
    try {
      const { position } = args;
      const resp = await axios.post(`${url}/admin/check-spin-wheel-position`, args, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// get tenant wheel position
export const GetTenantWheelPosition = createAsyncThunk(
  "tenant/GetTenantWheelPosition",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/admin/get-spin-wheel-position`, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Edit Tenant details
export const AddTenantCategoryService = createAsyncThunk(
  "tenant/AddTenantCategoryService",
  async (args, { rejectWithValue }) => {
    try {
      const { id } = args;
      const resp = await axios.post(`${url}/admin/create_tenantCategory`, args, {
        headers: getAuthenticationHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const TenantImageUpload = createAsyncThunk(
  "tenants/TenantImageUpload",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/common/file_upload`, args,{
        headers: CommongetAuthenticationHeader('', 'multipart/form-data'),
        mode: 'cors'
      });
      
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);



// Tenant Slice
const TenantSlice = createSlice({
  name: "tenants",
  initialState: {
    error: false,
    loading: false,
    allActiveTenants: [],
    allInactiveTenants: [],
    singleTenantData: [],
    AddTenant: [],
    EditTenant: [],
    postTenantWheelPosition : [],
    getTenantWheelPosition : [],
    uploadImage: null,
  },

  // reducers
  reducers: {
    setUserPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },

  // extra reducers and addcases
  extraReducers: (builder) => {
    builder

      // get all tenants
      .addCase(GetAllTenants.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllTenants.fulfilled, (state, action) => {
        state.loading = false;
        state.allActiveTenants = action.payload;
      })
      .addCase(GetAllTenants.rejected, (state) => {
        state.loading = false;
      })

      //get single tenant
      .addCase(GetSingleTenant.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetSingleTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.singleTenantData = action.payload;
      })
      .addCase(GetSingleTenant.rejected, (state) => {
        state.loading = false;
      })

      // create tenant
      .addCase(CreateTenant.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.AddTenant = action.payload;
      })
      .addCase(CreateTenant.rejected, (state) => {
        state.loading = false;
      })

      //update tenant
      .addCase(UpdateTenant.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.EditTenant = action.payload;
      })
      .addCase(UpdateTenant.rejected, (state) => {
        state.loading = false;
      })

      //update tenant
      .addCase(GetTenantWheelPosition.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetTenantWheelPosition.fulfilled, (state, action) => {
        state.loading = false;
        state.getTenantWheelPosition = action.payload;
      })
      .addCase(GetTenantWheelPosition.rejected, (state) => {
        state.loading = false;
      })

      //update tenant
      .addCase(PostTenantWheelPosition.pending, (state) => {
        state.loading = true;
      })
      .addCase(PostTenantWheelPosition.fulfilled, (state, action) => {
        state.loading = false;
        state.postTenantWheelPosition = action.payload;
      })
      .addCase(PostTenantWheelPosition.rejected, (state) => {
        state.loading = false;
      })

      .addCase(TenantImageUpload.pending, (state) => {
        state.loading = true;
      })
      .addCase(TenantImageUpload.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadImage = action.payload
      })
      .addCase(TenantImageUpload.rejected, (state) => {
        state.loading = false;
      })
  },
});

export const { setUserPage } = TenantSlice.actions;

export default TenantSlice.reducer;